import aosw from '../../aosw';

import athenaConfig from '../config';
import { $, getRawAppName } from '../utils/widget-facade';
import BaseCustomizer from './base';
import { getAppConfigForApp } from '../utils/app-config-helper';
import { APP_TYPES } from '../constants';

const { manageProfileUrl } = athenaConfig;

export default class GranularConsentCustomizer extends BaseCustomizer {
  async shouldCustomize() {
    if (this.context.formName === 'granular-consent' || this.context.controller === 'consent-required') {
      return (
        getAppConfigForApp({ appConfigs: this.appConfigs, requestContext: this.requestContext }).appType ===
        APP_TYPES.PATIENT
      );
    }
    return false;
  }

  async customize() {
    this.showTitleLogo();
    this.customizeConsentTitle();
    this.customizeConsentScopeList();
    this.flipAndCustomizeButtonsForConsent();
  }

  private showTitleLogo() {
    const signInHeader = document.getElementsByClassName('okta-sign-in-header')[0] as HTMLElement;
    if (signInHeader) {
      signInHeader.hidden = false;
    }
  }

  private customizeConsentTitle() {
    const clientLogo = document.getElementsByClassName('client-logo')[0];
    clientLogo?.remove();
    const oktaMainElement = document.getElementById('okta-sign-in');
    oktaMainElement?.classList.add('no-beacon');
    const consentTitleElement = document.getElementsByClassName('title-text')[0];
    if (consentTitleElement) {
      const appName = getRawAppName(this.requestContext);
      consentTitleElement.innerHTML = aosw.getConsentRequiredText(manageProfileUrl, appName);
    }
  }

  private customizeConsentScopeList() {
    // Custom check box for granular consent
    const scopeLabels = document.querySelector('.o-form-fieldset-container')?.getElementsByClassName('custom-checkbox');
    if (scopeLabels?.length) {
      for (const scopeItem of scopeLabels) {
        scopeItem.classList.remove('o-form-read-mode');
        const [ checkBox, scopeLabel ] = scopeItem.children as HTMLCollectionOf<HTMLInputElement>;
        checkBox.disabled = false;
        scopeLabel.setAttribute('class', 'checked fe_f_all');
        scopeLabel.style.fontFamily = '"Source Sans Pro", arial, sans-serif';
        scopeLabel.style.fontSize = '18px';
        scopeLabel.style.height = '25px';

        const [ scopeTitle, scopeDescription ] = scopeLabel.children as HTMLCollectionOf<HTMLElement>;
        scopeTitle.style.position = 'relative';
        scopeTitle.style.top = '-5px';
        scopeDescription.style.position = 'relative';
        scopeDescription.style.top = '-5px';
      }
    }
  }

  private flipAndCustomizeButtonsForConsent() {
    const allowLink = $("input[type='submit']");
    const declineLink = $("input[type='button']");
    allowLink.after(declineLink);
    const baseStyle = {
      fontWeight: 'bolder',
      width: '100%',
      height: '50px',
      marginBottom: '20px',
      textAlign: 'center',
      borderRadius: '5px',
      border: '1px solid #ccc9c9',
    };
    allowLink.css({ ...baseStyle, color: 'rgb(229, 216, 216)', background: 'rgb(3, 102, 214)' });
    declineLink.css({
      ...baseStyle,
      background: 'white',
    });

    //This override is not working by setting up i18n through config
    declineLink.val(this.config.i18n[this.config.language]['consent.required.cancelButton']);
  }
}
