import InputFieldContainer, { InputFieldContainerProps } from '../common/InputFieldContainer';
import { useMemo, useState, useEffect } from 'react';
import { GridCol, GridRow } from '@athena/forge';
import SendCodeButton from './SendCodeButton';
import { useWidgetContext } from '../context/WidgetContext';
import AmplitudeUtils from '../../utils/amplitude-utils';
import { toPascalCase } from '../../utils/string-utils';
import PrimaryButton from '../common/PrimaryButton';

const VERIFY_AMPLITUDE_EVENT_PREFIX: Record<string, string> = {
  'Send Code': 'SMS',
  'Re-Send Code': 'SMS',
  Call: 'VoiceCall',
  Redial: 'VoiceCall',
};

export default function PasscodeVerifySection(props: Readonly<InputFieldContainerProps>) {
  const { eventEmitter } = useWidgetContext();
  const sendCodeButton = useMemo(() => {
    return jQueryCourage('a[data-se="make-call"], a[data-se="sms-send-code"]');
  }, []);
  const showSendCodeButton = sendCodeButton.length > 0;
  const [ displaySendCodeButtonOnly, setDisplaySendCodeButtonOnly ] = useState(showSendCodeButton);

  useEffect(() => {
    const displayVerifyButton = !displaySendCodeButtonOnly;
    eventEmitter.emitEventToComponent({
      componentName: PrimaryButton.displayName as string,
      eventName: 'set-button-display',
      eventPayload: {
        display: displayVerifyButton,
      },
    });
  }, [ displaySendCodeButtonOnly ]);

  if (displaySendCodeButtonOnly) {
    return (
      <div className='fe_u_flex-container'>
        <SendCodeButton
          oktaButton={sendCodeButton}
          onClick={() => {
            setDisplaySendCodeButtonOnly(false);
            emitOnClickAmplitudeEvent(sendCodeButton.text());
          }}
          variant='primary'
          size='large'
        />
      </div>
    );
  }
  else {
    return (
      <div>
        <GridRow>
          <GridCol width={{ small: showSendCodeButton ? 6 : 12 }}>
            <InputFieldContainer {...props} />
          </GridCol>
          <>
            {showSendCodeButton && (
              <GridCol width={{ small: 6 }}>
                <div className='fe_u_flex-container'>
                  <SendCodeButton
                    oktaButton={sendCodeButton}
                    variant='secondary'
                    size='medium'
                    onClick={() => {
                      emitOnClickAmplitudeEvent(sendCodeButton.text());
                    }}
                  />
                </div>
              </GridCol>
            )}
          </>
        </GridRow>
      </div>
    );
  }
}

function emitOnClickAmplitudeEvent(buttonText: string) {
  AmplitudeUtils.logMfaChallengeEvent(`${VERIFY_AMPLITUDE_EVENT_PREFIX[buttonText]}::${toPascalCase(buttonText)}ButtonClick`);
}
