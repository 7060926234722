import SwitchUserButton from './SwitchUserButton';
import LastLoginTimeStamp from './LastLoginTimestamp';
import IntroduceToColleagueLink from './IntroduceToColleagueLink';
import ConfigureBrowserLink from './ConfigureBrowserLink';
import { useWidgetContext } from '../context/WidgetContext';

export default function ExtrasSection() {
  const { athenaOneWebSessionTimedOut, athenaOneWebSsoSessionTimedOut } = useWidgetContext();
  return (
    <>
      {
        (athenaOneWebSessionTimedOut || athenaOneWebSsoSessionTimedOut) ?
          <>
            <SwitchUserButton />
            <LastLoginTimeStamp />
          </>
          :
          <>
            <IntroduceToColleagueLink />
            <ConfigureBrowserLink />
          </>
      }
    </>
  );
}
