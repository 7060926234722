import athenaConfig from '../../config';
import AmplitudeUtils from '../../utils/amplitude-utils';
import BasePrimaryAuthCustomizer from './base';
const { oieEnabled } = athenaConfig;

export default class PrimaryAuthOieCustomizer extends BasePrimaryAuthCustomizer {
  rememberMeSelector = '#athena-rememberMe';

  async shouldCustomize() {
    return (await super.shouldCustomize()) && oieEnabled;
  }

  private onIdxProceed() {
    const authClient = this.oktaSignIn.authClient;
    const originalProceed = authClient.idx.proceed.bind(authClient);
    authClient.idx.proceed = async (...args: any) => {
      const res = await originalProceed.apply(authClient, args);
      res?.nextStep?.name === 'redirect-idp' &&
            await AmplitudeUtils.logIdpDiscoveryUserLoginEvent({ isIdpDiscoverySSOUser: true });
      if (this.context?.controller === 'mfa-verify-password') {
        await AmplitudeUtils.logIdpDiscoveryUserLoginEvent({ isIdpDiscoverySSOUser: false });
      }
      return res;
    };
  }

  async customize() {
    await super.customize();
    this.onIdxProceed();
  }
}
