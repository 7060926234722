import { List } from '@athena/forge';
import EnrollItem from './EnrollItem';
import ChallengeItem from './ChallengeItem';
import ProxyButton from '../common/ProxyButton';

export default function FactorsList({
  oktaFactorsList,
  listItemsSelector = '.enroll-factor-row',
  submitButtonSelector,
  challenge,
}: {
  oktaFactorsList: JQuery;
  listItemsSelector?: string;
  challenge?: boolean;
  submitButtonSelector?: string;
}) {
  const listItems = oktaFactorsList.find(listItemsSelector);
  const ItemComponent = challenge ? ChallengeItem : EnrollItem;
  return (
    <>
      <List padded={false}>
        {listItems.map((index) => {
          const oktaFactorItem = listItems.eq(index);
          return (
            <ItemComponent
              key={index}
              oktaFactorItem={oktaFactorItem}
              lastItem={index === listItems.length - 1}
            ></ItemComponent>
          );
        })}
      </List>
      {!!submitButtonSelector &&
        <div className='fe_u_margin--top-large'>
          <ProxyButton fullWidth oktaButton={jQueryCourage(submitButtonSelector)} size='large' variant='primary' />
        </div>
      }
    </>
  );
}
