import MutedText from '../common/MutedText';
import { getUserPhoneNumber } from '../../utils/widget-facade';

interface SmsTextProps {
  container: JQuery<HTMLElement>;
}

export default function SmsText( { container }: SmsTextProps) {
  const phoneNumberWithCountryCode = container.find('.phone-number').text();
  const phoneNumber = getUserPhoneNumber();
  const countryCode = phoneNumberWithCountryCode?.split(phoneNumber)[0];

  return (
    <div className='fe_u_text-align--center'>
      <MutedText className='fe_u_margin--bottom-none' text={container.find('p')[0].textContent as string} />
      <MutedText text={countryCode + '-' + phoneNumber} />
    </div>
  );
}
