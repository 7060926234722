import { getSmsManualSetupPhoneCode } from '../../utils/storage-utils';
import MutedText from '../common/MutedText';

export interface SmsSentInstructionsProps {
  oktaComponent: JQuery<HTMLElement>;
}
export default function SmsSentInstructions({ oktaComponent }: Readonly<SmsSentInstructionsProps>) {
  const phoneCode = getSmsManualSetupPhoneCode();
  const unformattedPhoneNumber = oktaComponent
    .find('.strong')
    .text();
  let formattedPhoneNumber: string = unformattedPhoneNumber;
  if (phoneCode) {
    formattedPhoneNumber = `+${phoneCode}-${unformattedPhoneNumber.split(phoneCode)[1]}`;
  }
  const smsSentInstructionsParts = oktaComponent.text().split(unformattedPhoneNumber);
  return (
    <>
      <MutedText className='sms-sent-instruction-item' text={smsSentInstructionsParts[0]} />
      <p className='fe_u_font-weight--bold sms-sent-instruction-item'>
        {formattedPhoneNumber}
      </p>
      <MutedText className='sms-sent-instruction-item' text={smsSentInstructionsParts[1]} />
    </>
  );
}
