import MutedText from '../../components/common/MutedText';
import { ENROLLMENT_CHANNEL_DESCRIPTION } from '../../constants';
import { setSmsManualSetupPhoneCode } from '../../utils/storage-utils';
import { getOktaWidgetModel } from '../../utils/widget-facade';
import OktaVerifyEnrollOieBaseCustomizer from './base';

export default class EnrollmentChannelDataCustomizer extends OktaVerifyEnrollOieBaseCustomizer {
  primaryButtonOnClick = () => {
    const oktaModel = getOktaWidgetModel();
    setSmsManualSetupPhoneCode(oktaModel.get('phoneCode'));
  };
  async shouldCustomize() {
    return (await super.shouldCustomize()) && this.context.formName === 'enrollment-channel-data';
  }

  async customize() {
    await super.customize();
    this.forgifyChannelDescription();
    this.hideSwitchChannelText();
    this.replaceComponents();
  }

  private forgifyChannelDescription() {
    const channelDescription = jQueryCourage('.o-form-fieldset-container')
      .find(`div:contains(${ENROLLMENT_CHANNEL_DESCRIPTION})`);

    if (channelDescription.length) {
      this.renderForgeAndHideOktaComponent({
        id: 'channel-description',
        oktaComponent: channelDescription,
        forgeComponent: <MutedText text={channelDescription.text()} />,
      });
    }
  }
}
