function getSecurityCodeHelpContainer(): HTMLElement {
  return document.querySelector('.security-code-help') as HTMLElement;
}

function showTooltip() {
  getSecurityCodeHelpContainer().style.display = 'block';
}

function closeTooltip() {
  getSecurityCodeHelpContainer().style.display = 'none';
}

function getSecurityCodePopover() {
  const securityCodeHelpContainer = document.createElement('div');
  securityCodeHelpContainer.classList.add('security-code-help');

  const helpClose = document.createElement('a');
  helpClose.innerHTML = '&#x2715;';
  helpClose.addEventListener('click', closeTooltip);

  const helpTitle = document.createElement('h2');
  helpTitle.textContent = 'Security Code (Symantec VIP)';

  const helpText = document.createElement('p');
  helpText.textContent =
    'The security code is used to authenticate your login information. Each time you open VIP Access, you will see a new 6-digit security code.';

  securityCodeHelpContainer.appendChild(helpClose);
  securityCodeHelpContainer.appendChild(helpTitle);
  securityCodeHelpContainer.appendChild(helpText);

  return securityCodeHelpContainer;
}

const fieldClassName = 'o-form-input-name-credentials.securitycode';
const inputFieldName = 'credentials.securitycode';
export function getSymantecField() {
  const symantecSecurityField = document.createElement('div');
  symantecSecurityField.classList.add('o-security-code-container');
  symantecSecurityField.innerHTML = `<div data-se="o-form-fieldset-credentials.securitycode" class="o-form-fieldset o-form-label-top">
      <div data-se="o-form-label" class="okta-form-label o-form-label">
        <label>Security Code (Symantec VIP) <a class="security-code-help-btn">&#x24D8;</a></label>
      </div>
      <div data-se="o-form-input-container" class="o-form-input">
        <span data-se="o-form-input-credentials.securitycode" class="${fieldClassName} o-form-control okta-form-input-field input-fix">
        <input type="password" placeholder="" name="${inputFieldName}" value="" aria-label="" autocomplete="off">
      </div>
    </div>
    <p style="margin-bottom: 20px">If you have lost your credentialed device, contact your practice manager for assistance to set up your new device.</p>`;
  const helpButton = symantecSecurityField.querySelector('.security-code-help-btn') as HTMLButtonElement;
  helpButton.parentElement?.insertAdjacentElement('afterend', getSecurityCodePopover());
  helpButton.addEventListener('click', showTooltip);
  return symantecSecurityField;
}

export function clearField() {
  const inputField = document.getElementsByName(inputFieldName)[0] as HTMLInputElement;
  if (inputField) inputField.value = '';
}

export const setError = (errorHintMessage: string) => {
  insertErrorHint(errorHintMessage);
  document.getElementsByClassName(fieldClassName)[0].addEventListener('keydown', function (e) {
    const fieldErrorElement = document.getElementById('athena-input-container-error');
    if (fieldErrorElement) {
      const formFieldContainer = fieldErrorElement.parentNode;
      if (formFieldContainer) {
        formFieldContainer.removeChild(fieldErrorElement);
      }
    }
  });
};

function insertErrorHint(errorMessage: string) {
  const formField = document.getElementsByClassName(fieldClassName)[0];
  if (formField) {
    const formFieldContainer = formField.parentNode;
    if (formFieldContainer && formFieldContainer.children.length === 1) {
      (formFieldContainer as HTMLElement).classList.add('o-form-has-errors');
      formField.insertAdjacentHTML(
        'afterend',
        `<p id="athena-input-container-error" class="okta-form-input-error o-form-input-error o-form-explain" role="alert"> 
                          <span class="icon icon-16 error-16-small" role="img" aria-label="Error"></span>
                          ${errorMessage}
                        </p>`
      );
    }
  }
}
