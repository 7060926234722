import aosw from './aosw';
import logger from './logger';
import {
  clearSecurityQuestionsPromptCookies,
  clearStorage,
  getStateTokenOverride,
  removeStateTokenOverride
} from './lib/utils/storage-utils';
import rewriteUrlOnChange from './lib/rewrite-url';
import { isAuthnWithAthenaOneWebClient } from './lib/utils/app-utils';
import  { onSuccessfulAuth, fetchAndStoreSqToken, shouldSqTokenEndpointBeCalled, getConfigForStateTokenOverrideFlow } from './lib/utils/authorize-utils';
import { loadLocalScripts, shouldLoadLocalScripts } from './load-local-scripts';
import athenaConfig from './lib/config';
import AmplitudeUtils from './lib/utils/amplitude-utils';

const { forgeEnabled, oieEnabled } = athenaConfig;

const shouldForgifyBeEnabled = () => {
  return forgeEnabled;
};

if (shouldLoadLocalScripts()) {
  loadLocalScripts();
} else {
  import('./aosw.scss');
  const { OktaUtil, OktaSignIn } = global;
  // "config" object contains default widget configuration
  // with any custom overrides defined in your admin settings.
  const config = OktaUtil.getSignInWidgetConfig();
  global.forgify = shouldForgifyBeEnabled();

  //Clear storage when user cancels the consent too
  const cancel = config.consent?.cancel;
  if (cancel) {
    config.consent.cancel = (...args) => {
      clearStorage();
      cancel.apply(config, args);
    };
  }

  global.config = config;

  try {
    rewriteUrlOnChange();
    aosw.customizeSignInWidget(config);
  } catch (e) {
    /* empty */
  }

  // Clear security questions related cookies.
  clearSecurityQuestionsPromptCookies();

  let oktaSignIn;
  const stateToken = getStateTokenOverride();
  if (isAuthnWithAthenaOneWebClient() && stateToken) {
    AmplitudeUtils.logCustomEvent('StateTokenOverrideFlow', {});
    global.stateTokenOverrideFlow = true;
    removeStateTokenOverride();

    oktaSignIn = new OktaSignIn(getConfigForStateTokenOverrideFlow(config, stateToken));
    oktaSignIn
      .showSignInAndRedirect()
      .catch((error) =>  logger.log(error.message, error));
  } else {
    oktaSignIn = new OktaSignIn(config);
    if (oieEnabled) {
      oktaSignIn.before('success-redirect', async () => {
        await onSuccessfulAuth();
        shouldSqTokenEndpointBeCalled() && await fetchAndStoreSqToken();
      });
    }
    oktaSignIn.renderEl(
      { el: '#okta-login-container' },
      // Widget calls this method after successful authentication completion, hook into the same to cleanup the storage
      // to avoid issues with same tab being used for login
      async (res) => {
        if (res?.status === 'SUCCESS') {
          await onSuccessfulAuth();
          shouldSqTokenEndpointBeCalled() && await fetchAndStoreSqToken();
          OktaUtil.completeLogin(res);
        }
      },
      (error) => logger.log(error.message, error)
    );
  }

  global.oktaSignIn = oktaSignIn;
  try {
    aosw.setupHooks(oktaSignIn);
  } catch (e) {
    /* empty */
  }
}
