import { Banner, BannerItem } from '@athena/forge';
import athenaConfig from '../../config';
const { athenaNetPasswordResetUrl } = athenaConfig;

const HEADER_TEXT = 'Invalid Username or Password';
const NON_LINK_HEADER_DESCRIPTION = 'If you don’t remember your password, ';
const RESET_PASSWORD_LINK_TEXT = 'reset it now';
const DOT = '.';

export default function InvalidUsernameOrPasswordBanner() {
  return (
    <Banner alertType='critical' className='fe_u_padding--small'>
      <BannerItem headerText={HEADER_TEXT}>
        {NON_LINK_HEADER_DESCRIPTION}
        <a href={athenaNetPasswordResetUrl}>
          {RESET_PASSWORD_LINK_TEXT}
        </a>
        {DOT}
      </BannerItem>
    </Banner>
  );
}
