import athenaConfig from '../../../config';
import BaseEnrollPhoneFactorCustomizer from '../base';
import { CustomizeControllerArgs } from '../../index';
import AuthenticatorEnrollmentDataCustomizer from './authenticator-enrollment-data';
import EnrollAuthenticatorCustomizer from './enroll-authenticator';

const { oieEnabled } = athenaConfig;
export default class EnrollPhoneFactorCustomizerOie extends BaseEnrollPhoneFactorCustomizer {
  private customizers: BaseEnrollPhoneFactorCustomizer[];
  constructor(args: CustomizeControllerArgs) {
    super(args);
    this.customizers = [ new AuthenticatorEnrollmentDataCustomizer(args), new EnrollAuthenticatorCustomizer(args) ];
  }
  async shouldCustomize() {
    let shouldCustomize = false;
    if (oieEnabled) {
      for (const customizer of this.customizers) {
        shouldCustomize = shouldCustomize || (await customizer.shouldCustomize());
      }
    }
    return shouldCustomize;
  }

  async customize() {
    for (const customizer of this.customizers) {
      if (await customizer.shouldCustomize()) {
        await customizer.customize();
      }
    }
  }
}
