import ProxyButton from '../common/ProxyButton';
import { useMutationObserver } from '../../hooks/useMutationObserver';
import { useMemo, useState } from 'react';

export interface ManualTotpNextButtonProps {
  container: JQuery<HTMLElement>;
}

export default function ManualTotpNextButton({ container }: ManualTotpNextButtonProps) {
  const oktaButton = container.find('.link-button');
  const [ hidden, setHidden ] = useState(container[0].style.display === 'none');
  const mutationObserverArgs = useMemo(() => {
    return {
      onMutation: (mutation: MutationRecord) => {
        const buttonBar = mutation.target as HTMLElement;
        setHidden(buttonBar.style.display === 'none');
      },
      filterOptions: { attributeFilter: [ 'style' ] },
    };
  }, []);

  useMutationObserver({
    element: container[0],
    ...mutationObserverArgs,
  });

  if (!hidden) {
    return (
      <ProxyButton
        oktaButton={oktaButton}
        variant="primary"
        fullWidth
        size='large'
      />
    );
  }
  else {
    return <></>;
  }
}
