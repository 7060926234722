import { CONTACT_ADMIN_TEXT } from '../../constants';
import BaseForgeCustomizer from '../base-forge';

export default abstract class FactorEnrollCustomizerBase extends BaseForgeCustomizer {
  abstract forgifyFactors(): void;

  protected forgifyController() {
    super.forgifyController();
    this.forgifyFactors();
  }

  footerText = CONTACT_ADMIN_TEXT;
  headerText = 'Set up multifactor authentication';

  async shouldCustomize() {
    return forgify && [ 'enroll-choices' ].includes(this.context.controller);
  }
}
