import { ComponentType, useEffect } from 'react';
import { useWidgetContext } from '../context/WidgetContext';


export default function withEventHelper<P extends object>(
  Component: ComponentType<P>
) {
  // The below function represents a React functional component, hence requires a specific name
  // and cannot be returned as an anonymous function
  const HOC = (props: P) => {
    const { eventEmitter } = useWidgetContext();
    useEffect(() => {
      eventEmitter.emit('componentMounted', { mountComponentName: Component.displayName });
      return () => {
        eventEmitter.emit('componentUnmounted', { mountComponentName: Component.displayName });
      };
    });

    return <Component {...props} />;
  };
  // displayName must be explicity declared within the Component passed in as a prop
  HOC.displayName = Component.displayName;

  return HOC;
}
