import { useState, useMemo } from 'react';
import { useMutationObserver } from '../../hooks/useMutationObserver';
import Banner from './Banner';
import { useEventBus } from '../../hooks/useEventBus';
import { AlertTypes } from '@athena/forge';
import ProxyButton from './ProxyButton';
import withEventHelper from './EventHelper';

interface GetBannerInfoProps {
  oktaInfoContainer?: JQuery<HTMLElement>;
  headerText?: string;
  description?: string;
  alertType?: AlertTypes;
}

const getBannerInfo = ({ oktaInfoContainer, headerText, description, alertType }: GetBannerInfoProps) => {
  if (headerText || description || alertType) {
    return { headerText, message: description, alertType, isVisible: true };
  }

  if (oktaInfoContainer) {
    const infoField = oktaInfoContainer.find('[role="info"], [class="infobox clearfix infobox-warning"]');
    const headerText = infoField.find('h3').text();
    const linkElement = infoField.find('a');
    const hasAnchorTag = linkElement.length > 0;

    let message;

    if (infoField.attr('role') === 'info') {
      message = infoField.find('p').text();
    }
    else if (hasAnchorTag) {
      message = infoField.text().split(linkElement.text())[0].trim();
    }
    else {
      message = '';
    }

    // Return isVisible: true if no children have the .hide class to support old flows
    //where we are constructing banner details without relying on oktaInfoContainer.
    return { headerText, message, linkElement, isVisible: oktaInfoContainer.children('.hide').length === 0 };
  }

  //Making sure isVisible is always returned
  return { isVisible: true };
};

function InfoContainer({
  oktaInfoContainer,
}: { oktaInfoContainer: JQuery<HTMLElement> }) {
  const [ bannerInfo, setBannerInfo ] = useState(getBannerInfo({ oktaInfoContainer }));
  const setInfoBanner = useMemo(() => {
    return ({
      headerText,
      description,
      alertType,
    }: {
      headerText: string;
      description: string;
      alertType: AlertTypes;
    }) => {
      setBannerInfo(getBannerInfo({ headerText, description, alertType }));
    };
  }, []);
  useEventBus({ eventName: 'set-info-banner', onEvent: setInfoBanner });

  //we need to check for children only in case of OIE we don't need to check in classic
  const oktaElement = oktaInfoContainer.children().length > 0 ? oktaInfoContainer.children()[0] : oktaInfoContainer[0];

  useMutationObserver({
    element: oktaElement,
    filterOptions: { childList: true, attributes: true },
    onMutation: () => {
      setBannerInfo(getBannerInfo({ oktaInfoContainer }));
    },
  });

  const { message, linkElement, alertType, headerText, isVisible } = bannerInfo;

  if (isVisible === false) return <></>;

  if (message) {
    return (
      <Banner alertType={alertType || 'info'} headerText={headerText}>
        {message}
        {linkElement && <ProxyButton oktaButton={linkElement} variant="tertiary" size="medium" />}
      </Banner>
    );
  }
  else {
    return <></>;
  }
}

InfoContainer.displayName = 'InfoContainer';

export default withEventHelper(InfoContainer);
