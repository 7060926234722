/* eslint-disable newline-per-chained-call */
import { RadioGroup, RadioGroupProps, RadioValue } from '@athena/forge';
import WidgetFormField, { WidgetFormFieldProps } from './WidgetFormField';
import { useEffect, useState } from 'react';

export type RadioButtonPropTypes = Omit<
  WidgetFormFieldProps<RadioGroupProps> & {
    inputFieldContainer: JQuery;
  },
  'onChange' | 'labelText' | 'type' | 'onClick' | 'onBlur' | 'description' | 'id' | 'value' | 'inputAs' | 'options'
>;

export default function RadioButton({ inputFieldContainer, ...passthroughProps }: RadioButtonPropTypes) {
  const label = inputFieldContainer.find('legend');
  const form = inputFieldContainer.closest('form');
  const inputElements = inputFieldContainer.find('input');
  const options = inputElements
    .map((index) => {
      const input = inputElements.eq(index);
      return {
        value: input.val() as RadioValue,
        text: input.siblings('label').text(),
      };
    })
    .toArray();
  const [ value, setValue ] = useState<string>((inputFieldContainer.find('input:checked')?.val() as string) ?? '');

  useEffect(() => {
    form.on('reset', () => {
      setValue('');
    });
  }, [ form ]);

  return (
    <WidgetFormField
      id={`athena-${inputElements.attr('name')}`}
      labelText={label.text()}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
      }}
      value={value}
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        const labelText = jQueryCourage(event.target).text();
        if (labelText) {
          inputFieldContainer.find(`label:contains(${labelText})`).trigger('click');
        }
      }}
      options={options}
      inputAs={RadioGroup}
      {...passthroughProps}
    />
  );
}
