import { useEffect, useState } from 'react';
import WidgetFormField, { WidgetFormFieldProps } from './WidgetFormField';
import { useMutationObserver } from '../../hooks/useMutationObserver';
import { CheckboxComponentProps } from '@athena/forge/dist/Checkbox/Checkbox';

export type InputFieldContainerProps = Omit<
  WidgetFormFieldProps<CheckboxComponentProps> & {
    inputFieldContainer: JQuery;
    text?: string;
  },
  'onChange' | 'labelText' | 'type' | 'onClick' | 'onBlur' | 'description' | 'id' | 'value'
>;

export default function InputFieldContainer({ inputFieldContainer, ...passthroughProps }: InputFieldContainerProps) {
  const label = inputFieldContainer.find('label');
  const form = inputFieldContainer.closest('form');

  const [ finalInput, setFinalInput ] = useState(inputFieldContainer.find('input'));
  const [ value, setValue ] = useState<string>(finalInput.val() ?? '');
  const [ autoComplete ] = useState<string>(finalInput.attr('autocomplete') ?? 'off');
  useEffect(() => {
    // set okta fields autocomplete to off to ensure forge fields get autofilled
    finalInput.attr('autocomplete', 'off');
    setValue(finalInput.val()!);
  }, [ finalInput ]);

  useEffect(() => {
    form.on('reset', () => {
      setValue('');
    });
  }, [ form ]);

  useMutationObserver({
    element: inputFieldContainer[0],
    onMutation: () => {
      setFinalInput(inputFieldContainer.find('input'));
    },
    filterOptions: { childList: true, subtree: true },
  });

  return (
    <WidgetFormField
      description={undefined}
      id={`athena-${finalInput.attr('name')}`}
      value={value}
      labelText={label.text()}
      type={finalInput.attr('type')}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        finalInput.val(event.target.value);
        finalInput.trigger('change');
      }}
      onClick={() => finalInput.trigger('click')}
      onBlur={() => finalInput.trigger('blur')}
      autoComplete={autoComplete}
      placeholder={finalInput.attr('placeholder')}
      {...passthroughProps}
    />
  );
}
