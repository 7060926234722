import { InputFieldContainerProps } from '../common/InputFieldContainer';
import { useMemo, useState } from 'react';
import ProxyButton from '../common/ProxyButton';
import CallPhoneInput from './CallPhoneInput';
import AmplitudeUtils from '../../utils/amplitude-utils';
import { toPascalCase } from '../../utils/string-utils';
import { useEventBus } from '../../hooks/useEventBus';

export default function CallPhoneInputSection(props: InputFieldContainerProps) {
  const [ disableResendButton, setDisableResendButton ] = useState<boolean>(true);
  const [ showResendButton, setShowResendButton ] = useState<boolean>(false);

  useEventBus({
    eventName: 'able_to_resend_otp',
    onEvent: ({ ableToResend }: { ableToResend: boolean }) => {
      setDisableResendButton(!ableToResend);
    },
  });

  useEventBus({
    eventName: 'enrolled_phone_changed',
    onEvent: () => {
      setShowResendButton((prevState) => !prevState);
    },
  });

  const [ sendCodeButton, resendCodeButton, callExtension ] = useMemo(() => {
    const buttons = jQueryCourage('a.call-request-button');
    return [ buttons.eq(0), buttons.eq(1), jQueryCourage('.enroll-call-extension') ];
  }, []);

  return (
    <div>
      <CallPhoneInput inputContainerProps={props} callExtension={callExtension}></CallPhoneInput>
      {showResendButton && (
        <ProxyButton
          oktaButton={resendCodeButton}
          variant="secondary"
          fullWidth
          size="medium"
          disabled={disableResendButton}
          alwaysShow
          onClick={() => {
            emitOnClickAmplitudeEvent(resendCodeButton.text());
          }}
        />
      )}
      {!showResendButton && (
        <ProxyButton
          oktaButton={sendCodeButton}
          variant="primary"
          fullWidth
          size="large"
          className="fe_u_margin--top-medium"
          alwaysShow
          onClick={() => {
            emitOnClickAmplitudeEvent(sendCodeButton.text());
          }}
        />
      )}
    </div>
  );
}

function emitOnClickAmplitudeEvent(buttonText: string) {
  AmplitudeUtils.logMfaEnrollEvent(`VoiceCall::${toPascalCase(buttonText)}ButtonClick`);
}
