import React, { Fragment } from 'react';
import MutedText from './MutedText';
import ProxyButton from './ProxyButton';
import AmplitudeUtils from '../../utils/amplitude-utils';
import { toPascalCase } from '../../utils/string-utils';

export interface FooterProps {
  text: string;
  marginTop: string;
  textAlign? : string;
  footerLinks?: HTMLElement[];
}

function getFooterLinkText(footerLink: HTMLElement) {
  return footerLink.textContent || '';
}

function overrideOktaSignOutFooterClick() {
  // Do a fresh reload instead of navigating back to primary auth which will populate the request context
  jQueryCourage('[data-se=signout-link]').on('click', (event) => {
    event.stopPropagation();
    event.preventDefault();
    window.location.reload();
  });
}

export default function Footer({ marginTop, text, textAlign, footerLinks }: FooterProps) {
  const footerTextAlignClass = textAlign ? `fe_u_text-align--${textAlign}` : '';
  const signOutLink = jQueryCourage('[data-se=signout-link]');
  signOutLink.length && overrideOktaSignOutFooterClick();
  return (
    <>
      <div className={`fe_u_margin--bottom-large fe_u_margin--top-${marginTop} fe_u_font-size--small ${footerTextAlignClass}`}>
        <MutedText text={text} />
      </div>

      {!!footerLinks?.length && footerLinks.map((footerLink, _) =>
        <Fragment key={`${footerLink.innerHTML}`}>
          <ProxyButton
            textToOverride={getFooterLinkText(footerLink)}
            oktaButton={jQueryCourage(footerLink)}
            variant='tertiary'
            size='small'
            fullWidth={false}
            onClick={() => {
              AmplitudeUtils.logCustomEvent(`Footer::${toPascalCase(footerLink.innerText)}ButtonClick`, {});
            }}
          />
          <br/>
        </Fragment>
      )}
    </>
  );
}
