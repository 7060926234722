import MutedText from '../common/MutedText';
import { useMemo, useState } from 'react';
import { useMutationObserver } from '../../hooks/useMutationObserver';

export interface SecretKeyInstructionsProps {
  container: JQuery<HTMLElement>;
}

export default function SecretKeyInstructions({ container }: SecretKeyInstructionsProps) {
  const [ secretInstructionsState, setSecretInstructionsState ] = useState({ hidden: container[0].style.display === 'none', sharedKey: container.find('.shared-key').text() });
  const text = container.find('.okta-form-subtitle').text();
  const containerMutationObserverArgs = useMemo(() => {
    return {
      filterOptions: { attributeFilter: [ 'style' ], childList: true },
      onMutation: (mutation: MutationRecord) => {
        const secretInstructionsContainer = mutation.target as HTMLInputElement;
        setSecretInstructionsState({ hidden: secretInstructionsContainer.style.display === 'none', sharedKey: secretInstructionsContainer.querySelector('.shared-key')?.textContent as string });
      },
    };
  }, []);

  useMutationObserver({
    element: container[0],
    ...containerMutationObserverArgs,
  });

  if (!secretInstructionsState.hidden) {
    return (
      <div className='fe_u_text-align--center'>
        <MutedText text={text} />
        <p className='fe_u_font-size--large'>{secretInstructionsState.sharedKey}</p>
      </div>
    );
  }
  else {
    return <></>;
  }
}
