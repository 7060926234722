import { useEffect } from 'react';

export interface UseMutationObserverArgs {
  element: HTMLElement;
  onMutation: (value: MutationRecord) => void;
  filterOptions: MutationObserverInit;
}

export function useMutationObserver({ element, filterOptions, onMutation }: UseMutationObserverArgs) {
  useEffect(() => {
    if (element) {
      const observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (value: MutationRecord) {
          onMutation(value);
        });
      });
      observer.observe(element, { ...(filterOptions.attributeFilter ? { attributes: true } : {}), ...filterOptions });
      return () => {
        observer.disconnect();
      };
    }
  }, [ element, filterOptions, onMutation ]);
}
