import { Heading } from '@athena/forge';
import MutedText from './MutedText';
import { useMemo, useState } from 'react';
import { useEventBus } from '../../hooks/useEventBus';
import withEventHelper from './EventHelper';

export interface HeaderPropsType {
  oktaHeader?: JQuery;
  oktaSubtitle?: JQuery;
  description?: string;
  headerText?: string;
  fontSize?: string;
  fontWeight?: string;
  fontColor?: string;
}

function Header({
  oktaHeader,
  oktaSubtitle,
  headerText,
  description,
  fontSize = 'default',
  fontWeight = 'semibold',
  fontColor = 'default',
}: HeaderPropsType) {
  const [ descriptionValue, setDescriptionValue ] = useState(description ? description : oktaSubtitle?.text() ?? '');
  const onUpdateDescription = useMemo(() => {
    return ({ headerDescription }: { headerDescription: string }) => {
      setDescriptionValue(headerDescription);
    };
  }, []);
  useEventBus({ eventName: 'update-header-description', onEvent: onUpdateDescription });

  const descriptionElement = <MutedText className="fe_u_font-size--default" text={descriptionValue} />;
  return (
    <div className="fe_u_text-align--center fe_u_margin--bottom-large fe_u_margin--top-small">
      <Heading
        className={`fe_u_margin--bottom-medium fe_u_font-size--${fontSize} fe_u_font-weight--${fontWeight} fe_u_color--${fontColor}`}
        headingDescription={descriptionElement}
        text={headerText ? headerText : oktaHeader?.text()}
        variant="subsection"
        headingTag={oktaHeader?.length ? oktaHeader[0].localName : 'h3'}
      ></Heading>
    </div>
  );
}

Header.displayName = 'Header';

export default withEventHelper(Header);
