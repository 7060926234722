import { getOktaWidgetModel } from '../../utils/widget-facade';
import athenaConfig from '../../config';
import BaseEnrollPhoneFactorCustomizer from './base';
import AmplitudeUtils from '../../utils/amplitude-utils';
import { toPascalCase } from '../../utils/string-utils';
import PhoneInputSectionClassic from '../../components/enroll-phone/PhoneInputSectionClassic';
const { classic } = athenaConfig;

const MFA_ENROLL_CLASS_AMPLITUDE_EVENT : Record<string, string> = {
  'enroll-call': 'VoiceCall',
  'enroll-sms': 'SMS',
};
export default class EnrollPhoneFactorCustomizer extends BaseEnrollPhoneFactorCustomizer {
  async shouldCustomize() {
    return (
      classic &&
      //Okta controller uses a function for classname for this specific case
      typeof this.oktaSignIn.router?.controller?.className === 'function' &&
      [ 'enroll-call', 'enroll-sms' ].includes(this.oktaSignIn.router?.controller?.className())
    );
  }

  async customize() {
    const controllerClass = this.oktaSignIn.router?.controller?.className();
    if (controllerClass) {
      AmplitudeUtils.logMfaEnrollEvent(`${MFA_ENROLL_CLASS_AMPLITUDE_EVENT[controllerClass]}::Landing`);

      this.primaryButtonOnClick = (buttonText: string) => {
        buttonText = buttonText ? toPascalCase(buttonText) : 'Verify';
        AmplitudeUtils.logMfaEnrollEvent(`${MFA_ENROLL_CLASS_AMPLITUDE_EVENT[controllerClass]}::${buttonText}ButtonClick`);
      };

      this.secondaryButtonOnClick = (buttonText: string) => {
        AmplitudeUtils.logMfaEnrollEvent(`${MFA_ENROLL_CLASS_AMPLITUDE_EVENT[controllerClass]}::${toPascalCase(buttonText)}ButtonClick`);
      };
    }
    await super.customize();
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const widgetModel = getOktaWidgetModel();
    widgetModel.on('change:ableToResend', function (_: any, ableToResend: boolean) {
      self.eventEmitter.emitEventToComponent({
        componentName: PhoneInputSectionClassic.displayName as string,
        eventName: 'able_to_resend_otp',
        eventPayload: { ableToResend },
      });
    });
    widgetModel.on('change:enrolled', function () {
      self.eventEmitter.emitEventToComponent({
        componentName: PhoneInputSectionClassic.displayName as string,
        eventName: 'enrolled_phone_changed',
      });
    });
  }

  protected forgifyController() {
    super.forgifyController();
    this.hidePhoneSectionButtons();
  }

  private hidePhoneSectionButtons() {
    // These buttons are already forgified by the custom component for this controller, so simply hide them from here
    jQueryCourage('a.call-request-button,a.sms-request-button').each((index, element) => this.hideComponent(element));
  }
}
