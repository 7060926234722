import { FormError, FormField, FormFieldProps, InputProps, FormFieldBaseInputProps } from '@athena/forge';

export type WidgetFormFieldProps<InputAsProps extends FormFieldBaseInputProps = InputProps> =
  FormFieldProps<InputAsProps>;
export default function WidgetFormField<InputAsProps extends FormFieldBaseInputProps = InputProps>(
  props: WidgetFormFieldProps<InputAsProps>
) {
  const inputFieldProps: WidgetFormFieldProps<InputAsProps> = {
    labelAlwaysAbove: true,
    width: 12,
    //it's a hack to take full width for input field, and ensure the label still
    // displays, setting this to 0 hides the label
    labelWidth: 0.0001,
    layout: 'medium',
    errorAlwaysBelow: true,
    nested: false,
    required: false,
    ...props,
    error: undefined,
  };
  const { error, className } = props;

  return (
    <div className={className ?? 'athena-input-form-field athena-input-form-field--required'}>
      <FormField {...inputFieldProps} className="fe_u_margin--bottom-none"></FormField>
      {error && (
        <FormError className="fe_u_margin--top-xxsmall" alertType="critical">
          {error}
        </FormError>
      )}
    </div>
  );
}
