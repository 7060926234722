import { SESSION_EXPIRED_ERROR_MESSAGE } from '../constants';
import AmplitudeUtils from '../utils/amplitude-utils';
import { getBannerTypeForSessionExpiry } from '../utils/banner-jwt-utils';
import { getLastUsernameOktaCookie, setStateTokenExpiryBannerType } from '../utils/storage-utils';
import { getUsernameWithoutNamespace } from '../utils/user-utils';
import { startSpinnerAndHideForm } from '../utils/widget-facade';
import BaseForgeCustomizer from './base-forge';

export default class TerminalCustomizer extends BaseForgeCustomizer {
  async shouldCustomize() {
    return this.context.formName === 'terminal';
  }
  async customize() {
    await super.customize();
    const errorMessage = jQueryCourage('.o-form-error-container').text()
      .trim();
    if (errorMessage === SESSION_EXPIRED_ERROR_MESSAGE) {
      startSpinnerAndHideForm();
      AmplitudeUtils.logCustomEvent('StateTokenExpired', {});
      await this.navigateToPrimaryAuthAndRenderSessionTimeoutBanner();
    }
  }

  private _setLoginHint(searchParams: URLSearchParams) {
    const username = getLastUsernameOktaCookie();
    if (username) {
      searchParams.set('login_hint', getUsernameWithoutNamespace({
        username,
        appConfigs: this.appConfigs,
        requestContext: this.requestContext,
      }));
    }
  }

  async navigateToPrimaryAuthAndRenderSessionTimeoutBanner() {
    const searchParams = new URLSearchParams(window.location.search);
    const bannerType = await getBannerTypeForSessionExpiry();
    setStateTokenExpiryBannerType(bannerType);
    this._setLoginHint(searchParams);
    window.location.search = searchParams.toString();
  }
}
