// When we want to render to primary auth and display a custom error message,
// we make use of TerminalError while triggering setTransactionError
// extra props are required to render custom error page and not the default session expired message on the screen
export class TerminalError extends Error {
  protected errorDetails: { errorSummary: string };
  protected is: (errorType: string) => boolean;
  constructor(description: string) {
    super(description);
    this.is = (errorType: string) => (errorType === 'terminal');
    this.errorDetails = { errorSummary: description };
  }
}
