import EnrollPollBaseCustomizer from './base';
import SmsSentInstructions from '../../../components/okta-verify-enroll-oie/SmsSentInstructions';

export default class EnrollPollSmsCustomizer extends EnrollPollBaseCustomizer {
  async shouldCustomize() {
    return (await super.shouldCustomize()) && this.selectedChannel === 'sms';
  }

  async customize() {
    await super.customize();
    this.forgifySmsSentInstructions();
    this.hideSwitchChannelText();
    this.replaceComponents();
  }

  private forgifySmsSentInstructions() {
    const oktaSmsSentInstructionsComponent: JQuery<HTMLElement> = jQueryCourage('.sms-info').find('li')
      .first();

    if (oktaSmsSentInstructionsComponent.length) {
      this.renderForgeAndHideOktaComponent({
        id: 'sms-sent-instructions',
        oktaComponent: oktaSmsSentInstructionsComponent,
        forgeComponent: <SmsSentInstructions oktaComponent={oktaSmsSentInstructionsComponent} />,
      });
    }
  }

}
