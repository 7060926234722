import {
  ANET_USER_PREFIX_SUPPORT_ENV_MAP,
  APP_TYPES,
  FUZZY_ENV_MAPPING,
  NON_FUZZY_ANET_USER_DOMAIN
} from '../constants';
import { getAppConfigForApp } from './app-config-helper';
import { getUsername } from './widget-facade';

export function isAnetUser() {
  const username = getUsername();
  return username?.includes(NON_FUZZY_ANET_USER_DOMAIN);
}

export function getAnetUsername() {
  return isAnetUser() ? getUsername().split('@')[0] : '';
}

export function getPatientUserNamespace(appConfig: any) {
  if (appConfig) {
    const supportEnv = appConfig.supportEnv;
    if (supportEnv && appConfig.appType === APP_TYPES.PATIENT) {
      return FUZZY_ENV_MAPPING[supportEnv].userNamespace;
    }
  }
}

export function getAnetUserDomain(username: string, appConfig: any) {
  const userPrefix = username.split('-')[0];
  if (appConfig && userPrefix) {
    const userSupportEnv = ANET_USER_PREFIX_SUPPORT_ENV_MAP[userPrefix];
    const appSupportEnv = appConfig.supportEnv;
    if (appSupportEnv && userSupportEnv === appSupportEnv && appConfig.appType === APP_TYPES.ATHENANET_USER) {
      return FUZZY_ENV_MAPPING[appSupportEnv].anetUserDomain;
    }
  }
  return NON_FUZZY_ANET_USER_DOMAIN;
}

export function transformUsername(username: string, operation: string) {
  const requestContext = global.OktaUtil.getRequestContext();
  const appConfig = getAppConfigForApp({ requestContext, appConfigs });
  //non-anet users
  if (username.indexOf('@') > -1) {
    const patientUserNamespace = getPatientUserNamespace(appConfig);
    return patientUserNamespace ? `${username}${patientUserNamespace}` : username;
  }
  else {
    return username + '@' + getAnetUserDomain(username, appConfig);
  }
}

export function getUsernameWithoutNamespace(
  { username, appConfigs, requestContext } : { username: string; appConfigs: any; requestContext: any }): string {
  const appConfig = getAppConfigForApp({ requestContext, appConfigs });
  const patientUserNamespace = getPatientUserNamespace(appConfig);
  if (username.includes(patientUserNamespace)) {
    return username.split(patientUserNamespace)[0];
  }
  return username.split('@' + getAnetUserDomain(username, appConfig))[0];
}
