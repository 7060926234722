import { clearStorage } from '../utils/storage-utils';
import BaseCustomizer from './base';

export default class SuccessRedirect extends BaseCustomizer {
  async shouldCustomize() {
    return 'success-redirect' === this.context.controller;
  }

  async customize() {
    clearStorage();
  }
}
