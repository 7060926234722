import { getAuthorizeUrl } from '../utils/storage-utils';
import logger from '../../logger';
import BaseCustomizer from './base';
import config from '../config';
import { isPrimaryAuthController } from '../utils/widget-facade';
import { removeMarketingLayout, removeMarketingFailoverLayout } from '../utils/layout-utils';
import { onSuccessfulAuth } from '../utils/authorize-utils';
import { isStateTokenOverrideFlow } from '../utils/common-utils';
const { classic } = config;
let isGetWithRedirectMonkeyPatched: boolean = false;

const hideTitleLogo = () => {
  const signInHeader = document.getElementsByClassName('okta-sign-in-header')[0] as HTMLElement;
  if (signInHeader) {
    signInHeader.hidden = true;
  }
};

const removeBrandingForIDPLogin = () => {
  try {
    const authorizeUrl = getAuthorizeUrl();
    if (authorizeUrl) {
      const parsedUrl = new URL(authorizeUrl);
      const queryParams = parsedUrl.searchParams;
      const idp = queryParams.get('idp');
      if (idp) {
        hideTitleLogo();
      }
    }
  }
  catch (err) {
    logger.log('Failed while removing branding for IDP Login', err);
  }
};

export default class CommonCustomizer extends BaseCustomizer {
  async shouldCustomize() {
    return true;
  }

  private onGetWithRedirect() {
    const authClient = this.oktaSignIn.authClient;
    const originalGetWithRedirect = authClient.token.getWithRedirect.bind(authClient);
    const originalSetLocation = authClient.token.getWithRedirect._setLocation;
    authClient.token.getWithRedirect = async (...args: any) => {
      await onSuccessfulAuth();
      await originalGetWithRedirect.apply(authClient, args);
    };
    authClient.token.getWithRedirect._setLocation = originalSetLocation;
  }

  async customize() {
    removeBrandingForIDPLogin();
    this.removePrimaryAuthCustomizations();
    if (classic && isStateTokenOverrideFlow() && !isGetWithRedirectMonkeyPatched) {
      isGetWithRedirectMonkeyPatched = true;
      this.onGetWithRedirect();
    }
  }

  private removePrimaryAuthCustomizations() {
    if (!isPrimaryAuthController(this.context?.controller)) {
      removeMarketingLayout();
      removeMarketingFailoverLayout();
      jQueryCourage('#athena-marketing').remove();
      jQueryCourage('#athena-copyright').remove();
    }
  }
}
