import amplitude from 'amplitude-js';
import athenaConfig from '../config';
import { getAuthorizeUrl } from './storage-utils';
import { getClientId, getOktaUserId } from './widget-facade';
import {
  ERROR_PAGE_EVENT_PREFIX,
  MFA_CHALLENGE_EVENT_PREFIX,
  MFA_ENROLL_EVENT_PREFIX,
  OHSW_EVENT_PREFIX,
  PASSWORD_EXPIRED_EVENT_PREFIX
} from '../constants';

const url = window.location.href;
const userId = new URL(url).searchParams.get('amplitudeUserId');
const deviceId = new URL(url).searchParams.get('deviceId');
const amplitudeDeviceId = new URL(url).searchParams.get('amplitude_device_id');

const { amplitudeApiKey } = athenaConfig;

const loggedAmplitudeEvents: Record<string, number> = {};

amplitude.getInstance().init(amplitudeApiKey, userId!, {
  deviceId: amplitudeDeviceId ?? deviceId!,
});

const AmplitudeUtils = {
  isWhiteListedEvent: function (eventName: string) {
    // _AthenaIdentityAmplituedEventWhitelist is defined in
    // https://bitbucket.athenahealth.com/projects/IAM/repos/identity-amplitude-event-whitelist/browse
    return !!_AthenaIdentityAmplituedEventWhitelist?.includes?.(eventName);
  },
  logForgeError: function (options: Record<string, any>) {
    return this.logCustomEvent('ForgeError', options);
  },
  logErrorPageLanding: function (options: Record<string, any>) {
    return this.logCustomEvent(`${ERROR_PAGE_EVENT_PREFIX}Landing`, options);
  },
  logErrorPageReloadPerformed: function () {
    return this.logCustomEvent(`${ERROR_PAGE_EVENT_PREFIX}ReloadPerformed`, {});
  },
  logErrorPageAuthUrlMissing: function () {
    return this.logCustomEvent(`${ERROR_PAGE_EVENT_PREFIX}AuthUrlMissing`, {});
  },
  logErrorPageError: function (options: Record<string, any>) {
    return this.logCustomEvent(`${ERROR_PAGE_EVENT_PREFIX}Error`, options);
  },

  fillEventProperties: function (additionalProps: Record<string, any>) {
    let authorizeUrl = getAuthorizeUrl();
    authorizeUrl = authorizeUrl || window.location.href;
    const parsedUrl = new URL(authorizeUrl);
    const queryParams = parsedUrl.searchParams;

    const eventProperties: Record<string, any> = {
      userId: getOktaUserId(),
      idp: queryParams.get('idp'),
      clientId: getClientId(global.OktaUtil?.getRequestContext()),
      forgify: global.forgify,
      isStateTokenOverrideFlow: global.stateTokenOverrideFlow,
    };
    return { ...eventProperties, ...additionalProps };
  },
  logCustomEvent: function (eventName: string, additionalProps: object) {
    const finalEventName = `${OHSW_EVENT_PREFIX}${eventName}`;
    if (eventName && this.isWhiteListedEvent(finalEventName)) {
      // If the same event was logged in the last 100ms, filter it out.
      const currentTime = new Date().getTime();
      if (loggedAmplitudeEvents[finalEventName] && currentTime - loggedAmplitudeEvents[finalEventName] < 100) {
        return;
      }
      loggedAmplitudeEvents[finalEventName] = currentTime;
      const eventProperties = this.fillEventProperties({
        ...additionalProps,
      });

      return Promise.race([
        new Promise((resolve) => {
          amplitude.getInstance().logEvent(finalEventName, eventProperties, resolve);
        }),
        new Promise((resolve) => {
          setTimeout(resolve, 1000);
        }),
      ]);
    }
  },
  logPasswordResetEvent: function (eventName: string, additionalProps: any) {
    this.logCustomEvent(`${PASSWORD_EXPIRED_EVENT_PREFIX}${eventName}`, additionalProps);
  },
  logIdpDiscoveryUserLoginEvent: function (additionalProps: any) {
    return this.logCustomEvent('IdpDiscoveryUserLoginInitiated', additionalProps);
  },
  logCustomizerError: function (props: Record<string, any>) {
    this.logCustomEvent('CustomizerError', props);
  },
  logMfaEnrollEvent: function(eventName: string) {
    this.logCustomEvent(`${MFA_ENROLL_EVENT_PREFIX}${eventName}`, {});
  },
  logMfaChallengeEvent: function(eventName: string) {
    this.logCustomEvent(`${MFA_CHALLENGE_EVENT_PREFIX}${eventName}`, {});
  },
};

export default AmplitudeUtils;
