import EventEmitter from 'eventemitter3';

export type IListener = (payload: any) => void;

export interface IEventBus {
  emit(eventName: string, eventPayload?: any): void;
  on(eventName: string, listener: IListener): void;
  removeListener(eventName: string, listener: IListener): void;
  emitEventToComponent(params: {
    componentName: string;
    eventName: string;
    eventPayload?: any;
  }): void;
}

export default class EventBus {
  private eventEmitter: EventEmitter;
  private mountedComponentRecords: Record<string, boolean> = {};

  constructor() {
    this.eventEmitter = new EventEmitter();
    this.eventEmitter.on('componentMounted', ({ mountComponentName }: { mountComponentName: string }) => {
      this.mountedComponentRecords[mountComponentName] = true;
    });
    this.eventEmitter.on('componentUnmounted', ({ mountComponentName }: { mountComponentName: string }) => {
      this.mountedComponentRecords[mountComponentName] = false;
    });
  }

  emit(eventName: string, eventPayload?: any) {
    this.eventEmitter.emit(eventName, eventPayload);
  }

  on(eventName: string, listener: IListener) {
    this.eventEmitter.on(eventName, listener);
  }

  removeListener(eventName: string, listener?: IListener) {
    this.eventEmitter.removeListener(eventName, listener);
  }

  emitEventToComponent({ componentName, eventName, eventPayload }: {
    componentName: string;
    eventName: string;
    eventPayload?: any;
  }) {
    if (this.mountedComponentRecords[componentName]) {
      this.emit(eventName, eventPayload);
    }
    else {
      const listener: IListener = ({ mountComponentName }) => {
        if (mountComponentName === componentName) {
          this.emit(eventName, eventPayload);
          this.removeListener('componentMounted', listener);
        }
      };
      this.eventEmitter.on('componentMounted', listener);
    }
  }
}
