import { KJUR } from 'jsrsasign';
import { withErrorConsumed } from './common-utils';
import athenaConfig from '../config';
import { AUTH_SERVER_ID_ANET_SIGNED_JWT_KEY_MAP, INFO_BANNER_MESSAGE_MAP, INFO_BANNER_MESSAGE_MAP_V2 } from '../constants';
import AmplitudeUtils from './amplitude-utils';
import { getOhswBannerKey, getStateTokenExpiryBannerType, removeStateTokenExpiryBannerType } from './storage-utils';
import { AlertTypes } from '@athena/forge';
const { bannerJwtAudience, athenaNetPublickKeyUrl, env, oieEnabled } = athenaConfig;

interface BannerDisplayConfig {
  description: string;
  header: string;
  bannerType: string;
  alertType: AlertTypes;
  key?: string;
}

export interface BannerInfo extends BannerDisplayConfig {
  isSso?: boolean;
  practiceId?: string;
}

export interface AthenaOneParams {
  ARGS: string[] | null;
  BANNERTYPE: string;
  PRACTICEID?: string;
  STACKNUMBER?: string;
  KEY: string;
  LASTLOGINET?: number;
  SSOREAUTHPARAMS?: Record<string, string>;
}

interface DecodedJWT {
  exp: number;
  aud: string;
  data: AthenaOneParams;
  iat: number;
}

const aNetPublicKeyCache: any = {};

export const _getAnetTokenPublicKey = async () => {
  let resolvedEnv = env;
  if (env === 'prod') {
    const authorizationServerId = OktaUtil.getRequestContext().authentication?.issuer?.id;
    if (authorizationServerId && AUTH_SERVER_ID_ANET_SIGNED_JWT_KEY_MAP[authorizationServerId]) {
      resolvedEnv = AUTH_SERVER_ID_ANET_SIGNED_JWT_KEY_MAP[authorizationServerId];
    }
  }
  if (!aNetPublicKeyCache[resolvedEnv]) {
    try {
      const response = await fetch(athenaNetPublickKeyUrl);
      const data = await response.json();
      aNetPublicKeyCache[resolvedEnv] = data[resolvedEnv].publicKeys[0];
    }
    catch (error: any) {
      AmplitudeUtils.logCustomEvent('FetchAnetPublicKeysFailure', {
        errorMessage: error?.message,
        errorCode: error?.code,
      });
      return null;
    }
  }
  return aNetPublicKeyCache[resolvedEnv];
};

export const _parseAndValidateJwt = withErrorConsumed(async (token: string): Promise<AthenaOneParams | null> => {
  const publicKey = await _getAnetTokenPublicKey();
  if (!publicKey) {
    return null;
  }
  const decodedToken = KJUR.jws.JWS.parse(token);
  const claims = decodedToken.payloadObj as DecodedJWT;
  const isValid = KJUR.jws.JWS.verifyJWT(token, publicKey, { alg: [ 'RS256' ], verifyAt: claims.iat });
  if (!isValid) {
    AmplitudeUtils.logCustomEvent('InvalidBannerJwt', { errorMessage: 'Jwt Verification Failed' });
    return null;
  }
  if (claims.aud !== bannerJwtAudience) {
    AmplitudeUtils.logCustomEvent('InvalidBannerJwt', { errorMessage: 'Invalid Aud', aud: claims.aud });
    return null;
  }
  return claims.data as AthenaOneParams;
});

export const getAthenaOneParamsJWTFromUrl = withErrorConsumed((): string | null => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('BANNERARGS');
});

export const getStateTokenExpiryBannerConfig = (bannerType: string): BannerDisplayConfig | null  => {
  const messageConfig = INFO_BANNER_MESSAGE_MAP_V2[bannerType]?.['DEFAULT'];
  if (messageConfig?.header) {
    return {
      key: 'DEFAULT',
      description: messageConfig?.description,
      header: messageConfig?.header,
      bannerType,
      alertType: 'info' as AlertTypes,
    };
  }
  return null;
};

const _getV2BannerConfig = (
  athenaOneParams: AthenaOneParams,
  athenaOneWebSsoSessionTimedOut?: boolean
) => {
  const { BANNERTYPE, KEY, ARGS } = athenaOneParams;
  const messageConfig = INFO_BANNER_MESSAGE_MAP_V2[BANNERTYPE]?.[KEY];
  let bannerDescription = messageConfig?.description;
  const bannerHeader = messageConfig?.header;

  const ARGSarr = typeof ARGS === 'string' ? [ ARGS ] : ARGS;
  // Dynamically replace placeholders in the description with ARGS
  ARGSarr?.forEach((arg, index) => {
    bannerDescription = bannerDescription.replace(new RegExp(`\\{${index}\\}`, 'g'), arg);
  });

  return {
    description: bannerDescription,
    header: bannerHeader,
    bannerType: BANNERTYPE,
    alertType: messageConfig?.alertType,
    isSso: !!(athenaOneWebSsoSessionTimedOut || new URLSearchParams(window.location.search).get('idp')),
    key: KEY,
    practiceId: (athenaOneParams.PRACTICEID || athenaOneParams.SSOREAUTHPARAMS?.PRACTICEID),
  };
};

const _getV1BannerConfig = () => {
  const ohswBannerKey = getOhswBannerKey();
  if (ohswBannerKey) {
    const messageConfig = INFO_BANNER_MESSAGE_MAP[ohswBannerKey];
    if (messageConfig?.header) {
      return {
        description: messageConfig.description,
        header: messageConfig.header,
        bannerType: ohswBannerKey,
        alertType: 'info' as AlertTypes,
        isSso: !!(new URLSearchParams(window.location.search).get('idp')),
      };
    }
  }
  return null;
};

// eslint-disable-next-line max-len
export const getBannerConfig = (athenaOneParams?: AthenaOneParams, athenaOneWebSsoSessionTimedOut?: boolean) : BannerInfo | null => {
  let bannerConfig: BannerInfo | null = null;
  if (athenaOneParams) {
    const { BANNERTYPE, KEY } = athenaOneParams;
    if (INFO_BANNER_MESSAGE_MAP_V2[BANNERTYPE]?.[KEY]) {
      bannerConfig = _getV2BannerConfig(athenaOneParams, athenaOneWebSsoSessionTimedOut);
    }
  }
  else {
    bannerConfig = _getV1BannerConfig();
  }
  const stateTokenExpiryBannerType = getStateTokenExpiryBannerType();
  if (oieEnabled && stateTokenExpiryBannerType) {
    removeStateTokenExpiryBannerType();
    const stateTokenExpiryBannerConfig =  getStateTokenExpiryBannerConfig(stateTokenExpiryBannerType);
    if (stateTokenExpiryBannerConfig) {
      bannerConfig = {
        ...bannerConfig,
        ...stateTokenExpiryBannerConfig,
      };
    }
  }
  return bannerConfig;
};


export const getAthenaOneParamsFromJwt = async (): Promise<AthenaOneParams | undefined> => {
  const athenaOneParamsJwt = getAthenaOneParamsJWTFromUrl();
  if (athenaOneParamsJwt) {
    const athenaOneParams = await _parseAndValidateJwt(athenaOneParamsJwt);
    if (athenaOneParams) {
      return athenaOneParams;
    }
  }
};

export const getBannerTypeForSessionExpiry = async (): Promise<string> => {
  const athenaOneParams = await getAthenaOneParamsFromJwt();
  if (athenaOneParams) {
    const bannerType = athenaOneParams.BANNERTYPE;
    if (bannerType && [ 'TIMEDOUT', 'SESSIONLOCKED', 'CONCIERGEMODE' ].includes(bannerType)) {
      return 'TIMEDOUT';
    }
  }
  return 'REFRESHTIMEOUT';
};
