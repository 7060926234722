import athenaConfig from '../config';
import { getTransformedError } from './i18n-utils';
import { getAnetUsername } from './user-utils';
import { getOIEStateHandle, getStateToken } from './widget-facade';

const {
  athenaOneSymantecPromptUrl,
  athenaOneOieChangeExpiredPasswordUrl,
  athenaOneChangeExpiredPasswordUrl,
  getSecurityQuestionsTokenUrl,
} = athenaConfig;

interface Username {
  username: string;
}

export interface ShouldPromptForSymantecPromptOieArgs extends Username {
  stateHandle: string;
}

export interface ShouldPromptForSymantecPromptClassicArgs extends Username {
  stateToken: string;
}

export async function shouldPromptForSymantecVIP(data: ShouldPromptForSymantecPromptClassicArgs) {
  try {
    const response = await makeAnetRequest({
      url: athenaOneSymantecPromptUrl,
      data,
    });
    if (response.prompt_symantec_vip_field) {
      return response.prompt_symantec_vip_field === 'true';
    }
    else {
      throw response;
    }
  }
  catch (error: any) {
    error.checkFailed = true;
    throw error;
  }
}

export async function shouldPromptForSymantecVIPOIE(data: ShouldPromptForSymantecPromptOieArgs) {
  try {
    const response = await makeAnetOieRequest({
      url: athenaOneSymantecPromptUrl,
      data,
    });
    if (response.prompt_symantec_vip_field) {
      return response.prompt_symantec_vip_field === 'true';
    }
    else {
      throw response;
    }
  }
  catch (error: any) {
    error.checkFailed = true;
    throw error;
  }
}

async function makePostRequest(opts : any) {
  return await global.oktaSignIn.authClient.invokeApiMethod(opts);
}

/**
 * Makes http request to the given url along with the given data as urlencoded
 * form data.
 * Tries to parse if the content is json
 * or text(return text if json parse fails)
 */
export async function makeAnetRequest({ url, data }: { url: URL | string; data: any }) {
  let response;
  try {
    response = await makePostRequest({
      url,
      method: 'POST',
      args: data,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  }
  catch (err: any) {
    response = err;
    response.requestError = true;
  }

  const responseContentType = response.headers?.['content-type'] ?? response.xhr?.headers?.['content-type'];
  let finalResponse;
  if (responseContentType?.indexOf('application/json') > -1) {
    finalResponse = response;
  }
  else {
    const { responseText } = response;
    if (responseText) {
      try {
        finalResponse = JSON.parse(responseText);
      }
      catch (e) {
        finalResponse = responseText;
      }
    }
    else {
      finalResponse = response;
    }
  }
  finalResponse.xhr = response.xhr;
  //If errorSummary is present, that means we are either in classic mode or there is failure in validating symantec flow
  // (even for OIE), throw error in both cases, and it gets handled correctly in both flows
  if (finalResponse.errorSummary || finalResponse.xhr?.responseJSON?.errorSummary) {
    const mayBeErrorKey = finalResponse.errorSummary;
    finalResponse.errorSummary = getTransformedError({ response: finalResponse });
    if (finalResponse.xhr?.responseJSON?.errorSummary) {
      finalResponse.xhr.responseJSON.errorSummary = finalResponse.errorSummary;
    }
    finalResponse.errorKey = mayBeErrorKey;
    throw finalResponse;
  }
  return finalResponse;
}

export interface PasswordResetOieData {
  newPassword: string;
  stateHandle: string;
  username: string;
  symantecSecurityCode?: string;
}

export interface PasswordResetClassicData {
  newPassword: string;
  oldPassword: string;
  username: string;
  stateToken: string;
  symantecSecurityCode?: string;
}

export async function passwordResetOie(data: PasswordResetOieData) {
  return makeAnetOieRequest({
    url: athenaOneOieChangeExpiredPasswordUrl,
    data,
    method: 'POST',
  });
}

export async function passwordResetClassic(data: PasswordResetClassicData) {
  return makeAnetRequest({
    url: athenaOneChangeExpiredPasswordUrl,
    data,
  });
}

export async function makeAnetOieRequest({ url, data }: any) {
  const response = await makeAnetRequest({
    url,
    data,
  });
  //OIE needs the error response itself, not the outer error object set by the above function
  if (response.xhr) {
    return response.xhr.responseJSON;
  }
  if (response.requestError) {
    throw response;
  }
  return response;
}

function getSecurityQuestionsPayloadClassic() : any {
  return {
    username: getAnetUsername(),
    stateToken: getStateToken(),
  };
}

function getSecurityQuestionsPayloadOie() : any {
  return {
    username: getAnetUsername(),
    stateHandle: getOIEStateHandle(),
  };
}

function validateGetSecurityQuestionsPayload({ oieEnabled, data }: { oieEnabled: boolean; data: any }) {
  if (!data?.username) {
    return false;
  }
  if (!oieEnabled) {
    if (!data.stateToken) {
      return false;
    }
  }
  else {
    if (!data.stateHandle) {
      return false;
    }
  }
  return true;
}

/**
 * Function makes POST call to /1/1/login/getsqtoken.esp endpoint to generate a
 * security questions JWT if the user has not setup answers to their security
 * questions.
 * @returns parsed JSON response.
 */
export async function getSecurityQuestionsPromptToken() {
  const { oieEnabled } = athenaConfig;
  const data = !oieEnabled ? getSecurityQuestionsPayloadClassic() : getSecurityQuestionsPayloadOie();
  if (validateGetSecurityQuestionsPayload({ oieEnabled, data }) === true) {
    if (!oieEnabled) {
      return await makeAnetRequest({
        url: getSecurityQuestionsTokenUrl,
        data,
      });
    }
    else {
      return await makeAnetOieRequest({
        url: getSecurityQuestionsTokenUrl,
        data,
      });
    }
  }
}
