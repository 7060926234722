import { useMemo, useState } from 'react';
import { Banner, BannerItem } from '@athena/forge';
import { useMutationObserver } from '../../hooks/useMutationObserver';
import { I18N_MAPPING, INVALID_SESSION_MESSAGE } from '../../constants';
import { useWidgetContext } from '../context/WidgetContext';
import { isAthenaOneWebClient } from '../../utils/app-utils';
import InvalidUsernameOrPasswordBanner from '../primary-auth/InvalidUsernameOrPasswordBanner';
import UserLockedOutBanner from '../primary-auth/UserLockedOutBanner';
import { isPrimaryAuthController, navigateToPrimaryAuthWithError } from '../../utils/widget-facade';

function calcErrorState(oktaErrorContainer: JQuery) {
  const attentionError = oktaErrorContainer.find('.okta-form-infobox-warning');
  const criticalError = oktaErrorContainer.find('.okta-form-infobox-error, .infobox-error');
  return {
    criticalErrorMessage: criticalError.text(),
    attentionErrorMessage: attentionError.find('p').text(),
  };
}

export default function ErrorContainer({ oktaErrorContainer }: { oktaErrorContainer: JQuery }) {
  const [ errorState, setErrorState ] = useState(calcErrorState(oktaErrorContainer));
  const { criticalErrorMessage, attentionErrorMessage } = errorState;

  const { clientId, controller } = useWidgetContext();
  const displayInvalidUsernameOrPasswordBanner =
    criticalErrorMessage === I18N_MAPPING['en']['errors.E0000004'] && clientId && isAthenaOneWebClient(clientId);
  const displayUserLockedOutBanner =
    criticalErrorMessage === I18N_MAPPING['en']['errors.E0000119'] && clientId && isAthenaOneWebClient(clientId);
  const useMutationArgs = useMemo(() => {
    return {
      filterOptions: { childList: true },
      onMutation: () => {
        setErrorState(calcErrorState(oktaErrorContainer));
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useMutationObserver({
    element: oktaErrorContainer[0],
    ...useMutationArgs,
  });

  function returnBannerComponent() {
    if (displayUserLockedOutBanner) {
      return (
        <>
          {<UserLockedOutBanner />}
        </>
      );
    }
    else if (displayInvalidUsernameOrPasswordBanner) {
      return (
        <>
          {<InvalidUsernameOrPasswordBanner />}
        </>
      );
    }
    else if (criticalErrorMessage) {
      return (
        <>
          {criticalErrorMessage && (
            <Banner alertType={'critical'} className="fe_u_padding--small">
              <BannerItem>{criticalErrorMessage}</BannerItem>
            </Banner>
          )}
        </>
      );
    }
  }

  if (criticalErrorMessage.includes(INVALID_SESSION_MESSAGE) && !isPrimaryAuthController(controller)) {
    navigateToPrimaryAuthWithError(INVALID_SESSION_MESSAGE);
  }

  if (criticalErrorMessage || attentionErrorMessage) {
    return (
      <div className="fe_u_margin--bottom-medium">
        {returnBannerComponent()}
        {criticalErrorMessage && attentionErrorMessage && <div className="fe_u_margin--top-small"></div>}
        {attentionErrorMessage && (
          <Banner alertType={'attention'} className="fe_u_padding--small">
            <BannerItem>{attentionErrorMessage}</BannerItem>
          </Banner>
        )}
      </div>
    );
  }
  else {
    return <></>;
  }
}
