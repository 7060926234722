import AmplitudeUtils from './amplitude-utils';
import { getSecurityQuestionsPromptToken } from './anet-client';
import { isAthenaOneWebSsoSessionTimedOut, isAuthnWithAthenaOneWebClient } from './app-utils';
import { getAthenaOneParamsFromJwt, getBannerConfig } from './banner-jwt-utils';
import {
  clearStorage,
  getShowBannerValue,
  isSecurityQuestionsTokenCookieSet,
  setSecurityQuestionsPromptCookies
} from './storage-utils';

export const shouldSqTokenEndpointBeCalled = () => {
  return getShowBannerValue() === 'LOGOUT' && isAuthnWithAthenaOneWebClient() && !isSecurityQuestionsTokenCookieSet();
};

export const fetchAndStoreSqToken = async () => {
  const response = await getSecurityQuestionsPromptToken();
  if (response?.sqtoken) {
    setSecurityQuestionsPromptCookies({ sqtoken: response.sqtoken });
  }
};

export const getCustomLoginMetadata = (bannerConfig: any) => {
  if (bannerConfig?.bannerType) {
    const metadata = { ...bannerConfig };
    delete metadata.header;
    delete metadata.description;
    delete metadata.alertType;
    return metadata;
  }
  return {
    isSso: !!(new URLSearchParams(window.location.search).get('idp')),
  };
};

export const onSuccessfulAuth = async () => {
  const athenaOneParams = await getAthenaOneParamsFromJwt();
  const bannerProps = getBannerConfig(athenaOneParams, isAthenaOneWebSsoSessionTimedOut(athenaOneParams));
  await AmplitudeUtils.logCustomEvent('Success', getCustomLoginMetadata(bannerProps));
  clearStorage();
};

export const getConfigForStateTokenOverrideFlow = (config: any, stateToken: string) => {
  const {
    authentication: {
      request: { redirect_uri: redirectUri, response_mode: responseMode, response_type: responseType, scope, state },
      issuer: { uri: issuerUri },
      client: { id: clientId },
    },
  } = OktaUtil.getRequestContext();

  const scopes = scope.trim().split(/\s+/g);
  const url = new URL(window.location.href);
  const searchParams = url.searchParams;
  const nonce = searchParams.get('nonce');
  const authParams = {
    responseMode,
    responseType,
    scopes,
    state,
    nonce,
    pkce: !!(searchParams.get('code_challenge_method') && searchParams.get('code_challenge')),
    issuer: issuerUri,
    authorizeUrl: window.location.origin + window.location.pathname,
  };

  const stateTokenOverrideFlowConfig = {
    ...config,
    issuer: issuerUri,
    clientId,
    redirectUri,
    state,
    scopes,
    nonce,
    authParams,
    idpDiscovery: {
      requestContext: stateToken,
    },
    stateToken,
    useClassicEngine: true,
    el: '#okta-login-container',
    relayState: undefined,
  };

  return stateTokenOverrideFlowConfig;
};
