import { Root } from '@athena/forge';
import { createRoot } from 'react-dom/client';
import React from 'react';
import WidgetContextProvider from '../components/context/WidgetContextProvider';
import { IWidgetContext } from '../components/context/WidgetContext';
import ErrorBoundary from '../components/common/ErrorBoundary';

export interface RenderReactComponentArgs {
  id: string;
  component: React.ReactNode;
  container: HTMLElement;
  className?: string;
}

export type FactoryReturnType = (args: RenderReactComponentArgs) => void;

export default function factory(widgetContext: IWidgetContext): FactoryReturnType {
  return function renderReactComponent({ id, component, container, className = '' }) {
    const forgifiedId = `athena-${id}`;
    if (document.getElementById(forgifiedId)) {
      return;
    }
    container.id = forgifiedId;
    const root = createRoot(container);
    root.render(
      <ErrorBoundary>
        <WidgetContextProvider {...widgetContext}>
          <Root className={className}>
            {component}
          </Root>
        </WidgetContextProvider>
      </ErrorBoundary>
    );
  };
}

export function createWrapper({ hidden = true } = {}) {
  const wrapper = jQueryCourage('<div></div>');
  if (hidden) wrapper.addClass('hide-element');
  return wrapper[0];
}
