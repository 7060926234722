import React from 'react';
import { useWidgetContext } from '../context/WidgetContext';
import { getTAndCTextOnLoginDescription } from '../../utils/i18n-utils';

export default function TermsContainer() {
  const { language, athenaOneWebSsoSessionTimedOut } = useWidgetContext();
  if (athenaOneWebSsoSessionTimedOut) return <></>;
  return (
    <div className='fe_u_margin--top-small fe_u_margin--bottom-large'>
      <p className='fe_u_font-size--xsmall fe_u_font-weight--regular fe_u_color--muted'>
        {getTAndCTextOnLoginDescription(language)}
      </p>
    </div>
  );
}
