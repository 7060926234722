import { formatUnixTimestamp } from '../../utils/date-utils';
import { useWidgetContext } from '../context/WidgetContext';

export default function LastLoginTimeStamp() {
  const { athenaOneParams } = useWidgetContext();
  const lastLoginTime = athenaOneParams?.LASTLOGINET;
  if (lastLoginTime) {
    const timestamp = formatUnixTimestamp(lastLoginTime);
    return (
      <>
        {
          timestamp ?
            <div className='fe_u_margin--top-small fe_u_margin--bottom-medium'>
              <p className='fe_u_font-size--small fe_u_font-weight--regular fe_u_color--muted'>
                {timestamp}
              </p>
            </div> : <></>
        }
      </>
    );
  }
  else {
    return <></>;
  }
}
