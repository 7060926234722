import config from '../../../config';
import { CONTACT_ADMIN_TEXT } from '../../../constants';
import BaseForgeCustomizer from '../../base-forge';

const { oieEnabled } = config;

export default class AuthenticatorVerificationDataOieCustomizer extends BaseForgeCustomizer {
  footerText = CONTACT_ADMIN_TEXT;
  primaryButtonClassName = 'fe_u_margin--bottom-small';
  async shouldCustomize() {
    return forgify && [ 'authenticator-verification-data' ].includes(this.context.formName) && oieEnabled;
  }

}
