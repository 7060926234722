import { CONTACT_ADMIN_TEXT, STEPS_TO_FINISH_SETUP_TEXT } from '../../constants';
import BaseForgeCustomizer from '../base-forge';
import MutedText from '../../components/common/MutedText';
import ProxyButton from '../../components/common/ProxyButton';

export default abstract class BarcodePushBaseCustomizer extends BaseForgeCustomizer {
  protected scanInstructionsTextSelector: string = '';
  protected cantScanTextSelector: string = '';
  headerText = STEPS_TO_FINISH_SETUP_TEXT;
  footerText = CONTACT_ADMIN_TEXT;

  protected forgifyController() {
    this.forgifyScanInstructionsText();
    this.forgifyCantScanText();
    super.forgifyController();
  }
  protected forgifyScanInstructionsText() {
    const scanInstructionsDetails: JQuery<HTMLElement> = jQueryCourage(this.scanInstructionsTextSelector).eq(0);
    if (scanInstructionsDetails.length) {
      const forgeComponent = <MutedText className="scan-instructions-details" text={scanInstructionsDetails.text()} />;
      this.renderForgeAndHideOktaComponent({
        id: scanInstructionsDetails.attr('class')!,
        oktaComponent: scanInstructionsDetails,
        forgeComponent: forgeComponent,
      });
    }
  }

  protected forgifyCantScanText() {
    const cantScanLink: JQuery<HTMLElement> = jQueryCourage(this.cantScanTextSelector);
    if (cantScanLink.length) {
      const forgeComponent = <ProxyButton oktaButton={cantScanLink} fullWidth variant="tertiary" />;
      this.renderForgeAndHideOktaComponent({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        id: cantScanLink.attr('class')!,
        oktaComponent: cantScanLink,
        forgeComponent: forgeComponent,
      });
    }
  }

}
