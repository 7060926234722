import RefreshAuthStateCustomizer from './refresh-auth-state';
import PasswordResetClassic from './password-expired/password-expired-classic';
import PasswordResetOieCustomizer from './password-expired/password-expired-oie';
import PrimaryAuthOieCustomizer from './primary-auth/oie';
import PrimaryAuthClassicCustomizer from './primary-auth/classic';
import BaseCustomizer from './base';
import CommonCustomizer from './common';
import GranularConsentCustomizer from './granular-consent';
import logger from '../../logger';
import ClassicFactorEnrollCustomizer from './factors/enroll-factors-classic';
import OieFactorEnrollCustomizer from './factors/enroll-factors-oie';
import OieFactorChallengeCustomizer from './factors/challenge-factors-oie';
import TerminalCustomizer from './terminal';
import EnrollTotpClassicCustomizer from './enroll-totp/classic';
import ActivatePushClassicCustomizer from './activate-push/classic';
import BarcodePushClassicCustomizer from './barcode-push/classic';
import EnrollActivationLinkSentClassicCustomizer from './enroll-activation-link-sent/classic';
import EnrollPhoneFactorCustomizer from './enroll-phone/classic';
import EnrollManualPushClassicCustomizer from './enroll-manual-push/classic';
import EnrollManualPushOieCustomizer from './enroll-manual-push/oie';
import SuccessRedirect from './success-redirect';
import AmplitudeUtils from '../utils/amplitude-utils';
import MfaVerifyClassicCustomizer from './mfa-verify/classic';
import EnrollPollQrCodeCustomizer from  './okta-verify-enroll-oie/enroll-poll/qrcode';
import EnrollPollSmsCustomizer from  './okta-verify-enroll-oie/enroll-poll/sms';
import SelectEnrollmentChannelCustomizer from './okta-verify-enroll-oie/select-enrollment-channel';
import EnrollmentChannelDataCustomizer from './okta-verify-enroll-oie/enrollment-channel-data';
import EnrollPhoneFactorCustomizerOie from './enroll-phone/oie';
import BarcodePushOieCustomizer from './barcode-push/oie';
import FactorVerifyOieCustomizer from './mfa-verify/oie/factor-verify';
import AuthenticatorVerificationDataOieCustomizer from './mfa-verify/oie/authenticator-verification-data';

import { unhideWidget } from '../utils/widget-facade';

export interface CustomizeControllerArgs {
  requestContext: any;
  context: any;
  appConfigs: any;
  config: any;
  oktaSignIn: any;
}

function getCustomizers(initArgs: CustomizeControllerArgs): Record<string, BaseCustomizer> {
  const commonCustomizer = new CommonCustomizer(initArgs);
  const primaryAuthOieCustomizer = new PrimaryAuthOieCustomizer(initArgs);
  const primaryAuthClassicCustomizer = new PrimaryAuthClassicCustomizer(initArgs);
  const passwordResetClassicCustomizer = new PasswordResetClassic(initArgs);
  const granularConsentCustomizer = new GranularConsentCustomizer(initArgs);
  const passwordResetOieCustomizer = new PasswordResetOieCustomizer(initArgs);
  const classicFactorEnrollCustomizer = new ClassicFactorEnrollCustomizer(initArgs);
  const oieFactorEnrollCustomizer = new OieFactorEnrollCustomizer(initArgs);
  const terminalCustomizer = new TerminalCustomizer(initArgs);
  const oieFactorChallengeCustomizer = new OieFactorChallengeCustomizer(initArgs);
  const enrollTotpClassicCustomizer = new EnrollTotpClassicCustomizer(initArgs);
  const activatePushClassicCustomizer = new ActivatePushClassicCustomizer(initArgs);
  const barcodePushClassicCustomizer = new BarcodePushClassicCustomizer(initArgs);
  const enrollActivationLinkSentClassicCustomizer = new EnrollActivationLinkSentClassicCustomizer(initArgs);
  const enrollManualPushClassicCustomizer = new EnrollManualPushClassicCustomizer(initArgs);
  const enrollPhoneFactorCustomizer = new EnrollPhoneFactorCustomizer(initArgs);
  const refreshAuthStateCustomizer = new RefreshAuthStateCustomizer(initArgs);
  const mfaVerifyClassicCustomizer = new MfaVerifyClassicCustomizer(initArgs);
  const enrollPollQrCodeCustomizer = new EnrollPollQrCodeCustomizer(initArgs);
  const enrollPollSmsCustomizer = new EnrollPollSmsCustomizer(initArgs);
  const selectEnrollmentChannelCustomizer = new SelectEnrollmentChannelCustomizer(initArgs);
  const enrollmentChannelDataCustomizer = new EnrollmentChannelDataCustomizer(initArgs);
  const enrollPhoneFactorCustomizerOie = new EnrollPhoneFactorCustomizerOie(initArgs);
  const barcodePushOieCustomizer = new BarcodePushOieCustomizer(initArgs);
  const enrollManualPushOieCustomizer = new EnrollManualPushOieCustomizer(initArgs);
  const factorVerifyOieCustomizer = new FactorVerifyOieCustomizer(initArgs);
  const authenticatorVerificationDataOieCustomizer = new AuthenticatorVerificationDataOieCustomizer(initArgs);

  const successRedirect = new SuccessRedirect(initArgs);
  return {
    commonCustomizer,
    refreshAuthStateCustomizer,
    primaryAuthOieCustomizer,
    primaryAuthClassicCustomizer,
    passwordResetClassicCustomizer,
    granularConsentCustomizer,
    passwordResetOieCustomizer,
    classicFactorEnrollCustomizer,
    oieFactorEnrollCustomizer,
    oieFactorChallengeCustomizer,
    terminalCustomizer,
    enrollTotpClassicCustomizer,
    activatePushClassicCustomizer,
    barcodePushClassicCustomizer,
    enrollActivationLinkSentClassicCustomizer,
    enrollManualPushClassicCustomizer,
    mfaVerifyClassicCustomizer,
    successRedirect,
    enrollPhoneFactorCustomizer,
    enrollPollQrCodeCustomizer,
    enrollPollSmsCustomizer,
    selectEnrollmentChannelCustomizer,
    enrollmentChannelDataCustomizer,
    enrollPhoneFactorCustomizerOie,
    barcodePushOieCustomizer,
    enrollManualPushOieCustomizer,
    factorVerifyOieCustomizer,
    authenticatorVerificationDataOieCustomizer,
  };
}

export default async function customizeController(initArgs: CustomizeControllerArgs) {
  const customizers = getCustomizers(initArgs);
  for (const customizerName in customizers) {
    const customizer = customizers[customizerName];
    try {
      await customizer.performCustomization();
    }
    catch (e: any) {
      logger.error(`error occurred while customizing in ${customizerName}`, e);
      AmplitudeUtils.logCustomizerError({ customizerName, ...e, errorMessage: e.message, errorName: e.name });
    }
  }
  // widget got hidden with the custom logic added on render function
  unhideWidget();
}
