import { AlertTypes, Banner as ForgeBanner, BannerItem as ForgeBannerItem } from '@athena/forge';
export interface BannerProps {
  children?: React.ReactNode;
  alertType: AlertTypes;
  headerText?: string;
}

export default function Banner({ children, alertType, headerText }: BannerProps) {
  return (
    <div className="fe_u_margin--bottom-medium">
      <ForgeBanner alertType={alertType} className="fe_u_padding--small">
        <ForgeBannerItem headerText={headerText}>{children}</ForgeBannerItem>
      </ForgeBanner>
    </div>
  );
}
