import BaseForgeCustomizer from './base-forge';
import { applyMarketingFailoverLayout, applyMarketingLayout } from '../utils/layout-utils';
import { isAthenaOneWebClient } from '../utils/app-utils';
import athenaConfig from '../config';

const {  marketingContentUrl } = athenaConfig;

export default class RefreshAuthStateCustomizer extends BaseForgeCustomizer {
  async shouldCustomize() {
    return 'refresh-auth-state' === this.context?.controller;
  }

  async customize() {
    this.customizeLogo();
    this.forgifyWidgetContainer();
    if (forgify && isAthenaOneWebClient(this.clientId)) {
      if (marketingContentUrl) {
        applyMarketingLayout();
      }
      else {
        applyMarketingFailoverLayout();
      }
    }
  }
}
