import { Root } from '@athena/forge';

import React from 'react';

export default function PasswordRequirements ({
  passwordRequirements,
  headerSelector = '.password-requirements--header',
  itemListSelector = '.password-requirements--list',
}: {
  passwordRequirements: JQuery<HTMLElement>;
  headerSelector?: string;
  itemListSelector?: string;
}) {
  const headerText = passwordRequirements.find(headerSelector).text();
  const items = passwordRequirements.find(itemListSelector).children();

  return (
    <Root>
      <div className="fe_u_color--muted password-requirements--header">{headerText}</div>
      <ul className="password-requirements--list">
        {items.map((index, element) => {
          return (
            <li className="password-requirements--list-item" key={index}>
              <div className="fe_u_color--muted">{items.eq(index).text()}</div>
            </li>
          );
        })}
      </ul>
    </Root>
  );
}
