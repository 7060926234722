import { useEffect, useMemo, useRef } from 'react';
import withWidgetContext from '../context/withWidgetContext';
import { IWidgetContext, useWidgetContext } from '../context/WidgetContext';
import AmplitudeUtils from '../../utils/amplitude-utils';

interface MarketingContentProps extends IWidgetContext {
  marketingContentUrl?: URL;
}

export default withWidgetContext(function MarketingContent({ marketingContentUrl }: MarketingContentProps) {
  const { eventEmitter } = useWidgetContext();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const randomId = useMemo(() => Math.random(), []);

  useEffect(() => {
    // Follow approach https://stackoverflow.com/questions/2524502/refresh-iframe-cache-issue/11299756#11299756
    // to dynamically set iframe URL to enforce refect of marketing html
    if (iframeRef.current && marketingContentUrl) {
      iframeRef.current.src = `${marketingContentUrl.href}?v=${randomId}`;
    }
  }, [ iframeRef ]);

  useEffect(() => {
    async function checkContent() {
      try {
        if (marketingContentUrl) {
          const response = await fetch(marketingContentUrl, {
            method: 'GET',
            cache: 'no-cache',
          });
          AmplitudeUtils.logCustomEvent('FetchMarketingContentSuccess', { });
          if (!response.ok) {
            eventEmitter.emit('marketing-content-failed');
          }
        }
      }
      catch (err) {
        eventEmitter.emit('marketing-content-failed');
      }
    }
    checkContent();
  }, [ marketingContentUrl, eventEmitter ]);
  return marketingContentUrl ? (
    <iframe
      src=""
      ref={iframeRef}
      allowFullScreen
      title={'Marketing Content'}
      className="marketing-content-iframe"
      loading="eager"
    />
  ) : (
    <></>
  );
});
