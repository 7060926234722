import ManualTotpNextButton from '../../components/enroll-manual-push/ManualTotpNextButton';
import SecretKeyInstructionsClassic from '../../components/enroll-manual-push/SecretKeyInstructionsClassic';
import athenaConfig from '../../config';
import { CONTACT_ADMIN_TEXT, STEPS_TO_FINISH_SETUP_TEXT } from '../../constants';
import EnrollManualPushBaseCustomizer from './base';
const { classic } = athenaConfig;

export default class EnrollManualPushClassicCustomizer extends EnrollManualPushBaseCustomizer {
  headerText = STEPS_TO_FINISH_SETUP_TEXT;
  footerText = CONTACT_ADMIN_TEXT;

  async shouldCustomize() {
    return (await super.shouldCustomize()) && classic;
  }

  async customize() {
    this.hideOktaVerifyEmailOption();
    await super.customize();
    if (forgify) {
      this.forgifyManualTotpNextButton();
      this.forgifySecretKeyInstructionsText();
      this.replaceComponents();
    }
  }

  protected hideOktaVerifyEmailOption() {
    jQueryCourage('li:contains("Send activation link via email")').remove();
    jQueryCourage('option:contains("Send activation link via email")').remove();
  }

  private forgifyManualTotpNextButton() {
    const buttonContainer = jQueryCourage('[data-type="next-button-wrap"]');
    if (buttonContainer.length) {
      const parentContainer = buttonContainer.parent();
      if (parentContainer.length) {
        const forgeComponent = <ManualTotpNextButton container={parentContainer} />;
        this.renderForgeAndHideOktaComponent({
          id: 'manual-totp-next-button',
          oktaComponent: parentContainer,
          forgeComponent: forgeComponent,
        });
      }
    }
  }

  private forgifySecretKeyInstructionsText() {
    const oktaSecretKeyInstructions: JQuery<HTMLElement> = jQueryCourage('.secret-key-instructions');
    if (oktaSecretKeyInstructions.length) {
      const forgeComponent = <SecretKeyInstructionsClassic container={oktaSecretKeyInstructions} />;
      this.renderForgeAndHideOktaComponent({
        id: oktaSecretKeyInstructions.attr('class')!,
        oktaComponent: oktaSecretKeyInstructions,
        forgeComponent: forgeComponent,
      });
    }
  }
}
