import BaseForgeCustomizer from '../base-forge';
import { CONTACT_ADMIN_TEXT, STEPS_TO_FINISH_SETUP_TEXT } from '../../constants';

export default abstract class EnrollManualPushBaseCustomizer extends BaseForgeCustomizer {
  headerText = STEPS_TO_FINISH_SETUP_TEXT;
  footerText = CONTACT_ADMIN_TEXT;
  async shouldCustomize() {
    // common customizations between oktaVerify and googleAuthenticator controllers
    return [ 'enroll-manual-push', 'enroll-manual-totp' ].includes(this.context?.controller);
  }
}
