import logger from '../../logger';
import athenaConfig from '../config';
import { TerminalError } from './errors';

const { oieEnabled } = athenaConfig;

export const $ = global.jQueryCourage;

const AUTH_CONTAINER_SELECTOR = '.auth-content-inner';

const getAppState = () => global.oktaSignIn.router.appState;

export function getOktaUserId() {
  return global.oktaSignIn?.getUser()?.id;
}

export function getClientId(requestContext: any) {
  try {
    const requestContextClientId = requestContext?.authentication?.client?.id;
    if (requestContextClientId) {
      return requestContextClientId;
    }
    // When appEmbeddedLink url (E.g. https://identity.athenahealth.com/home/athena_cornerstonesamlapp_1/0oabvwil1bS7qsc9e297/alnbvwv8v8M17DSpc297)
    // is loaded for classic okta env, clientId is absent in request context target and is present as issuer id
    const requestContextIssuer = requestContext?.authentication?.issuer;
    if (requestContextIssuer) {
      const { id, uri } = requestContextIssuer;
      if (id && uri) {
        const issuerUriParts = uri.split('/');
        const authServerId = issuerUriParts[issuerUriParts.length - 1];
        // Make sure we aren't returning auth server id as client id
        if (authServerId !== id) {
          return id;
        }
      }
    }
  }
  catch (e) {
    // eslint-disable-next-line no-empty
  }
}

export function getOIEStateHandle() {
  return getAppState().get('idx')?.context?.stateHandle;
}

export function getStateToken() {
  return getAppState()?.get('lastAuthResponse')?.stateToken;
}

export function showFormErrors(error: any) {
  if (oieEnabled) {
    showFormErrorsOie(error);
  }
  else {
    showFormErrorsClassic(error);
  }
}

function showFormErrorsOie(error: any) {
  const { controller } = global.oktaSignIn.router;
  const { formView } = controller;
  const { model, form } = formView;
  controller.showFormErrors(model, error, form);
}

function showFormErrorsClassic(error: any) {
  const model = global.oktaSignIn.router.controller.model;
  model.trigger('error', model, error.xhr);
  model.trigger('setTransactionError', error);
}

export function getRawAppName(requestContext: any) {
  if (requestContext?.target?.label) {
    //This is not set for SSO logins in OIE
    return requestContext.target.label;
  }
  else {
    return (
      global.oktaSignIn?.router?.appState.get('app')?.label || global.oktaSignIn?.router?.appState?.get('targetLabel')
    );
  }
}

function getFormElements() {
  return [
    jQueryCourage('.o-form-fieldset-container'),
    jQueryCourage('.o-form-button-bar'),
    jQueryCourage('.okta-form-title'),
    jQueryCourage('.ion-messages-container'),
    jQueryCourage('#athena-o-form-head'),
    jQueryCourage('#athena-footer'),
    jQueryCourage('#athena-forgot-password-link'),
    jQueryCourage('#athena-o-form-terms-container'),
    jQueryCourage('#athena-additional-links'),
  ];
}

export function hideWidgetForm() {
  const errorContainer = document.querySelector('.o-form-error-container');
  if (errorContainer) {
    errorContainer.textContent = '';
  }
  getFormElements().forEach((element) => {
    if (element) {
      element.hide();
    }
  });
}

function showWidgetForm() {
  getFormElements().forEach((element) => {
    if (element) {
      element.show();
    }
  });
}

function getSpinnerContainer() {
  const authContainer = document.querySelector('.auth-content') as HTMLElement;
  const spinnerElement = authContainer?.querySelector('.beacon-container') as HTMLElement;
  if (spinnerElement) {
    return spinnerElement;
  }
  else {
    return document.querySelector('.beacon-container') as HTMLElement;
  }
}

export const stopSpinner = () => {
  if (oieEnabled) {
    const container = getSpinnerContainer();
    container?.classList?.remove('beacon-loading');
  }
  else {
    getAppState()?.trigger('loading', false);
    getAppState()?.trigger('removeLoading');
  }
};
export const stopSpinnerAndShowForm = () => {
  try {
    stopSpinner();
    showWidgetForm();
  }
  catch (e) {
    logger.log(e);
  }
};

const startSpinner = () => {
  if (oieEnabled) {
    const container = getSpinnerContainer();
    container.classList.add('beacon-loading');
  }
  else {
    setTimeout(() => getAppState()?.trigger('loading', true));
  }
};
export const startSpinnerAndHideForm = () => {
  try {
    startSpinner();
    hideWidgetForm();
  }
  catch (e) {
    logger.log(e);
  }
};

export function getOktaWidgetModel() {
  if (oieEnabled) {
    return global.oktaSignIn.router.controller.formView.model;
  }
  else {
    return global.oktaSignIn.router.controller.model;
  }
}

function getEmailFromUsername(): string {
  const username = getAppState().get('username');
  return username && oktaSignIn.router.settings.transformUsername(username, 'PRIMARY_AUTH');
}

export function getUsername(): string {
  let login;
  if (oieEnabled) {
    login = getAppState().get('user')?.identifier;
  }
  else {
    login = getAppState().get('userEmail') || getEmailFromUsername();
  }
  return login;
}

export function getUserPhoneNumber(): string {
  return getAppState().get('userPhoneNumber');
}

export function getSelectedProvider(): string {
  return global.oktaSignIn.router?.controller?.options?.provider;
}

export function getSelectedFactorType(): string {
  return global.oktaSignIn.router?.controller?.options?.factorType;
}

export function getCountryCallingCode(countryCode: string): string {
  const model = getOktaWidgetModel();
  if (oieEnabled) {
    return '+' + model.derived.phoneCode.fn(countryCode);
  }
  return model.derived.countryCallingCode.fn(countryCode);
}

export function isPrimaryAuthController(controller: string | undefined): boolean {
  return !!(controller && [ 'primary-auth', 'idp-discovery', 'mfa-verify-password' ].includes(controller));
}

export const navigateToPrimaryAuthWithError = (errorMessage: string): void => {
  const router = global.oktaSignIn.router;
  getAppState().set('flashError', new TerminalError(errorMessage));
  router.controller.state.set('navigateDir', 'DIRECTION_BACK');
  router.navigate('signin', { trigger: true });
};

export const isRequestFromSamlClient = (requestContext: any): boolean => {
  return requestContext?.target?.name !== 'oidc_client';
};

/**
 * For a smooth render between okta screen and forge styled screens
 * we hide the main content before render and unhide after every customization
 */
export const applyContentHidingForCustomization = () => {
  const originalRender = global.oktaSignIn.router.render.bind(global.oktaSignIn.router);
  global.oktaSignIn.router.render = (...args: any[]) => {
    jQueryCourage(AUTH_CONTAINER_SELECTOR).addClass('hide-element');
    originalRender.apply(global.oktaSignIn.router, args);
  };
};

export function unhideWidget() {
  jQueryCourage(AUTH_CONTAINER_SELECTOR).removeClass('hide-element');
}

export function getAuthenticatorSelectedChannel() {
  return oktaSignIn.router?.controller?.options?.appState?.get('currentAuthenticator')?.contextualData?.selectedChannel;
}

export async function closeOktaSession() {
  try {
    await oktaSignIn.authClient.session.close();
  }
  catch (err) { /* empty */ }
}
