import ProxyButton, { ProxyButtonProps } from '../common/ProxyButton';
import { useState } from 'react';
import { useMutationObserver } from '../../hooks/useMutationObserver';

export type SendCodeButtonProps = Omit<
  ProxyButtonProps,
  'fullWidth' | 'textToOverride' | 'disabled' | 'className'
>;

export default function SendCodeButton({ oktaButton, ...passthroughProps }: Readonly<SendCodeButtonProps>) {
  const [ buttonText, setButtonText ] = useState(oktaButton.text());
  const [ disabled, setDisabled ] = useState(false);

  useMutationObserver({
    element: oktaButton[0],
    filterOptions: { attributes: true, subtree: true },
    onMutation: () => {
      if (oktaButton.length) {
        // Behind the scene Okta uses setTimeout to enable/disable these buttons,
        // so listen to button text changes to relay the same effect to Forge buttons
        setButtonText(oktaButton.text());
        setDisabled([ 'Sent', 'Calling' ].includes(oktaButton.text()));
      }
    },
  });

  return (
    <ProxyButton
      {...passthroughProps}
      oktaButton={oktaButton}
      className='resend-code-button'
      fullWidth
      textToOverride={buttonText}
      disabled={disabled}
    />
  );
}
