import athenaConfig from '../config';
import { CLIENTID_ATHENAONE_HOST_MAPPING } from '../constants';
import { AthenaOneParams } from './banner-jwt-utils';
import { getAnetUsername } from './user-utils';
import { getClientId } from './widget-facade';
const { athenaOneWebClientIds, athenaOneMobileClientIds, env } = athenaConfig;

export function isAthenaOneWebClient(appId: string): boolean {
  return athenaOneWebClientIds.includes(appId);
}

export function isAthenaOneWebOrMobileClient(appId: string): boolean {
  return isAthenaOneWebClient(appId) || athenaOneMobileClientIds.includes(appId);
}

export function isAuthnWithAthenaOneWebClient(): boolean {
  const authnClientId = getClientId(OktaUtil.getRequestContext());
  return authnClientId ? isAthenaOneWebClient(authnClientId) : false;
}

export function getAthenaOneLoginUrl(clientId?: string) {
  const { defaultAthenaOneLoginUrl } = athenaConfig;
  if (clientId) {
    const athenaOneHost = CLIENTID_ATHENAONE_HOST_MAPPING[clientId];
    if (athenaOneHost) {
      return `https://${athenaOneHost}/login.esp`;
    }
  }
  return defaultAthenaOneLoginUrl;
}

function getAthenaOneHost(clientId?: string) {
  const { defaultAthenaOneHost } = athenaConfig;
  let referrerHost;
  //If we are iframed, this should be set, so use this to support dev servers
  if (document.referrer && env !== 'prod') {
    referrerHost = new URL(document.referrer).host;
  }
  return (clientId && CLIENTID_ATHENAONE_HOST_MAPPING[clientId]) || referrerHost || defaultAthenaOneHost;
}

const _getBasePathForAthenaOneUrl = (athenaOneParams?: AthenaOneParams) => {
  const practiceId = athenaOneParams?.PRACTICEID;
  const stackNumber = athenaOneParams?.STACKNUMBER;
  let basePath = '1/1';
  if (practiceId && stackNumber) {
    basePath = `${practiceId}/${stackNumber}`;
  }
  return basePath;
};

export function getAthenaOneSwitchUserUrl(clientId?: string, athenaOneParams?: AthenaOneParams) {
  const athenaOneHost = getAthenaOneHost(clientId);
  const reAuthUrl = new URL(`https://${athenaOneHost}`);
  const oktaIdpId = athenaOneParams?.SSOREAUTHPARAMS?.OKTAIDPID;
  const basePath = _getBasePathForAthenaOneUrl(athenaOneParams);
  reAuthUrl.pathname = `${basePath}/logout.esp`;
  if (oktaIdpId) {
    const loginUrl = `/${basePath}/login/startoidc.esp?IDP=${oktaIdpId}&PROMPT=login`;
    reAuthUrl.searchParams.set('LOGINURL', loginUrl);
  }
  else {
    reAuthUrl.searchParams.set('FORCELOGIN', '1');
  }
  return reAuthUrl.toString();
}

export function getAthenaOneSsoReauthUrl(clientId: string, athenaOneParams: AthenaOneParams) {
  const reAuthUrl = new URL(`https://${getAthenaOneHost(clientId)}`);
  const basePath = _getBasePathForAthenaOneUrl(athenaOneParams);
  reAuthUrl.pathname = `${basePath}/login/oidc.esp`;
  const { SSOREAUTHPARAMS: ssoReauthParams } = athenaOneParams;
  ssoReauthParams && Object.keys(ssoReauthParams).forEach(param => {
    reAuthUrl.searchParams.set(param, ssoReauthParams[param]);
  });
  if (athenaOneParams.PRACTICEID) {
    reAuthUrl.searchParams.set('PRACTICEID', athenaOneParams.PRACTICEID);
  }
  reAuthUrl.searchParams.set('USERNAME', encodeURIComponent(getAnetUsername()));
  reAuthUrl.searchParams.set('REDIRECT', '1');
  reAuthUrl.searchParams.set('RESUMESESSION', '1');

  return reAuthUrl;
}

export const legacyIsAthenaOneWebSessionTimedOut = (bannerType?: string) => {
  // Below banner types indicate session was killed and widget was launched within an iframe
  return !!(bannerType && [ 'TIMEDOUT', 'SESSIONLOCKED', 'CONCIERGEMODE', 'REFRESHTIMEOUT' ].includes(bannerType));
};

export const isAthenaOneWebSessionTimedOut = (athenaOneParams?: AthenaOneParams): boolean => {
  // Below banner types indicate session was killed and widget was launched within an iframe
  return !!(
    athenaOneParams &&
    (
      [ 'TIMEDOUT', 'SESSIONLOCKED', 'CONCIERGEMODE', 'REFRESHTIMEOUT' ].includes(athenaOneParams.BANNERTYPE) ||
      // Below condition is a temporary fix to prevent WRONGUSER errors in monolith.
      // UIAMUA-6323 will implement a proper fix.
      (
        athenaOneParams.BANNERTYPE === 'LOGOUT' &&
        document.referrer &&
        document.location &&
        document.location.href &&
        document.referrer !== document.location.href)
    ) &&
    !(athenaOneParams.SSOREAUTHPARAMS && Object.keys(athenaOneParams.SSOREAUTHPARAMS).length)
  );
};

export const isAthenaOneWebSsoSessionTimedOut = (athenaOneParams?: AthenaOneParams): boolean => {
  // SSOREAUTHPARAMS is populated only for sso flow and widget launched in an iframe
  return !!(athenaOneParams?.SSOREAUTHPARAMS && Object.keys(athenaOneParams.SSOREAUTHPARAMS).length);
};
