import { CLIENTID_ATHENAONE_HOST_MAPPING } from '../../constants';
import Link from '../common/Link';
import athenaConfig from '../../config';
import { useWidgetContext } from '../context/WidgetContext';
import AmplitudeUtils from '../../utils/amplitude-utils';

const { athenaNetPasswordResetUrl } = athenaConfig;

function getForgotPasswordLink(clientId: string | undefined) {
  if (clientId) {
    const athenaOneHost = CLIENTID_ATHENAONE_HOST_MAPPING[clientId];
    if (athenaOneHost) {
      return `https://${athenaOneHost}/1/1/resetpassword.esp`;
    }
  }
}

function getForgotPasswordLinkDescription(language: string | undefined) {
  if (language?.includes('es')) {
    return '¿Olvidó la contraseña?';
  }
  return 'Forgot Password?';
}

function onClickForgotPassword() {
  AmplitudeUtils.logCustomEvent('ForgotPasswordButtonClick', { });
}

export default function ForgotPasswordLink() {
  const { language, clientId, athenaOneWebSsoSessionTimedOut } = useWidgetContext();
  if (athenaOneWebSsoSessionTimedOut) return <></>;
  const forgotPasswordUrl = getForgotPasswordLink(clientId) || athenaNetPasswordResetUrl;
  return (
    <Link text={getForgotPasswordLinkDescription(language)} href={forgotPasswordUrl} className='fe_u_font-size--small' onClick={onClickForgotPassword}/>
  );
}
