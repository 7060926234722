import InfoButtonSmall from '@athena/forge-icons/dist/InfoButtonSmall';
import { Popover } from '@athena/forge';
import React, { useRef } from 'react';

export default function SymantecCodeLabel ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const buttonRef = useRef(null);
  return (
    <>
      <span>Security Code (Symantec VIP)</span>
      <a className="fe_u_margin--left-xsmall" ref={buttonRef} onClick={() => setIsOpen(true)}>
        <InfoButtonSmall />
      </a>
      <Popover
        isOpen={isOpen}
        heading="Security Code (Symantec VIP)"
        onClose={() => {
          setIsOpen(false);
        }}
        targetRef={buttonRef}
        placement="bottom"
        className="symantec-code-popover--content"
      >
        <div className="fe_u_font-size--small">
          <p>
            The security code is used to authenticate your login information. Each time you open VIP Access, you will
            see a new 6-digit security code.
          </p>
        </div>
      </Popover>
    </>
  );
}
