import { APP_TYPES } from '../../constants';
import logger from '../../../logger';
import BaseForgifyCustomizer from '../base-forge';
import athenaConfig from '../../config';
import { getAppConfigForApp } from '../../utils/app-config-helper';
import { createWrapper } from '../../utils/react-utils';
import TermsContainer from '../../components/primary-auth/TermsContainer';
import { isAthenaOneWebOrMobileClient, isAthenaOneWebClient, isAthenaOneWebSsoSessionTimedOut, isAthenaOneWebSessionTimedOut, getAthenaOneSsoReauthUrl } from '../../utils/app-utils';
import Header from '../../components/common/Header';
import ForgotPasswordLink from '../../components/primary-auth/ForgotPasswordLink';
import { getTAndCTextOnLoginDescription } from '../../utils/i18n-utils';
import Footer from '../../components/common/Footer';
import MarketingContent from '../../components/primary-auth/MarketingContent';
import { hideWidgetForm, closeOktaSession, isPrimaryAuthController } from '../../utils/widget-facade';
import { removeMarketingLayout, applyMarketingLayout, applyMarketingFailoverLayout } from '../../utils/layout-utils';
import ExtrasSection from '../../components/primary-auth/ExtrasSection';
import AmplitudeUtils from '../../utils/amplitude-utils';
import { getAthenaOneParamsFromJwt, getBannerConfig } from '../../utils/banner-jwt-utils';
import { AlertTypes } from '@athena/forge';
import { getCustomLoginMetadata } from '../../utils/authorize-utils';
import InfoContainer from '../../components/common/InfoContainer';

const { clientIdsForBanner, marketingContentUrl } = athenaConfig;

export default abstract class BasePrimaryAuthCustomizer extends BaseForgifyCustomizer {
  protected abstract rememberMeSelector: string;
  protected bannerConfig: any = {};
  displayHeader = !isAthenaOneWebOrMobileClient(this.clientId);
  displayFooterLink = false;
  headerFontSize = 'xlarge';
  inputFieldsCustomClass = 'athena-input-form-field';
  primaryButtonFullWidth = false;
  primaryButtonClassName = 'primary-auth-login-button';

  clearInfoBanner() {
    this.eventEmitter.emitEventToComponent({
      componentName: InfoContainer.displayName as string,
      eventName: 'set-info-banner',
      eventPayload: {},
    });
  }

  primaryButtonOnClick = async () => {
    const { athenaOneWebSsoSessionTimedOut, athenaOneParams } = this.controllerContext;
    if (athenaOneWebSsoSessionTimedOut && athenaOneParams?.SSOREAUTHPARAMS) {
      // Kill Okta session before starting SSO authentication as Okta is not able to update session
      //to newly authenticated if there is already a valid session
      await closeOktaSession();
      // start sso reauthentication with IDP
      window.open(getAthenaOneSsoReauthUrl(this.clientId, athenaOneParams));
      AmplitudeUtils.logCustomEvent('SsoReauthenticationInitiated', { });
    }
    else {
      this.clearInfoBanner();
    }
  };

  async shouldCustomize() {
    return isPrimaryAuthController(this.context?.controller);
  }

  private setBanner({ headerText, description, alertType }:
    { headerText: string; description: string; alertType?: AlertTypes }) {
    if (forgify) {
      this.eventEmitter.emitEventToComponent({
        componentName: InfoContainer.displayName as string,
        eventName: 'set-info-banner',
        eventPayload: {
          headerText,
          description,
          alertType,
        },
      });
    }
    else {
      // set-info-banner event doesnt trigger when app display name is set
      jQueryCourage(`
        <div className="okta-form-infobox infobox" role='info'>
          <h3>${headerText}</h3>
          <p>${description}</p>
        </div>`).appendTo('.o-form-info-container');
    }
  }

  private addBanner(bannerDetails: any) {
    if ([ 'primary-auth', 'idp-discovery' ].includes(this.context.controller)) {
      setTimeout(() => this.setBanner(bannerDetails));
    }
  }

  private getBannerDetails() {
    if (this.config?.language?.includes('es')) {
      return {
        headerText: '¿Tienes problemas para iniciar sesión?',
        description:
          'En este momento tenemos problemas técnicos y estamos trabajando para solucionarlos. Te pedimos disculpas por este inconveniente. Intenta iniciar sesión de nuevo más tarde.',
      };
    }
    return {
      headerText: 'Having trouble logging in?',
      description:
        "We're having technical problems right now, and we're working to fix them. We apologize for this inconvenience. Please try logging in again later.",
    };
  }

  private isDegradedStateBannerToBeShown() {
    return !!(this.clientId && clientIdsForBanner.includes(this.clientId));
  }

  private setDegradedStateBanner() {
    const { headerText, description } = this.getBannerDetails();
    if (forgify) {
      this.addBanner({ headerText, description });
    }
    else {
      if ([ 'primary-auth', 'idp-discovery' ].includes(this.context.controller)) {
        const degradedStateBanner = jQueryCourage('#athena-degraded-state-banner');
        if (!degradedStateBanner.length) {
          document.getElementsByClassName('auth-content')[0].insertAdjacentHTML(
            'afterbegin',
            `<div id="athena-degraded-state-banner" style="border-left: 10px solid #ffab00;background-color: #fff;box-shadow: 0 1px 8px 0 rgb(0 0 0 / 25%);position: relative;display: flex;">
              <div style="padding: 18px 0 16px 8px;">
                  <svg style="fill: #0466b4;width: 25px;height: 25px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
                    <g>
                      <g style="fill: rgba(0,0,0,0);stroke: none;">
                        <rect width="25" height="25"></rect>
                      </g>
                      <g>
                        <g>
                          <circle cx="12.5" cy="12.5" r="10.6" fill="#ffab00" />
                          <g style="fill: #333;">
                            <polygon points="13.2,13.9 13.8,7.4 11.2,7.4 11.8,13.9"></polygon>
                            <path d="M11.3,16.6c0,0.7,0.5,1.2,1.2,1.2s1.2-0.5,1.2-1.2c0-0.7-0.5-1.2-1.2-1.2 S11.3,16,11.3,16.6z"></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
              </div>
              <div style="flex: 1;padding-left: 16px;padding-top: 16px;padding-bottom: 16px;">
                <div>
                  <h2 style="margin-bottom: 8px;font-size: 18px;font-weight: 600;color: #333;">${headerText}</h2>
                  <div style="color: #5f5f5f;">${description}</div>
                </div>
            </div>`
          );
        }
      }
    }
  }

  private addDegradedStateBanner() {
    if (!this.isDegradedStateBannerToBeShown()) return;
    this.setDegradedStateBanner();
  }

  protected forgifyController() {
    super.forgifyController();
    this.addCopyrightText();
  }

  async populateControllerContext() {
    const athenaOneParams = await getAthenaOneParamsFromJwt();
    this.controllerContext = {
      athenaOneParams,
      athenaOneWebSessionTimedOut: isAthenaOneWebSessionTimedOut(athenaOneParams),
      athenaOneWebSsoSessionTimedOut: isAthenaOneWebSsoSessionTimedOut(athenaOneParams),
    };
    this.suppressOktaClick = !!this.controllerContext.athenaOneWebSsoSessionTimedOut;
  }

  private displayBannerBasedOnConfig() {
    const { athenaOneParams, athenaOneWebSsoSessionTimedOut } = this.controllerContext;
    const bannerConfig = getBannerConfig(athenaOneParams, athenaOneWebSsoSessionTimedOut);
    this.bannerConfig = bannerConfig;
    if (bannerConfig) {
      this.logInfoBannerAmplitudeEvents({
        ohswBannerKey: bannerConfig.bannerType,
        isSso: bannerConfig.isSso,
      });
      this.addBanner({
        headerText: bannerConfig.header,
        description: bannerConfig.description,
        alertType: bannerConfig.alertType,
      });
    }
  }


  async customize() {
    await this.populateControllerContext();
    await super.customize();
    this.displayBannerBasedOnConfig();
    AmplitudeUtils.logCustomEvent('PrimaryAuthLanding', getCustomLoginMetadata(this.bannerConfig));
    this.addAthenaOneWebCustomizations();
    this.addDegradedStateBanner();
    this.addTitleMessage();
    this.showTAndCText();
    if (forgify) {
      this.hideCheckBox();
      this.hideFormElementsForInvalidIDPError();
    }
  }

  protected hideCheckBox() {
    const rememberMeContainer: JQuery<HTMLElement> = jQueryCourage(this.rememberMeSelector);
    this.hideComponent(rememberMeContainer[0]);
  }

  private addMarketingContent() {
    applyMarketingLayout();
    const marketingWrapper = createWrapper({ hidden: false });
    const marketingContainer = jQueryCourage('#marketing-container');
    marketingContainer.append(marketingWrapper);
    this.renderReactComponent({
      id: 'marketing',
      container: marketingWrapper,
      className: 'marketing-content',
      component: <MarketingContent marketingContentUrl={marketingContentUrl} />,
    });
    this.eventEmitter.on('marketing-content-failed', () => {
      removeMarketingLayout();
      applyMarketingFailoverLayout();
    });
  }

  private addCopyrightText() {
    const authContentContainer = jQueryCourage('#okta-sign-in .auth-content');
    if (authContentContainer.length) {
      const copyrightText = createWrapper({ hidden: false });
      authContentContainer.after(copyrightText);
      const footerText = `© ${new Date().getFullYear()} athenahealth, Inc`;
      const component = <Footer text={footerText} marginTop="xsmall" textAlign="center" />;
      this.renderReactComponent({
        id: 'copyright',
        container: copyrightText,
        component,
      });
    }
  }

  private logInfoBannerAmplitudeEvents({ ohswBannerKey, isSso }: {ohswBannerKey: string; isSso?: boolean}) {
    if (ohswBannerKey === 'TIMEDOUT') {
      AmplitudeUtils.logCustomEvent('TimeoutLanding', { isSso });
    }
    else if (ohswBannerKey === 'LOGOUT') {
      AmplitudeUtils.logCustomEvent('LogoutLanding', { });
    }
    else if (ohswBannerKey === 'REFRESHTIMEOUT') {
      AmplitudeUtils.logCustomEvent('RefreshTimeoutLanding', { isSso });
    }
  }

  private addPrimaryAuthExtras() {
    const primaryAuthForm = jQueryCourage('form');
    const additionalLinksWrapper = createWrapper({ hidden: false });
    primaryAuthForm.append(additionalLinksWrapper);
    this.renderReactComponent({
      id: 'additional-links',
      container: additionalLinksWrapper,
      component: <ExtrasSection />,
    });
  }

  private addAthenaOneWebCustomizations() {
    if (!this.requestContext) return;
    if (!isAthenaOneWebClient(this.clientId)) return;
    if (marketingContentUrl) {
      this.addMarketingContent();
    }
    else {
      applyMarketingFailoverLayout();
    }
    this.addPrimaryAuthExtras();
    this.addForgotPasswordLink();
  }

  private addForgotPasswordLink() {
    const formContainer: JQuery<HTMLElement> = jQueryCourage('[data-se="o-form-content"]');
    const container = createWrapper({ hidden: false });
    formContainer.append(container);

    const component = <ForgotPasswordLink />;
    this.renderReactComponent({
      id: 'forgot-password-link',
      component,
      container,
    });
  }

  private getTitleMessage() {
    if (this.config?.language?.includes('es')) {
      return 'Inicia sesión para conectarte a';
    }
    return 'Log in to connect to';
  }

  private addTitleMessage() {
    const titleMessage = this.getTitleMessage();
    if (forgify) {
      if (!isAthenaOneWebOrMobileClient(this.clientId)) {
        const athenaHeaderContainer: JQuery<HTMLElement> = jQueryCourage('[data-se="o-form-head"]');
        const container = createWrapper({ hidden: false });
        athenaHeaderContainer.after(container);
        const component = <Header headerText={titleMessage} fontSize="small" fontColor="muted" fontWeight="regular" />;
        this.renderReactComponent({
          id: 'okta-form-titlemessage',
          component,
          container,
        });
      }
    }
    else {
      try {
        if (titleMessage) {
          document
            .getElementsByClassName('o-form-content')[0]
            .insertAdjacentHTML(
              'afterbegin',
              `<h3 data-se="o-form-head" class="okta-form-titlemessage o-form-head" style="font-size: 14px; font-weight: 200; text-align: center;">${titleMessage}</h3>`
            );
        }

        const appTitle = document.getElementsByClassName('okta-form-title o-form-head')[0] as HTMLElement;
        if (appTitle) {
          appTitle.style.lineHeight = '1';
        }
      }
      catch (e) {
        logger.log(e);
      }
    }
  }

  private showTAndCText() {
    const { requestContext, appConfigs } = this;
    if (!requestContext) return;
    const appConfig = getAppConfigForApp({ requestContext, appConfigs });
    if (appConfig.appType !== APP_TYPES.ATHENANET_USER) return;
    if (forgify) {
      const formContainer: JQuery<HTMLElement> = jQueryCourage('[data-se="o-form-content"]');
      const container = createWrapper({ hidden: false });
      formContainer.after(container);
      const component = <TermsContainer />;
      this.renderReactComponent({
        id: 'o-form-terms-container',
        component,
        container,
      });
    }
    else {
      jQueryCourage(
        `<div>
          <br/>
          <div class="o-form-terms-container" data-se="o-form-terms-container">${getTAndCTextOnLoginDescription(
    this.config?.language
  )}</div>
          <br/>
          <br/>
       </div>`
      ).appendTo('.o-form-content');
    }
  }

  private hideFormElementsForInvalidIDPError() {
    const { athenaOneParams } = this.controllerContext;
    if (athenaOneParams) {
      if (athenaOneParams.KEY === 'UNKNOWNIDENTITYPROVIDER' || athenaOneParams.KEY === 'INVALIDIDENTITYPROVIDER') {
        hideWidgetForm();
        AmplitudeUtils.logCustomEvent('InvalidIdentityProvider', { });
      }
    }
  }
}
