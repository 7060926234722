import {
  MultiselectChangeEvent,
  MultiselectOption,
  SingleSelect,
  SingleSelectProps,
  StructuredOption
} from '@athena/forge';
import WidgetFormField, { WidgetFormFieldProps } from './WidgetFormField';

export type DropDownListProps = Omit<
  WidgetFormFieldProps<Omit<SingleSelectProps, 'options'>> & {
    inputFieldContainer: JQuery;
    options?: ReadonlyArray<StructuredOption>;
    defaultValue?: StructuredOption;
  },
  'onChange' | 'labelText' | 'type' | 'onClick' | 'onBlur' | 'description' | 'id' | 'value' | 'inputAs'
>;
export default function DropDownList({
  inputFieldContainer,
  defaultValue,
  options,
  ...passthroughProps
}: DropDownListProps) {
  const dropDownList = inputFieldContainer.find('select');
  const label = inputFieldContainer.find('label');
  const finalOptions: ReadonlyArray<StructuredOption> =
    options ??
    inputFieldContainer
      .find('option')
      .map((_, element) => ({ value: element.value, label: element.text }))
      .toArray();
  const finalDefaultValue =
    finalOptions?.find((entry: StructuredOption) => dropDownList.val() === entry.value) ?? defaultValue;
  if (finalDefaultValue && dropDownList.val() !== finalDefaultValue.value) {
    //Set it back to Okta dropdown if the default value is different
    dropDownList.val(finalDefaultValue.value);
  }
  return (
    <WidgetFormField
      {...passthroughProps}
      id={`athena-${dropDownList.attr('name')}`}
      labelText={label.text()}
      defaultValue={[ finalDefaultValue as MultiselectOption ]}
      options={finalOptions}
      onChange={(event: MultiselectChangeEvent<false>) => {
        if (event.target.value) {
          dropDownList.val(event.target.value.value);
          dropDownList.trigger('change');
        }
      }}
      inputAs={SingleSelect}
    />
  );
}
