import React from 'react';

interface MutedTextProps {
  text: string;
  className?: string;
}

export default function MutedText({ className = '', text }: MutedTextProps) {
  return <p className={`${className} fe_u_color--muted`}>{text}</p>;
}
