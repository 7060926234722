/* eslint-disable max-len */
import BaseForgeCustomizer from '../base-forge';
import athenaConfig from '../../config';
import { CONTACT_ADMIN_TEXT } from '../../constants';
import TotpVerifySection from '../../components/mfa-verify/TotpVerifySection';
import ProxyButton from '../../components/common/ProxyButton';
import AmplitudeUtils from '../../utils/amplitude-utils';
import { toPascalCase } from '../../utils/string-utils';
import CheckBox from '../../components/common/CheckBox';

const { classic } = athenaConfig;

export default class MfaVerifyClassicCustomizer extends BaseForgeCustomizer {
  headerText = '';
  footerText = CONTACT_ADMIN_TEXT;
  footerTextAlign = 'left';

  async shouldCustomize() {
    return (
      this.context?.controller === 'mfa-verify' &&
      classic &&
      !!this.factorType &&
      [ 'sms', 'call', 'token', 'token:software:totp', 'token:hotp', 'push', 'password' ].includes(this.factorType)
    );
  }

  protected forgifyController() {
    super.forgifyController();
    this.hideTriggerCodeButton();
    this.forgifyBeaconContainer();
    this.forgifyAutoPushCheckbox();
    this.forgifyEnterCodeButton();
    this.replaceComponents();
    this.emitAmplitudeEvents();
  }

  private forgifyBeaconContainer() {
    const beaconContainer = jQueryCourage('.beacon-container')[0];
    const dropdown = jQueryCourage('.factors-dropdown-wrap[data-type="factor-types-dropdown"]');
    dropdown.addClass('fe_f_all');

    if (beaconContainer && !dropdown.length) {
      const observer = new MutationObserver(function () {
        // Forgify by just adding forge's root class to update the fonts,
        // needs MutationObserver as this element can appear after the controller is rendered
        jQueryCourage('.factors-dropdown-wrap[data-type="factor-types-dropdown"]').addClass('fe_f_all');
      });
      observer.observe(beaconContainer, { childList: true, subtree: true });
    }
  }

  private hideTriggerCodeButton() {
    // these buttons for Call and SMS verify are forgified by PasscodeVerifySection.tsx,
    // so it should simply be hidden here
    const selector = this.factorType === 'sms' ? 'a[data-se="sms-send-code"]' : 'a[data-se="make-call"]';
    const sendCodeButton: JQuery<HTMLElement> = jQueryCourage(selector);
    this.hideComponent(sendCodeButton[0]);
  }

  private hideOktaTotpVerifyButton() {
    const selector = jQueryCourage('.button.inline-totp-verify');
    const verifyButton: JQuery<HTMLElement> = jQueryCourage(selector);
    this.hideComponent(verifyButton[0]);
  }

  private hideEnterCodeButton() {
    const enterCodeLink: JQuery<HTMLElement> = jQueryCourage('#athena-totp-enter-code');
    this.hideComponent(enterCodeLink[0]);
  }

  private forgifyEnterCodeButton() {
    const enterCodeButton: JQuery<HTMLElement> = jQueryCourage('a[data-se="inline-totp-add"]');
    /* When a user enrolls in both OktaVerify Manual & Push factors, factorType will be set to `push` in prod.
       Where as in non-prod, it'll be set with either with `token:software:totp` or `push` depending upon oktaSignIn.afterRender callback.
    */
    if (this.provider === 'OKTA' && this.factorType === 'push') {
      const forgeComponent = <ProxyButton
        oktaButton={enterCodeButton}
        fullWidth variant="tertiary"
        onClick={() => {
          this.forgifyTotpVerifyForm();
          AmplitudeUtils.logMfaChallengeEvent(`OktaVerify::${toPascalCase(enterCodeButton.text())}ButtonClick`);
        }} />;
      if (enterCodeButton.length) {
        this.renderForgeAndHideOktaComponent({
          id: 'totp-enter-code',
          oktaComponent: enterCodeButton,
          forgeComponent: forgeComponent,
        });
      }
    }
  }

  private forgifyTotpVerifyForm = () => {
    /* Note: 'Or enter Code' button gets removed from DOM, ProxyButton component's mutation observer doesn't observe
     *       it's parent's childlist/subtree mutations, so we are hiding it manually.
    */
    this.hideEnterCodeButton();
    const totpVerifyform = jQueryCourage('form[data-se="factor-inline-totp"]');
    const observer = new MutationObserver((mutations, observer) => {
      this.hideOktaTotpVerifyButton();
      const oktaInputFieldContainer: JQuery<HTMLElement> = jQueryCourage('.auth-passcode');
      const forgeComponent = <TotpVerifySection inputFieldContainer={oktaInputFieldContainer} />;
      if (oktaInputFieldContainer.length) {
        this.renderForgeAndHideOktaComponent({
          id: 'enter-code-totp-form',
          oktaComponent: oktaInputFieldContainer,
          forgeComponent: forgeComponent,
        });
        /*
        Note: We must call `observer.disconnect();` once are done with forgification here. Otherwise,
              the newly added forgified element also gets observerd by this mutation observer, causes
              the callback to invoked infinitely.
        */
        observer.disconnect();
        this.replaceComponents();
      }
    });
    observer.observe(totpVerifyform[0], { childList: true, subtree: true });
  };

  private forgifyAutoPushCheckbox() {
    if (this.provider === 'OKTA' && this.factorType === 'push') {
      const oktaAutoPushCheckbox: JQuery<HTMLElement> = jQueryCourage('.custom-checkbox');
      const forgeAutoPushCheckbox = <CheckBox
        inputFieldContainer={oktaAutoPushCheckbox}
        doMoreOnChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
          if (event?.type === 'change') {
            if (!checked) {
              AmplitudeUtils.logMfaChallengeEvent('OktaVerify::AutoPush::Check');
            }
            else {
              AmplitudeUtils.logMfaChallengeEvent('OktaVerify::AutoPush::Uncheck');
            }
          }
        }}
      />;
      this.renderForgeAndHideOktaComponent({
        id: oktaAutoPushCheckbox.attr('class')!,
        oktaComponent: oktaAutoPushCheckbox,
        forgeComponent: forgeAutoPushCheckbox,
      });
    }
  }

  private emitAmplitudeEvents() {
    if (this.provider === 'GOOGLE') {
      AmplitudeUtils.logMfaChallengeEvent('Landing::GoogleAuthenticatorFactorSelect');
    }
    else if (this.provider === 'OKTA') {
      if (this.factorType === 'push') {
        AmplitudeUtils.logMfaChallengeEvent('Landing::OktaVerifyFactorSelect');
      }
      else if (this.factorType === 'sms') {
        AmplitudeUtils.logMfaChallengeEvent('Landing::SMSFactorSelect');
      }
      else if (this.factorType === 'call') {
        AmplitudeUtils.logMfaChallengeEvent('Landing::VoiceCallFactorSelect');
      }
      else {
        AmplitudeUtils.logMfaChallengeEvent(`Landing::Okta${toPascalCase(this.factorType)}FactorSelect`);
      }
    }
    else {
      AmplitudeUtils.logMfaChallengeEvent(`Landing::${toPascalCase(this.provider)}${toPascalCase(this.factorType)}FactorSelect`);
    }
  }

  protected primaryButtonOnClick = (buttonText: string) => {
    if (this.provider === 'GOOGLE') {
      AmplitudeUtils.logMfaChallengeEvent('GoogleAuthenticator::VerifyButtonClick');
    }
    else if (this.provider === 'OKTA') {
      if (this.factorType === 'push') {
        AmplitudeUtils.logMfaChallengeEvent('OktaVerify::SendPushButtonClick');
      }
      else if (this.factorType === 'sms') {
        AmplitudeUtils.logMfaChallengeEvent('SMS::VerifyButtonClick');
      }
      else if (this.factorType === 'call') {
        AmplitudeUtils.logMfaChallengeEvent('VoiceCall::VerifyButtonClick');
      }
    }
  };
}
