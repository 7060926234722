import { getClientId } from '../utils/widget-facade';
import { CustomizeControllerArgs } from './index';

export default abstract class BaseCustomizer {
  protected appConfigs: any;
  protected requestContext: any;
  protected context: any;
  protected config: any;
  protected oktaSignIn: any;
  protected clientId: string;
  constructor({ requestContext, context, appConfigs, config, oktaSignIn }: CustomizeControllerArgs) {
    this.requestContext = requestContext;
    this.context = context;
    this.appConfigs = appConfigs;
    this.config = config;
    this.oktaSignIn = oktaSignIn;
    this.clientId = getClientId(this.requestContext);
  }

  abstract shouldCustomize(): Promise<boolean>;

  abstract customize(): Promise<void>;

  async performCustomization() {
    const willCustomize = await this.shouldCustomize();
    if (willCustomize) {
      await this.customize();
    }
    return willCustomize;
  }
}
