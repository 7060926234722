import { useEffect, useState } from 'react';
import WidgetFormField, { WidgetFormFieldProps } from './WidgetFormField';
import { Checkbox } from '@athena/forge';
import { CheckboxComponentProps } from '@athena/forge/dist/Checkbox/Checkbox';

export type CheckBoxProps = Omit<
  WidgetFormFieldProps<CheckboxComponentProps> & {
    inputFieldContainer: JQuery;
  } & {
    doMoreOnChange?: any;
  },
  'onChange' | 'labelText' | 'type' | 'onClick' | 'onBlur' | 'description' | 'id' | 'value' | 'inputAs'
>;

export default function CheckBox({ inputFieldContainer, doMoreOnChange, ...passthroughProps }: CheckBoxProps) {
  const label = inputFieldContainer.find('label');
  const form = inputFieldContainer.closest('form');
  const input: JQuery<HTMLInputElement> = inputFieldContainer.find('input');
  const [ checked, setChecked ] = useState<boolean>(input.is(':checked'));

  useEffect(() => {
    form.on('reset', () => {
      setChecked(false);
    });
  }, [ form ]);

  return (
    <WidgetFormField
      {...passthroughProps}
      id={`athena-${input.attr('name')}-checkbox`}
      labelText={''}
      type={input.attr('type')}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
        if (doMoreOnChange && typeof doMoreOnChange === 'function') {
          doMoreOnChange(event, checked);
        }
      }}
      checked={checked}
      onClick={() => input.trigger('click')}
      onBlur={() => input.trigger('blur')}
      inputAs={Checkbox}
      description={label.text()}
    />
  );
}
