import InputFieldContainer, { InputFieldContainerProps } from '../../common/InputFieldContainer';
import { useMemo } from 'react';
import CallPhoneInput from '../CallPhoneInput';

export default function PhoneInputSectionOie(props: InputFieldContainerProps) {
  const callExtension = useMemo(() => {
    return jQueryCourage('.phone-authenticator-enroll__phone-ext');
  }, []);

  const getComponentToRender = (callExtension: JQuery) => {
    if (callExtension.length > 0 && !callExtension.hasClass('hide')) {
      return <CallPhoneInput inputContainerProps={props} callExtension={callExtension}></CallPhoneInput>;
    }
    else {
      return <InputFieldContainer {...props}></InputFieldContainer>;
    }
  };

  return <div>{getComponentToRender(callExtension)}</div>;
}
