import { createContext, useContext } from 'react';
import { AthenaOneParams } from '../../utils/banner-jwt-utils';
import { IEventBus } from '../../utils/event-bus';
export interface IWidgetContext {
  eventEmitter: IEventBus;
  language?: string;
  clientId?: string;
  controller?: string;
  provider?: string;
  factorType?: string;
  athenaOneParams?: AthenaOneParams;
  athenaOneWebSessionTimedOut?: boolean;
  athenaOneWebSsoSessionTimedOut?: boolean;
}

const WidgetContext = createContext<IWidgetContext | null>(null);

export const useWidgetContext = () => useContext(WidgetContext) as IWidgetContext;

export default WidgetContext;
