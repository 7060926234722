import { Label, useClickOutside } from '@athena/forge';
import React, { useMemo, useRef, useState } from 'react';
import SymantecCodeLabel from './SymantecCodeLabel';
import WidgetFormField from '../common/WidgetFormField';
import { useEventBus } from '../../hooks/useEventBus';
import withEventHelper from '../common/EventHelper';

function SymantecCode() {
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ isOpen, setIsOpen ] = useState(false);
  const [ value, setValue ] = useState('');
  const containerRef = useRef(null);

  //useMemo to ensure we are using the same function ref on re-renders
  const onUpdateError = useMemo(() => {
    return ({ errorMessage }: { errorMessage: string }) => {
      setErrorMessage(errorMessage);
    };
  }, []);

  const onClearValue = useMemo(() => {
    return () => {
      setValue('');
    };
  }, []);

  useEventBus({ eventName: 'update-error', onEvent: onUpdateError });
  useEventBus({ eventName: 'clear-value', onEvent: onClearValue });

  const closePopover = () => setIsOpen(false);

  useClickOutside(containerRef, closePopover);
  const labelContent = <SymantecCodeLabel isOpen={isOpen} setIsOpen={setIsOpen}></SymantecCodeLabel>;
  const label = (
    <Label htmlFor="credentials.securitycode" text={labelContent}></Label>
  );
  return (
    <div ref={containerRef}>
      <WidgetFormField
        id={'credentials.securitycode'}
        labelText={label}
        type="password"
        error={errorMessage}
        value={value}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setValue(event.target.value);
          if (value) {
            setErrorMessage('');
          }
        }}
        autoComplete="off"
      ></WidgetFormField>
      <p className="fe_u_margin--bottom-medium fe_u_font-size--small">
        If you have lost your credentialed device, contact your practice manager for assistance to set up your new device.
      </p>
    </div>
  );
}

SymantecCode.displayName = 'SymantecCode';

export default withEventHelper(SymantecCode);
