import athenaConfig from '../../config';
import { passwordResetClassic, shouldPromptForSymantecVIP } from '../../utils/anet-client';
import { getOktaWidgetModel, getStateToken, showFormErrors, stopSpinner } from '../../utils/widget-facade';
import PasswordResetBaseCustomizer, { PasswordRequirementsSelector } from './password-expired-base';
import { getAnetUsername } from '../../utils/user-utils';
import AmplitudeUtils from '../../utils/amplitude-utils';
import { setSecurityQuestionsPromptCookies } from '../../utils/storage-utils';
const { classic } = athenaConfig;

interface SymantecPromptPayloadClassic {
  username: string;
  stateToken: string;
}

export default class PasswordResetClassicCustomizer extends PasswordResetBaseCustomizer<SymantecPromptPayloadClassic> {
  constructor(args: any) {
    super(args);
    this.symantecFieldIndex = 1;
  }

  protected getSymantecCodeTarget(): JQuery<HTMLElement> {
    return jQueryCourage('#athena-oldPassword');
  }

  protected onPasswordReset() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    // Monkey patch the changePassword method in PasswordExpiredController to hit aNet APIs instead of Okta's
    // https://github.com/okta/okta-signin-widget/blob/7.8/src/v1/controllers/PasswordExpiredController.js#L36-L39
    const model = getOktaWidgetModel();
    const transaction = model.appState.get('transaction');
    transaction.changePassword = async function (payload: { newPassword: string; oldPassword: string }) {
      try {
        AmplitudeUtils.logPasswordResetEvent('PasswordChangeInitiated', {
          shouldPromptForSymantec: self.shouldPromptForSymantec,
        });
        const response = await self.triggerPasswordResetRequest({
          symantecSecurityCode: self.getSymantecSecurityCode(),
          ...payload,
        });
        AmplitudeUtils.logPasswordResetEvent('PasswordChangeSuccess', {
          shouldPromptForSymantec: self.shouldPromptForSymantec,
        });
        if (response.sqtoken) {
          setSecurityQuestionsPromptCookies({
            sqtoken: response.sqtoken,
            primaryAuthWithCustomTxn: true,
          });
        }
        return global.oktaSignIn.authClient.tx.createTransaction(response);
      }
      catch (err: any) {
        AmplitudeUtils.logPasswordResetEvent('PasswordChangeFailed', {
          errorMessage: err.errorSummary ?? err.message,
          shouldPromptForSymantec: self.shouldPromptForSymantec,
        });
        const { message } = err;
        if (self.isSymantecValidationError(message)) {
          self.clearPasswordResetForm();
        }
        else {
          if (self.shouldPromptForSymantec) {
            self.clearSymantecCode();
          }
        }
        throw err;
      }
    };
  }

  protected async triggerPasswordResetRequest({ newPassword, oldPassword, symantecSecurityCode }: any): Promise<any> {
    const symantecPromptPayload = await this.getSymantecPromptPayload();
    return passwordResetClassic({
      newPassword,
      oldPassword,
      symantecSecurityCode,
      ...symantecPromptPayload,
    });
  }

  async shouldCustomize() {
    return (await super.shouldCustomize()) && classic;
  }
  protected async getSymantecPromptPayload() {
    return {
      username: getAnetUsername(),
      stateToken: getStateToken(),
    };
  }

  protected async shouldPromptForSymantecVIP(payload: any) {
    return shouldPromptForSymantecVIP(payload);
  }

  protected handleSymantecPromptError(error: any) {
    const errorMessage = error.errorSummary || error.message;
    AmplitudeUtils.logPasswordResetEvent('SymantecVipPromptFailure', {
      errorMessage,
    });
    showFormErrors(error);
    stopSpinner();
  }

  protected getPasswordRequirementsSelector(): PasswordRequirementsSelector {
    return {
      headerSelector: '.password-requirements--header',
      itemListSelector: '.password-requirements--list',
      containerSelector: 'div[data-se="password-requirements-html"]',
    };
  }
}
