import ProxyButton from '../../components/common/ProxyButton';
import AppDownloadInstructions from '../../components/enroll-totp/AppDownloadInstructions';
import athenaConfig from '../../config';
import { TOTP_PROVIDER_AMPLITUDE_EVENT } from '../../constants';
import AmplitudeUtils from '../../utils/amplitude-utils';
import EnrollTotpBaseCustomizer from './base';

const { classic } = athenaConfig;

export default class EnrollTotpClassicCustomizer extends EnrollTotpBaseCustomizer {
  async shouldCustomize() {
    return (await super.shouldCustomize()) && classic && forgify;
  }

  async customize() {
    await super.customize();
    this.forgifyNextButton();
    this.forgifyAppDownloadInstructions();
    this.replaceComponents();
    if (this.provider) {
      AmplitudeUtils.logMfaEnrollEvent(`${TOTP_PROVIDER_AMPLITUDE_EVENT[this.provider]}TotpEnroll::Landing`);
    }
  }

  private forgifyNextButton() {
    const oktaNextButton: JQuery<HTMLElement> = jQueryCourage('.link-button');
    const buttonText = oktaNextButton[0].innerText;
    if (oktaNextButton.length) {
      const forgeComponent = <ProxyButton
        oktaButton={oktaNextButton}
        variant="primary"
        fullWidth size='large'
        onClick={() => {
          AmplitudeUtils.logMfaEnrollEvent(`${TOTP_PROVIDER_AMPLITUDE_EVENT[this.provider]}TotpEnroll::${buttonText}ButtonClick`);
        }}
      />;
      this.renderForgeAndHideOktaComponent({
        id: oktaNextButton.attr('class')!,
        oktaComponent: oktaNextButton,
        forgeComponent: forgeComponent,
      });
    }
  }

  private forgifyAppDownloadInstructions() {
    const oktaAppDownloadInstructions: JQuery<HTMLElement> = jQueryCourage('.app-download-instructions');
    if (oktaAppDownloadInstructions.length) {
      const forgeComponent = <AppDownloadInstructions container={oktaAppDownloadInstructions} />;
      this.renderForgeAndHideOktaComponent({
        id: oktaAppDownloadInstructions.attr('class')!,
        oktaComponent: oktaAppDownloadInstructions,
        forgeComponent: forgeComponent,
      });
    }
  }
}
