import React, { ErrorInfo } from 'react';
import AmplitudeUtils from '../../utils/amplitude-utils';

interface ErrorProps {
  children: React.ReactNode;
}

interface ErrorState {
  hasError: boolean;
}

export default class ErrorBoundary extends React.Component<ErrorProps, ErrorState> {
  state = { hasError: false };
  async componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    AmplitudeUtils.logForgeError({ ...error, ...errorInfo });
  }

  static getDerivedStateFromError(): ErrorState {
    return { hasError: true };
  }

  render() {
    if (!this.state.hasError) return this.props.children;
    return <></>;
  }
}
