import OrderedList from '../../../components/common/OrderedList';
import ProxyButton from '../../../components/common/ProxyButton';
import EnrollPollBaseCustomizer from './base';

export default class EnrollPollQrCodeCustomizer extends EnrollPollBaseCustomizer {
  async shouldCustomize() {
    return (await super.shouldCustomize()) && this.selectedChannel === 'qrcode';
  }

  async customize() {
    await super.customize();
    this.forgifyQrCodeInstructions();
    this.forgifyCantScanLink();
    this.replaceComponents();
  }

  private forgifyQrCodeInstructions() {
    const qrCodeInstructions: JQuery<HTMLElement> = jQueryCourage('.qrcode-info');
    if (qrCodeInstructions.length) {
      this.renderForgeAndHideOktaComponent({
        id: 'qr-code-instructions',
        oktaComponent: qrCodeInstructions,
        forgeComponent: <OrderedList oktaComponent={qrCodeInstructions} />,
      });
    }
  }

  private forgifyCantScanLink() {
    const cantScanLink: JQuery<HTMLElement> = jQueryCourage('.switch-channel-link');
    if (cantScanLink.length) {
      const forgeComponent = <ProxyButton oktaButton={cantScanLink} fullWidth variant='tertiary' />;
      this.renderForgeAndHideOktaComponent({
        id: 'cant-scan-link',
        oktaComponent: cantScanLink,
        forgeComponent: forgeComponent,
      });
    }
  }
}
