import Cookies from 'js-cookie';
import { withErrorConsumed } from './common-utils';
import athenaConfig from '../config';

const ATHENA_AUTHORIZE_COOKIE_KEY = 'athena-authorizeUrl';
const OHSW_AUTHORIZE_URL = 'athena-ohsw-authorizeUrl';
const STATE_TOKEN_OVERRIDE = 'state-token-override';
export const LOGIN_VIA_OHSW = 'LOGIN_VIA_OHSW';
const OHSW_SQTOKEN_KEY = 'OHSW_SQTOKEN';
const OHSW_PRIMARY_AUTH_PLUS_CUSTOM_OKTA_TXN_KEY = 'OHSW_PRIMARY_AUTH_PLUS_CUSTOM_OKTA_TXN';
const ATHENAHEALTH_COOKIE_DOMAIN = '.athenahealth.com';
const SMS_MANUAL_SETUP_PHONE_CODE = 'athena-sms-manual-setup-phone-code';
const STATE_TOKEN_EXPIRY_BANNER_TYPE = 'state-token-expiry-banner-type';
const LAST_USERNAME_OKTA_COOKIE = 'ln';

const { env } = athenaConfig;

/**
 * The storage interactions are wrapped in "ErrorConsumed" function, so it becomes no-op when storage is unavailable
 * (e.g. when cookies are disabled)
 */

export const getAuthorizeUrl = withErrorConsumed(function () {
  //This cookie is expected to be set by LWA SPA
  return Cookies.get(ATHENA_AUTHORIZE_COOKIE_KEY) as string;
});
export const setOhswAuthorizeUrl = withErrorConsumed((url: string) => {
  sessionStorage.setItem(OHSW_AUTHORIZE_URL, url);
});

export const getOhswAuthorizeUrl = withErrorConsumed(() => {
  return sessionStorage.getItem(OHSW_AUTHORIZE_URL);
});

export const removeOhswAuthorizeUrl = withErrorConsumed(() => {
  return sessionStorage.removeItem(OHSW_AUTHORIZE_URL);
});

export const getOhswBannerKey = withErrorConsumed(() => {
  const urlParams = new URLSearchParams(window.location.search);
  const showBanner = urlParams.get('SHOWBANNER');
  const bannerTime = urlParams.get('BANNERTIME');
  const currentTime = Math.round(new Date().getTime() / 1000);
  if (bannerTime && showBanner && currentTime - parseInt(bannerTime) < 300) {
    return showBanner;
  }
});

export interface SqPromptCookiesArgsData {
  sqtoken: string;
  primaryAuthWithCustomTxn?: boolean;
}

export const setSecurityQuestionsPromptCookies = withErrorConsumed((sqPromptCookiesData: SqPromptCookiesArgsData) => {
  const options = {
    domain: ATHENAHEALTH_COOKIE_DOMAIN,
    path: '/',
  };
  Cookies.set(OHSW_SQTOKEN_KEY, sqPromptCookiesData.sqtoken, options);
  if (sqPromptCookiesData.primaryAuthWithCustomTxn) {
    Cookies.set(
      OHSW_PRIMARY_AUTH_PLUS_CUSTOM_OKTA_TXN_KEY,
      sqPromptCookiesData.primaryAuthWithCustomTxn ? 'Y' : 'N',
      options
    );
  }
});

export const getLoadLocalScriptsFlag = () => {
  return env !== 'prod' && Cookies.get('LOAD_LOCAL_SCRIPTS_ONLY_IN_NON_PROD') === 'true';
};

export const getLocalBaseUrl = () => {
  return Cookies.get('LOCAL_SCRIPTS_BASE_URL') || 'http://localhost:50050/static/js/';
};

export const clearSecurityQuestionsPromptCookies = withErrorConsumed(() => {
  const options = {
    domain: ATHENAHEALTH_COOKIE_DOMAIN,
    path: '/',
  };
  Cookies.remove(OHSW_SQTOKEN_KEY, options);
  Cookies.remove(OHSW_PRIMARY_AUTH_PLUS_CUSTOM_OKTA_TXN_KEY, options);
});

export const isSecurityQuestionsTokenCookieSet = withErrorConsumed(() => {
  return !!(Cookies.get(OHSW_SQTOKEN_KEY));
});

export const isLoginViaOhswCookieSet = withErrorConsumed(function () {
  return Cookies.get(LOGIN_VIA_OHSW) === 'ON';
});

export const getShowBannerValue = withErrorConsumed(() => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('SHOWBANNER') || '';
});

export const getStateTokenOverride = withErrorConsumed(() => {
  return sessionStorage.getItem(STATE_TOKEN_OVERRIDE);
});

export const setStateTokenOverride = withErrorConsumed((stateToken: string) => {
  return sessionStorage.setItem(STATE_TOKEN_OVERRIDE, stateToken);
});

export const removeStateTokenOverride = withErrorConsumed(() => {
  return sessionStorage.removeItem(STATE_TOKEN_OVERRIDE);
});

export const setSmsManualSetupPhoneCode = withErrorConsumed((phoneNumber: string) => {
  return sessionStorage.setItem(SMS_MANUAL_SETUP_PHONE_CODE, phoneNumber);
});

export const getSmsManualSetupPhoneCode = withErrorConsumed(() => {
  return sessionStorage.getItem(SMS_MANUAL_SETUP_PHONE_CODE);
});

export const removeSmsManualSetupPhoneCode = withErrorConsumed(() => {
  return sessionStorage.removeItem(SMS_MANUAL_SETUP_PHONE_CODE);
});

export const setStateTokenExpiryBannerType = withErrorConsumed((bannerType: string) => {
  return sessionStorage.setItem(STATE_TOKEN_EXPIRY_BANNER_TYPE, bannerType);
});

export const getStateTokenExpiryBannerType =  withErrorConsumed(() => {
  return sessionStorage.getItem(STATE_TOKEN_EXPIRY_BANNER_TYPE);
});

export const removeStateTokenExpiryBannerType =  withErrorConsumed(() => {
  return sessionStorage.removeItem(STATE_TOKEN_EXPIRY_BANNER_TYPE);
});

export const getLastUsernameOktaCookie = withErrorConsumed(() => {
  return Cookies.get(LAST_USERNAME_OKTA_COOKIE);
});

export const clearStorage = withErrorConsumed(() => {
  Cookies.remove(ATHENA_AUTHORIZE_COOKIE_KEY);
  removeSmsManualSetupPhoneCode();
});

export const removeLastUsernameOktaCookie = withErrorConsumed(() => {
  Cookies.remove(LAST_USERNAME_OKTA_COOKIE);
});
