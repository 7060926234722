import SmsText from '../../components/enroll-activation-link-set/SmsText';
import athenaConfig from '../../config';
import EnrollActivationLinkSentBaseCustomizer from './base';
const { classic } = athenaConfig;

export default class EnrollActivationLinkSentClassicCustomizer extends EnrollActivationLinkSentBaseCustomizer {
  async shouldCustomize() {
    return (await super.shouldCustomize()) && classic && forgify;
  }

  async customize() {
    await super.customize();
    this.forgifyPhoneNumberText();
    this.replaceComponents();
  }

  private forgifyPhoneNumberText() {
    const phoneNumberElement = jQueryCourage('.phone-number');
    if (phoneNumberElement.length) {
      const parentContainer = phoneNumberElement.closest('div');
      const forgeComponent = <SmsText container={parentContainer} />;
      this.renderForgeAndHideOktaComponent({
        id: 'sms-text',
        oktaComponent: parentContainer,
        forgeComponent: forgeComponent,
      });
    }
  }
}
