import athenaConfig from '../../config';
import FactorsList from '../../components/enroll-factors/FactorsList';
import BaseForgeCustomizer from '../base-forge';

const { oieEnabled } = athenaConfig;
export default class OieFactorChallengeCustomizer extends BaseForgeCustomizer {
  async shouldCustomize() {
    return this.context.formName === 'select-authenticator-authenticate' && oieEnabled;
  }

  protected forgifyController() {
    super.forgifyController();
    this.forgifyFactors();
  }

  headerDescription = jQueryCourage('.o-form-explain').text();

  forgifyFactors() {
    const factors = jQueryCourage('.authenticator-verify-list');
    const forgeComponent = <FactorsList challenge oktaFactorsList={factors} listItemsSelector=".authenticator-row" />;
    this.renderForgeAndHideOktaComponent({ id: 'authenticator-verify-list', oktaComponent: factors, forgeComponent });
  }
}
