import { ERROR_MESSAGES, ERROR_KEYS, PROVIDER_FACTOR_TYPE_APP_MAPPING } from '../constants';
import { getSelectedFactorType, getSelectedProvider } from './widget-facade';

export function getTransformedError({ response }: { response: any }) {
  if (response.errorSummary) {
    return ERROR_MESSAGES[response.errorSummary] || response.errorSummary;
  }
  return ERROR_MESSAGES[ERROR_KEYS.GENERIC_INTERNAL_ERROR];
}

export function getTAndCTextOnLoginDescription(language: string | undefined) {
  if (language?.includes('es')) {
    return 'Tu acceso y uso de las aplicaciones con esta cuenta está sujeto a las condiciones de uso y a la política de privacidad aplicables y que se encuentran en dichas aplicaciones.';
  }
  return 'Your access and use of applications with this account is subject to the Terms of Use and Privacy Policy applicable to and found within those applications.';
}

export function getFactorApplicationName() {
  const provider = getSelectedProvider();
  const factorType = getSelectedFactorType();
  const providerFactorTypes = PROVIDER_FACTOR_TYPE_APP_MAPPING[provider];
  if (providerFactorTypes) {
    return providerFactorTypes[factorType];
  }
}
