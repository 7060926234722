import WidgetContext, { IWidgetContext } from './WidgetContext';
interface WidgetContextProviderProps extends IWidgetContext {
  children: React.ReactNode;
}

export default function WidgetContextProvider({ children, ...passThroughProps }: WidgetContextProviderProps) {
  return (
    <WidgetContext.Provider
      value={passThroughProps}
    >
      {children}
    </WidgetContext.Provider>
  );
}
