import AmplitudeUtils from '../../utils/amplitude-utils';
import {
  getOktaUserId,
  getOktaWidgetModel,
  startSpinnerAndHideForm,
  stopSpinnerAndShowForm
} from '../../utils/widget-facade';
import { clearField, getSymantecField, setError } from '../../components/symantec';
import { isAnetUser } from '../../utils/user-utils';
import { ERROR_MESSAGES } from '../../constants';
import SymantecCode from '../../components/password-reset/SymantecCode';
import PasswordRequirements from '../../components/password-reset/PasswordRequirements';
import BaseForgeCustomizer from '../base-forge';
import { createWrapper } from '../../utils/react-utils';
import Header from '../../components/common/Header';
export interface PasswordRequirementsSelector {
  headerSelector: string;
  itemListSelector: string;
  containerSelector: string;
}

export default abstract class PasswordResetBaseCustomizer<T> extends BaseForgeCustomizer {
  protected shouldPromptForSymantec: boolean = false;
  protected symantecFieldIndex = 0;

  async shouldCustomize() {
    return (
      (this.context.formName === 'reenroll-authenticator-warning' || this.context.controller === 'password-expired') &&
      isAnetUser()
    );
  }

  protected getSymantecSecurityCode() {
    if (this.shouldPromptForSymantec) {
      return (document.getElementsByName('credentials.securitycode')[0] as HTMLInputElement).value;
    }
  }

  protected isSecurityCodeValid() {
    const { shouldPromptForSymantec } = this;
    const emptyFieldErrorMessage = ERROR_MESSAGES['error.field.empty'];
    if (shouldPromptForSymantec && !this.getSymantecSecurityCode()) {
      //This is our custom field and thus, a special case, and needs raising the error separately
      this.setErrorForSymantecField({ errorMessage: emptyFieldErrorMessage });
      return false;
    }
    return true;
  }

  protected customizeForEpcs() {
    const $ = global.jQueryCourage;
    const headerDescription =
      'Two-factor authentication is required to change your password or manage your credentialed devices.';
    if (forgify) {
      //Defer the execution to next event loop so that react components finish mounting in the current event loop
      //and thus ready for event subscription
      setTimeout(() => {
        this.eventEmitter.emitEventToComponent({
          componentName: Header.displayName as string,
          eventName: 'update-header-description',
          eventPayload: { headerDescription },
        });
        const container = createWrapper({ hidden: false });
        this.getSymantecCodeTarget().after(container);
        const component = <SymantecCode />;
        this.renderReactComponent({ id: 'symantec-token', component, container });
      });
    }
    else {
      $('.okta-form-title').after(`<p style="text-align: center; margin-bottom: 20px">${headerDescription}</p>`);
      $('.o-form-fieldset-container').children()
        .eq(this.symantecFieldIndex)
        .after(getSymantecField());
    }
  }

  protected forgifyController() {
    super.forgifyController();
    this.forgifyPasswordRequirements();
  }

  async customize() {
    AmplitudeUtils.logPasswordResetEvent('Landing', {});
    try {
      await super.customize();
      startSpinnerAndHideForm();
      const checkPayload = await this.getSymantecPromptPayload();
      this.shouldPromptForSymantec = await this.shouldPromptForSymantecVIP(checkPayload);
      if (this.shouldPromptForSymantec) {
        this.customizeForEpcs();
        AmplitudeUtils.logPasswordResetEvent('SymantecVipPrompted', {
          userId: getOktaUserId(),
        });
      }
      this.customizePasswordResetButton();
      stopSpinnerAndShowForm();
    }
    catch (error: any) {
      await this.handleSymantecPromptError(error);
    }
  }

  protected customizePasswordResetButton() {
    // Monkey patch model's validate method and add on the Symantec code's validation.
    // This lets us keep using Okta's validations out of the box for rest of the fields
    // For classic - https://github.com/okta/okta-signin-widget/blob/7.8/src/v1/controllers/PasswordExpiredController.js#L31
    // For OIE - https://github.com/okta/okta-signin-widget/blob/7.8/src/v2/view-builder/views/password/EnrollAuthenticatorPasswordView.js#L86
    const model = getOktaWidgetModel();
    const originalValidate = model.validate.bind(model);
    model.validate = () => {
      const response = originalValidate();
      const symantecCodeValid = this.isSecurityCodeValid();
      if (response) {
        return response;
      }
      else {
        // return a hash when symantec token is not valid so widget set the top error banner
        return symantecCodeValid ? undefined : { 'symantec-token': 'error' };
      }
    };
    this.onPasswordReset();
  }

  protected clearPasswordResetForm() {
    (document.getElementsByClassName('o-form o-form-edit-mode')[0] as HTMLFormElement).reset();
    if (forgify) {
      this.clearSymantecCode();
    }
  }

  protected isSymantecValidationError(errorMessage: string) {
    return errorMessage?.startsWith('symantec.');
  }

  protected clearSymantecCode() {
    if (forgify) {
      this.eventEmitter.emitEventToComponent({
        componentName: SymantecCode.displayName as string,
        eventName: 'clear-value',
      });
    }
    else {
      clearField();
    }
  }

  private forgifyPasswordRequirements() {
    const { headerSelector, itemListSelector, containerSelector } = this.getPasswordRequirementsSelector();
    const passwordRequirements: JQuery = jQueryCourage(containerSelector);
    const component = (
      <PasswordRequirements
        passwordRequirements={passwordRequirements}
        headerSelector={headerSelector}
        itemListSelector={itemListSelector}
      />
    );

    this.renderForgeAndHideOktaComponent({
      id: passwordRequirements.attr('data-se')!,
      forgeComponent: component,
      oktaComponent: passwordRequirements,
    });
  }

  protected abstract shouldPromptForSymantecVIP(payload: T): Promise<boolean>;

  protected abstract onPasswordReset(): void;

  protected abstract getSymantecPromptPayload(): Promise<T>;

  protected abstract handleSymantecPromptError(error: any): any;

  protected abstract triggerPasswordResetRequest(formData?: any): Promise<any>;

  protected abstract getPasswordRequirementsSelector(): PasswordRequirementsSelector;

  protected abstract getSymantecCodeTarget(): JQuery;

  private setErrorForSymantecField({ errorMessage }: { errorMessage: string }) {
    if (forgify) {
      this.eventEmitter.emitEventToComponent({
        componentName: SymantecCode.displayName as string,
        eventName: 'update-error',
        eventPayload: { errorMessage },
      });
    }
    else {
      errorMessage && setError(errorMessage);
    }
  }
}
