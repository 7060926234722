import { getLoadLocalScriptsFlag, getLocalBaseUrl } from './lib/utils/storage-utils';

export function loadLocalScripts() {
  const localUrl = new URL(getLocalBaseUrl());
  [ 'bundle.js', 'src_aosw_scss.chunk.js' ].forEach((script) => {
    const id = script.split('.')[0];
    if (jQueryCourage(`script#${id}`).length === 0) {
      const scriptElement = document.createElement('script');
      scriptElement.type = 'text/javascript';
      scriptElement.src = localUrl.href + script;
      scriptElement.id = id;
      jQueryCourage('body').append(scriptElement);
    }
  });
}

export function shouldLoadLocalScripts() {
  const loadLocalScriptsFlag = getLoadLocalScriptsFlag();
  if (loadLocalScriptsFlag) {
    const javascript = document?.currentScript as HTMLScriptElement;
    const localUrl = new URL(getLocalBaseUrl());
    return new URL(javascript.src).origin !== localUrl.origin;
  }
  else {
    return false;
  }
}
