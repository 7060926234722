export function applyMarketingLayout() {
  jQueryCourage('#layout').addClass('marketing-layout');
  jQueryCourage('#inner-container').addClass('rounded-border__radius-large');
  jQueryCourage('#okta-sign-in').removeClass('rounded-border');
}

export function removeMarketingLayout() {
  jQueryCourage('#layout').removeClass('marketing-layout');
  jQueryCourage('#inner-container').removeClass('rounded-border__radius-large');
  jQueryCourage('#okta-sign-in').addClass('rounded-border');
}

export function applyMarketingFailoverLayout() {
  jQueryCourage('#layout').addClass('marketing-failover');
}

export function removeMarketingFailoverLayout() {
  jQueryCourage('#layout').removeClass('marketing-failover');
}
