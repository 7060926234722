import { useMemo, useState } from 'react';
import { Button, ButtonProps } from '@athena/forge';
import { useMutationObserver } from '../../hooks/useMutationObserver';

export interface ProxyButtonProps extends ButtonProps<false> {
  oktaButton: JQuery;
  textToOverride?: string;
  onClick?: any;
  alwaysShow?: boolean;
  suppressOktaClick?: boolean;
}

function calcStateFromButton(button: HTMLButtonElement | undefined) {
  if (button) {
    return {
      disabled: jQueryCourage(button).prop('disabled'),
      hidden: button.style.display === 'none',
    };
  }
  return {
    disabled: false,
    hidden: true,
  };
}

export default function ProxyButton({
  oktaButton,
  size,
  variant,
  textToOverride,
  onClick = () => null,
  disabled,
  alwaysShow,
  suppressOktaClick,
  ...passthroughProps
}: ProxyButtonProps) {

  const [ buttonState, setButtonState ] = useState(calcStateFromButton(oktaButton.get(0) as HTMLButtonElement));

  const mutationObserverArgs = useMemo(() => {
    return {
      filterOptions: { attributeFilter: [ 'disabled', 'style' ] },
      onMutation: (mutation: MutationRecord) => {
        const sourceButton = mutation.target as HTMLButtonElement;
        setButtonState(calcStateFromButton(sourceButton));
      },
    };
  }, []);

  useMutationObserver({
    element: oktaButton[0],
    ...mutationObserverArgs,
  });

  if ((alwaysShow || !buttonState.hidden) && oktaButton.length) {
    return (
      <Button
        {...passthroughProps}
        disabled={disabled || buttonState.disabled}
        text={`${textToOverride || oktaButton.text() || oktaButton.val()}`}
        variant={variant}
        onClick={ async () => {
          await onClick();
          if (!suppressOktaClick) {
            oktaButton.trigger('click');
          }
        }}
        size={size}
      />
    );
  }
  else {
    return <></>;
  }
}
