interface BaseConfig {
  portalClientId: string;
  clientIdsForBanner: string[];
  athenaOneWebClientIds: string[];
  athenaOneMobileClientIds: string[];
  amplitudeApiKey: string;
  manageProfileUrl: string;
  athenaNetPasswordResetUrl: string;
  defaultAthenaOneLoginUrl: string;
  defaultAthenaOneHost: string;
  athenaOneChangeExpiredPasswordUrl: string;
  athenaOneOieChangeExpiredPasswordUrl: string;
  athenaOneSymantecPromptUrl: string;
  getSecurityQuestionsTokenUrl: string;
  oieEnabled: boolean;
  forgeEnabled: boolean;
  marketingContentUrl?: URL;
  bannerJwtAudience : string;
  env: 'dev' | 'int' | 'prod';
}

interface AthenaEnvConfig extends BaseConfig {
  classic: boolean;
  athenaNetPublickKeyUrl: URL;
}

const devAthenaOneOrigin = process.env.REACT_APP_ATHENAONE_ORIGIN || 'https://dev11718.athenahealth.com:51120';

const localConfig: BaseConfig = {
  portalClientId: process.env.REACT_APP_PORTAL_CLIENTID || '0oa5are7tf2rxpSAS1t7',
  clientIdsForBanner: process.env.REACT_APP_CLIENTIDS_FOR_BANNER ||
    [
      '0oa5are7tf2rxpSAS1t7', // Communicator Patient Portal
    ],
  athenaOneWebClientIds: process.env.REACT_APP_ATHENAONE_WEB_CLIENTIDS || [
    '0oa554hzbhSbGogvt1t7', // athenaNet
  ],
  athenaOneMobileClientIds: process.env.REACT_APP_ATHENAONE_MOBILE_CLIENTIDS || [
    '0oah2qpbo0AnEFxuW1t7', // athenaOne Mobile - development
    '0oaon0ul3vr8dF1Nn1t7', // PKCE athenaOne Mobile - development
    '0oatgusivxQkk6Hrr1t7', // Test AthenaOne Mobile - development
  ],
  amplitudeApiKey: '20dc2a80bf07774d9fc999f614e9ea5e',
  manageProfileUrl: process.env.REACT_APP_MANAGE_PROFILE_URL || 'https://myidentity-dev.aws.athenahealth.com/profile/manage',
  athenaNetPasswordResetUrl: `${devAthenaOneOrigin}/1/1/resetpassword.esp`,
  defaultAthenaOneLoginUrl: `${devAthenaOneOrigin}/login.esp`,
  athenaOneChangeExpiredPasswordUrl: `${devAthenaOneOrigin}/1/1/login/changeoktaexpiredpassword.esp`,
  athenaOneOieChangeExpiredPasswordUrl: `${devAthenaOneOrigin}/1/1/login/changeoktaoieexpiredpassword.esp`,
  athenaOneSymantecPromptUrl: `${devAthenaOneOrigin}/1/1/login/oktasymantecvipprompt.esp`,
  getSecurityQuestionsTokenUrl: `${devAthenaOneOrigin}/1/1/login/getsqtoken.esp`,
  oieEnabled: process.env.REACT_APP_OIE_ENABLED === 'true' ? true : false,
  forgeEnabled: true,
  marketingContentUrl: new URL('https://anet.aws.caas.athenahealth.com/'),
  env: process.env.REACT_APP_ENV || 'dev',
  bannerJwtAudience: process.env.REACT_APP_BANNER_JWT_AUD || 'https://identity.dev.athenahealth.com',
  defaultAthenaOneHost: devAthenaOneOrigin.split('https://')[1],
};

const devConfig: BaseConfig = {
  portalClientId: '0oa5are7tf2rxpSAS1t7',
  clientIdsForBanner: [
    '0oa5are7tf2rxpSAS1t7', // Communicator Patient Portal
  ],
  athenaOneWebClientIds: [
    '0oa554hzbhSbGogvt1t7', // athenaNet
  ],
  athenaOneMobileClientIds: [
    '0oah2qpbo0AnEFxuW1t7', // athenaOne Mobile - development
    '0oaon0ul3vr8dF1Nn1t7', // PKCE athenaOne Mobile - development
    '0oatgusivxQkk6Hrr1t7', // Test AthenaOne Mobile - development
    '0oaus43bkaNKUaNGc1t7', // athenaOne android app - development
  ],
  amplitudeApiKey: '20dc2a80bf07774d9fc999f614e9ea5e',
  manageProfileUrl: 'https://myidentity-dev.aws.athenahealth.com/profile/manage',
  athenaNetPasswordResetUrl: 'https://dev11718.athenahealth.com:51120/1/1/resetpassword.esp',
  defaultAthenaOneLoginUrl: 'https://dev11718.athenahealth.com:51120/login.esp',
  athenaOneChangeExpiredPasswordUrl: 'https://dev11718.athenahealth.com:51120/1/1/login/changeoktaexpiredpassword.esp',
  athenaOneOieChangeExpiredPasswordUrl:
    'https://dev11718.athenahealth.com:51120/1/1/login/changeoktaoieexpiredpassword.esp',
  athenaOneSymantecPromptUrl: 'https://dev11718.athenahealth.com:51120/1/1/login/oktasymantecvipprompt.esp',
  getSecurityQuestionsTokenUrl: 'https://dev11718.athenahealth.com:51120/1/1/login/getsqtoken.esp',
  oieEnabled: true,
  forgeEnabled: true,
  marketingContentUrl: new URL(
    'https://cdn.nimbus.athena.io/static/login-branding-static-content/1.0.2/marketing.html'
  ),
  env: 'dev',
  bannerJwtAudience: 'https://identity.dev.athenahealth.com',
  defaultAthenaOneHost: 'dev11718.athenahealth.com:51120',
};

const intConfig: BaseConfig = {
  portalClientId: '0oaduemiyvRIywGST0h7',
  clientIdsForBanner: [
    '0oaduemiyvRIywGST0h7', // Communicator Patient Portal
  ],
  athenaOneWebClientIds: [
    '0oac0y9mixYkeU8oH0h7', // athenaNet
  ],
  athenaOneMobileClientIds: [
    '0oawn8pq59Qt3XLTY0h7', // athenaOne Mobile testing - integration
    '0oa1b2ojxadvDorFh0h8', // PKCE athenaOne Mobile - integration
    '0oa1pgg7ke8hKqSbU0h8', // Test AthenaOne Mobile - integration
    '0oa1tkq2r6xkA6NMY0h8', // athenaOne android app - integration
  ],
  amplitudeApiKey: '20dc2a80bf07774d9fc999f614e9ea5e',
  manageProfileUrl: 'https://myidentity-int.aws.athenahealth.com/profile/manage',
  athenaNetPasswordResetUrl: 'https://devmain-mirror.athenahealth.com/1/1/resetpassword.esp',
  defaultAthenaOneLoginUrl: 'https://devmain-mirror.athenahealth.com/login.esp',
  athenaOneChangeExpiredPasswordUrl: 'https://devmain-mirror.athenahealth.com/1/1/login/changeoktaexpiredpassword.esp',
  athenaOneOieChangeExpiredPasswordUrl:
    'https://devmain-mirror.athenahealth.com/1/1/login/changeoktaoieexpiredpassword.esp',
  athenaOneSymantecPromptUrl: 'https://devmain-mirror.athenahealth.com/1/1/login/oktasymantecvipprompt.esp',
  getSecurityQuestionsTokenUrl: 'https://devmain-mirror.athenahealth.com/1/1/login/getsqtoken.esp',
  oieEnabled: false,
  forgeEnabled: true,
  marketingContentUrl: new URL('https://anetdev.aws.caas.athenahealth.com'),
  env: 'int',
  bannerJwtAudience: 'https://identity.int.athenahealth.com',
  defaultAthenaOneHost: 'devmain-mirror.athenahealth.com',
};

const prodConfig: BaseConfig = {
  portalClientId: '0oaku1tngsTH20pA1296',
  clientIdsForBanner: [
    '0oaku1tngsTH20pA1296', // Communicator Patient Portal
  ],
  athenaOneWebClientIds: [
    '0oaet0rfjNzyKCiQQ296', // athenaNet
    '0oa4zsgxk7LR1gMAo297', // athenaNet - ClientSandbox
    '0oa1g3qnz9iqZWIjR297', // athenaNet - ClientTrain
    '0oa16evw7trKLV80X297', // athenaNet - Preview
    '0oa1g3pp3dNEa1Rhm297', // athenaNet - salesdemo
    '0oa1g3qt4lLFlpa7d297', // athenaNet - Sandbox
  ],
  athenaOneMobileClientIds: [
    '0oa6ul7lt1uvcNBz8297', // athenaOne Mobile
    '0oa6ulmxvpXJ0IUVy297', // athenaOne Mobile - preview
    '0oa6uljb2e7eIila7297', // athenaOne Mobile - salesdemo
    '0oae3x8ssiw1KDxLz297', // PKCE athenaOne Mobile
    '0oakwjdee7ew9gP8w297', // athenaOne android app
    '0oakzzyg6iiiaEcpp297', // athenaOne android app - salesdemo
    '0oal0019ls7P9RTv6297', // athenaOne android app - preview
    '0oal0066lwYUCKz5w297', // athenaOne android app - sandbox
    '0oal0046kfGYYH85U297', // athenaOne android app - clienttrain
  ],
  amplitudeApiKey: 'c41bf44737b4598dcae4c0eaec9b5833',
  manageProfileUrl: 'https://myidentity.platform.athenahealth.com/profile/manage',
  athenaNetPasswordResetUrl: 'https://athenanet.athenahealth.com/1/1/resetpassword.esp',
  defaultAthenaOneLoginUrl: 'https://athenanet.athenahealth.com/login.esp',
  athenaOneChangeExpiredPasswordUrl: 'https://athenanet.athenahealth.com/1/1/login/changeoktaexpiredpassword.esp',
  athenaOneSymantecPromptUrl: 'https://athenanet.athenahealth.com/1/1/login/oktasymantecvipprompt.esp',
  athenaOneOieChangeExpiredPasswordUrl: 'https://athenanet.athenahealth.com/1/1/login/changeoktaoieexpiredpassword.esp',
  getSecurityQuestionsTokenUrl: 'https://athenanet.athenahealth.com/1/1/login/getsqtoken.esp',
  oieEnabled: false,
  forgeEnabled: true,
  marketingContentUrl: new URL('https://anet.aws.caas.athenahealth.com?v=1'),
  env: 'prod',
  bannerJwtAudience: 'https://identity.athenahealth.com',
  defaultAthenaOneHost: 'athenanet.athenahealth.com',
};

const envConfigMappings: Record<string, BaseConfig> = {
  'http://localhost:50050': localConfig,
  'http://identity.test.athenahealth.com:50050': localConfig,
  'https://identity.dev.athenahealth.com': devConfig,
  'https://identity.int.athenahealth.com': intConfig,
  'https://identity.athenahealth.com': prodConfig,
};

function makeFinalConfig(config: BaseConfig): AthenaEnvConfig {
  return {
    ...config,
    classic: !config.oieEnabled,
    // TO DO: cleanup & add new domain after https://athenajira.athenahealth.com/browse/NOC-42910 is done
    athenaNetPublickKeyUrl: new URL('https://identity-static-assets.platform.athenahealth.com/athenanet-signed-jwt-conf.json'),
  };
}

export default makeFinalConfig(
  process.env.REACT_APP_TEST_DEV_PUPP ? localConfig : envConfigMappings[window.location.origin] || devConfig
);
