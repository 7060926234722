import ProxyButton, { ProxyButtonProps } from './ProxyButton';
import { useEventBus } from '../../hooks/useEventBus';
import { useState } from 'react';
import withEventHelper from './EventHelper';

function PrimaryButton(props: Readonly<ProxyButtonProps>) {
  const [ displayButton, setDisplayButton ] = useState(true);

  useEventBus({
    eventName: 'set-button-display',
    onEvent: ({ display }) => {
      setDisplayButton(display);
    },
  });

  return displayButton ? <ProxyButton {...props} /> : <></>;
}

PrimaryButton.displayName = 'PrimaryButton';

export default withEventHelper(PrimaryButton);
