import Link from '../common/Link';
const INTRODUCE_TO_COLLEAGUE_LINK = 'https://www.athenahealth.com/landing/yournetwork';

export default function IntroduceToColleagueLink() {
  return (
    <div className='fe_u_margin--top-large'>
      <Link text={'Introduce Us to a Colleague'} href={INTRODUCE_TO_COLLEAGUE_LINK} id='athena-introduce-to-colleague-link' target='_blank' className='fe_u_font-size--small' />
    </div>
  );
}
