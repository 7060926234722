import React, { useMemo, useState } from 'react';
import { useMutationObserver } from '../../hooks/useMutationObserver';
import ProxyButton from './ProxyButton';
import PrimaryButton from './PrimaryButton';

export interface ButtonBarProps {
  oktaButtonBar: JQuery<HTMLElement>;
  fullWidth?: boolean;
  primaryButtonOnClick?: any;
  containerClassName?: string;
  primaryButtonClassName?: string;
  secondaryButtonOnClick?: any;
  suppressOktaClick?: boolean;
}

export default function ButtonBar({
  oktaButtonBar,
  fullWidth = true,
  primaryButtonOnClick,
  containerClassName = '',
  primaryButtonClassName = '',
  secondaryButtonOnClick,
  suppressOktaClick,
}: ButtonBarProps) {
  const firstButton = oktaButtonBar.find('input.button').eq(0);
  const secondButton = oktaButtonBar.find('input.button').eq(1);
  const linkButton = oktaButtonBar.find('a.link').eq(0);
  const [ hidden, setHidden ] = useState(oktaButtonBar[0]?.style?.display === 'none');
  const justifyContentClass = fullWidth ? 'fe_u_flex-justify-content--space-between' : 'fe_u_flex-justify-content--center';

  //useMemo to ensure we don't create new references for the dependencies of useMutationObserver
  const onMutationArgs = useMemo(() => {
    return {
      onMutation: (mutation: MutationRecord) => {
        const buttonBar = mutation.target as HTMLElement;
        setHidden(buttonBar.style.display === 'none');
      },
      filterOptions: { attributeFilter: [ 'style' ] },
    };
  }, []);
  useMutationObserver({
    element: oktaButtonBar[0],
    ...onMutationArgs,
  });

  if (!hidden) {
    return (
      <>
        <div className={`fe_u_flex-container ${justifyContentClass} ${containerClassName}`}>
          <PrimaryButton
            oktaButton={firstButton}
            variant="primary"
            className={primaryButtonClassName}
            fullWidth={fullWidth}
            size="large"
            onClick={() => {
              if (primaryButtonOnClick && typeof primaryButtonOnClick === 'function') {
                primaryButtonOnClick(firstButton.text());
              }
            }}
            suppressOktaClick={suppressOktaClick}
          />
          <ProxyButton
            className="fe_u_margin--left-medium fe_c_button--large"
            oktaButton={secondButton}
            variant="secondary"
            fullWidth={fullWidth}
            size="large"
            onClick={() => {
              if (secondaryButtonOnClick && typeof secondaryButtonOnClick === 'function') {
                secondaryButtonOnClick(secondButton.text());
              }
            }}
          />
        </div>
        <ProxyButton
          className="fe_u_margin--top-medium fe_c_button--medium"
          oktaButton={linkButton}
          variant="tertiary"
          fullWidth={fullWidth}
          size="medium"
        />
      </>
    );
  }
  else {
    return <></>;
  }
}
