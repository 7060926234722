import athenaConfig from '../../config';
import EnrollManualPushBaseCustomizer from './base';
import ProxyButton from '../../components/common/ProxyButton';
const { oieEnabled } = athenaConfig;

export default class EnrollManualPushOieCustomizer extends EnrollManualPushBaseCustomizer {

  async shouldCustomize() {
    return this.context.formName === 'enroll-authenticator' && this.context.authenticatorKey === 'google_otp' && oieEnabled && forgify;
  }

  private forgifyVerifyButton() {
    const oktaNextButton: JQuery<HTMLElement> = jQueryCourage('.google-authenticator-verify');
    if (oktaNextButton.length) {
      const forgeComponent = <ProxyButton
        oktaButton={oktaNextButton}
        variant="primary"
        fullWidth size='large'
      />;
      this.renderForgeAndHideOktaComponent({
        id: oktaNextButton.attr('class')!,
        oktaComponent: oktaNextButton,
        forgeComponent: forgeComponent,
      });
    }
  }

  protected forgifyController() {
    const oktaSecretKeyInstructions: JQuery<HTMLElement> = jQueryCourage('.oie-enroll-google-authenticator-manual-setup .google-authenticator-setup-info');
    this.addToHideList(oktaSecretKeyInstructions[0]); // this component gets forgified in InputFieldFactory
    this.forgifySubTitle('.manual-setup-title');
    this.forgifySubTitle('.enter-code-title');
    this.forgifyVerifyButton();
    this.replaceComponents();
  }
}
