import { getOhswAuthorizeUrl, removeOhswAuthorizeUrl, setOhswAuthorizeUrl } from './utils/storage-utils';
import athenaConfig from './config';
import { getClientId } from './utils/widget-facade';
import { withErrorConsumed } from './utils/common-utils';
const { classic } = athenaConfig;

const updateUrlBasedOnClientId = (clientId: string) => {
  const ohswAuthorizeUrl = getOhswAuthorizeUrl();
  if (ohswAuthorizeUrl) {
    const parsedUrl = new URL(ohswAuthorizeUrl);
    const clientIdFromUrl = parsedUrl.searchParams.get('client_id');
    if (clientIdFromUrl !== clientId) {
      removeOhswAuthorizeUrl();
    }
  }
};

const updateAuthorizeUrl = withErrorConsumed(() => {
  if (window.location.pathname.endsWith('v1/authorize')) {
    setOhswAuthorizeUrl(window.location.href);
  }
  else {
    const requestContext = global.OktaUtil.getRequestContext();
    const clientId = getClientId(requestContext);
    if (clientId) {
      updateUrlBasedOnClientId(clientId);
    }
    else {
      removeOhswAuthorizeUrl();
    }
  }
});

export default function rewriteUrlOnChange() {
  updateAuthorizeUrl();
  // Rewrite URL back to the original recorded authorize URL on DOM changes, it's expected the DOM would be
  // changed by the controller before changing the URL (e.g. password-expired would change it /password-expired)
  // this allows us to maintain the requestContext when user reloads the page
  if (classic) {
    const body = document.querySelector('body') as HTMLElement;
    const recordedUrl = getOhswAuthorizeUrl();
    if (recordedUrl) {
      const parsedUrl = new URL(recordedUrl);
      const observer = new MutationObserver((mutations) => {
        //document object becomes null in jest after the test runs and thus cause errors
        if (document && recordedUrl !== window.location.href) {
          window.history.replaceState(undefined, '', `${parsedUrl.pathname}${parsedUrl.search}${parsedUrl.hash}`);
        }
      });
      observer.observe(body, { childList: true, subtree: true });
    }
  }
}
