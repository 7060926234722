import athenaConfig from '../../config';
import BarcodePushBaseCustomizer from './base';
import { TOTP_PROVIDER_AMPLITUDE_EVENT } from '../../constants';
import AmplitudeUtils from '../../utils/amplitude-utils';
import { toPascalCase } from '../../utils/string-utils';
import ProxyButton from '../../components/common/ProxyButton';

const { classic } = athenaConfig;

export default class BarcodePushClassicCustomizer extends BarcodePushBaseCustomizer {
  scanInstructionsTextSelector = '.scan-instructions-details';
  cantScanTextSelector = '.manual-setup';
  async shouldCustomize() {
    // common customizations between oktaVerify and googleAuthenticator controllers
    return [ 'barcode-push', 'barcode-totp' ].includes(this.context?.controller) && classic && forgify;
  }

  async customize() {
    await super.customize();
    this.forgifyRefreshCodeText();
    this.replaceComponents();
    if (this.provider) {
      AmplitudeUtils.logMfaEnrollEvent(`${TOTP_PROVIDER_AMPLITUDE_EVENT[this.provider]}TotpBarcodeScan::Landing`);
    }
  }

  private forgifyRefreshCodeText() {
    const refreshQrCodeLink = jQueryCourage('.refresh-qrcode');
    if (refreshQrCodeLink.length) {
      const forgeComponent = (
        <ProxyButton oktaButton={refreshQrCodeLink} variant="tertiary" fullWidth />
      );
      this.renderForgeAndHideOktaComponent({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        id: 'refresh-qrcode',
        className: 'refresh-qrcode',
        oktaComponent: refreshQrCodeLink,
        forgeComponent: forgeComponent,
      });
    }
  }

  protected primaryButtonOnClick = (buttonText: string) => {
    buttonText = buttonText ? toPascalCase(buttonText) : 'Next';
    AmplitudeUtils.logMfaEnrollEvent(`${TOTP_PROVIDER_AMPLITUDE_EVENT[this.provider]}TotpBarcodeScan::${buttonText}ButtonClick`);
  };
}
