export function formatUnixTimestamp(unixTimestamp: number) {
  const date = new Date(unixTimestamp * 1000); // Convert Unix timestamp to milliseconds
  const dateTimeFormat = new Intl.DateTimeFormat('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: 'numeric',
    timeZone: 'UTC',
  });
  const formattedDate = dateTimeFormat.format(date);
  const finalDateString = 'Last login on ' +
    formattedDate.replace(/ at/i, '').replace(/\xa0/g, ' ')
      .replace(/\u202f/g, ' ') + ' ET';
  return finalDateString;
}
