import athenaConfig from '../../config';
import { INVALID_STATE_TOKEN_ERROR_CODE } from '../../constants';
import AmplitudeUtils from '../../utils/amplitude-utils';
import { fetchAndStoreSqToken, getCustomLoginMetadata, onSuccessfulAuth, shouldSqTokenEndpointBeCalled } from '../../utils/authorize-utils';
import { getOktaWidgetModel } from '../../utils/widget-facade';
import BasePrimaryAuthCustomizer from './base';
import { setStateTokenOverride } from '../../utils/storage-utils';
import { isAuthnWithAthenaOneWebClient } from '../../utils/app-utils';
import { indefiniteWaitPromise } from '../../utils/common-utils';
const { classic } = athenaConfig;

export default class PrimaryAuthClassicCustomizer extends BasePrimaryAuthCustomizer {
  rememberMeSelector = '#athena-remember';

  private onPrimaryAuthModelSave() {
    const isUsernameAndPasswordScreen = this.context?.controller === 'primary-auth';
    if (this.config?.features?.idpDiscovery && isUsernameAndPasswordScreen) {
      const primaryAuthModel = getOktaWidgetModel();
      const originalPrimaryAuthModel = primaryAuthModel.save.bind(primaryAuthModel);
      primaryAuthModel.save = async (...args: any) => {
        await AmplitudeUtils.logIdpDiscoveryUserLoginEvent({ isIdpDiscoverySSOUser: false });
        return await originalPrimaryAuthModel.apply(primaryAuthModel, args);
      };
    }
  }

  private async handleSqPromptDuringStateTokenExpiry(signInArgs: any) {
    const transaction = await this.oktaSignIn.router.appState.get('transaction').cancel();
    // Statetoken less authentication provides us with an invalid stateToken when authn transaction cycle is completed
    // We need to perform authentication with stateToken that will not invalidate the stateToken.
    // This will prevent us from getting unauthorized errors on sq token endpoint end.
    // We cannot make use of this stateToken for the stateToken override flow
    // since it would lack authn/target props required for the final redirect.
    const authnResponse = await this.oktaSignIn.authClient.signInWithCredentials({
      ...signInArgs,
      stateToken: transaction.data.stateToken,
    });
    this.oktaSignIn.router.appState.set('lastAuthResponse', authnResponse.data);
    await fetchAndStoreSqToken();
  }

  private removeLoginFromPromptParam(searchParams: URLSearchParams) {
    const prompt = searchParams.get('prompt');
    if (prompt?.includes('login')) {
      searchParams.set(
        'prompt',
        prompt.split(' ').filter((param) => param.trim() !== 'login')
          .join(' ')
      );
    }
  }

  private async handleStateTokenExpiry(signInArgs: any) {
    let authnRes;
    try {
      authnRes = await this.oktaSignIn.authClient.signInWithCredentials(signInArgs);
    }
    catch (error: any) {
      AmplitudeUtils.logCustomEvent('LoginError', {
        errorMessage: error.message || error.errorSummary,
        errorCode: error.errorCode,
        errorName: error.name,
        ...getCustomLoginMetadata(this.bannerConfig),
      });
      throw error;
    }

    if (authnRes?.data) {
      const { sessionToken, stateToken } = authnRes.data;
      if (sessionToken || stateToken) {
        await Promise.all([
          AmplitudeUtils.logCustomEvent('LoginSuccess', getCustomLoginMetadata(this.bannerConfig)),
          AmplitudeUtils.logCustomEvent('StateTokenExpired', {}),
        ]);
        shouldSqTokenEndpointBeCalled() && (await this.handleSqPromptDuringStateTokenExpiry(signInArgs));

        const searchParams = new URLSearchParams(window.location.search);
        this.removeLoginFromPromptParam(searchParams);
        if (sessionToken) {
          searchParams.set('sessionToken', sessionToken);
          await onSuccessfulAuth();
        }
        else {
          setStateTokenOverride(stateToken);
        }
        window.location.search = searchParams.toString();
        await indefiniteWaitPromise();
      }
      this.oktaSignIn.router.controller.model.trigger('setTransaction', authnRes);
      AmplitudeUtils.logCustomEvent('LoginError', {
        ...getCustomLoginMetadata(this.bannerConfig),
        errorMessage: authnRes?.data?.status,
      });
    }
  }

  private onAuthenticate() {
    const transaction = getOktaWidgetModel().appState.get('transaction');
    const originalAuthenticate = transaction.authenticate.bind(transaction);
    transaction.authenticate = async (...args: any) => {
      try {
        AmplitudeUtils.logCustomEvent('LoginInitiated', getCustomLoginMetadata(this.bannerConfig));
        const res = await originalAuthenticate.apply(transaction, args);
        AmplitudeUtils.logCustomEvent('LoginSuccess', getCustomLoginMetadata(this.bannerConfig));
        return res;
      }
      catch (error: any) {
        if (error?.errorCode === INVALID_STATE_TOKEN_ERROR_CODE) {
          await this.handleStateTokenExpiry(args[0]);
          return;
        }
        AmplitudeUtils.logCustomEvent('LoginError', {
          errorMessage: error.message || error.errorSummary,
          errorCode: error.errorCode,
          errorName: error.name,
          ...getCustomLoginMetadata(this.bannerConfig),
        });
        throw error;
      }
    };
  }

  async shouldCustomize() {
    return (await super.shouldCustomize()) && classic;
  }

  private getIdpId(url: URL) {
    const urlParts = url.pathname.split('/');
    return urlParts[urlParts.length - 1];
  }

  private onWebFinger() {
    const authClient = this.oktaSignIn.authClient;
    const originalWebFinger = authClient.webfinger.bind(authClient);
    authClient.webfinger = async (...args: any) => {
      const res = await originalWebFinger.apply(authClient, args);
      const isSsoUser = res?.links[0]?.properties['okta:idp:type'] !== 'OKTA' && res?.links[0]?.href;
      if (isSsoUser && res?.links[0]?.href) {
        // Avoid sending anet login as login_hint to idp
        const url = new URL(res.links[0].href);
        url.searchParams.delete('login_hint');
        res.links[0].href = url.toString();
        try {
          await AmplitudeUtils.logIdpDiscoveryUserLoginEvent({ isIdpDiscoverySSOUser: true, idp: this.getIdpId(url) });
        }
        catch (e) {
          // eslint-disable-next-line no-empty
        }
      }
      return res;
    };
  }

  async customize() {
    await super.customize();
    if (this.context.controller === 'idp-discovery') {
      this.onWebFinger();
    }
    this.onPrimaryAuthModelSave();
    isAuthnWithAthenaOneWebClient() && this.onAuthenticate();
  }
}
