import { Heading, ListItem } from '@athena/forge';
import ProxyButton from '../common/ProxyButton';
import { OKTA_MFA_FACTOR_AMPLITUDE_EVENTS } from '../../constants';
import AmplitudeUtils from '../../utils/amplitude-utils';
import { toPascalCase } from '../../utils/string-utils';

export default function ChallengeFactor({
  oktaFactorItem,
}: {
  oktaFactorItem: JQuery;
  lastItem: boolean;
}) {
  const label = oktaFactorItem.find('h3');
  const description = oktaFactorItem.find('p').eq(0);
  const oktaButton = oktaFactorItem.find('a.button');
  return (
    <ListItem
      className='fe_u_flex-container fe_u_margin--bottom-small'
    >
      <div className={oktaFactorItem.attr('class')}>
        <div className={`${oktaFactorItem.children().attr('class')} fe_u_margin--right-medium`}>
          <div className={oktaFactorItem.find('.factor-icon').attr('class')}></div>
        </div>
      </div>
      <div className='factor-challenge-description'>
        <Heading
          className="fe_u_font-size--default"
          text={label.text()}
          variant="subsection"
          headingDescription={description.text()}
        ></Heading>
      </div>
      <div>
        <ProxyButton
          oktaButton={oktaButton}
          fullWidth={false}
          size="tiny"
          variant="secondary"
          onClick={() => {
            if (OKTA_MFA_FACTOR_AMPLITUDE_EVENTS[label.text()]) {
              AmplitudeUtils.logMfaChallengeEvent(`${OKTA_MFA_FACTOR_AMPLITUDE_EVENTS[label.text()]}::${toPascalCase(oktaButton.text())}ButtonClick`);
            }
          }}
        />
      </div>
    </ListItem>
  );
}
