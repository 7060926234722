import { Banner, BannerItem } from '@athena/forge';
import { INFO_BANNER_MESSAGE_MAP_V2 } from '../../constants';
const HEADER_TEXT = INFO_BANNER_MESSAGE_MAP_V2['ERROR']['ACCOUNTBLOCKED'].header;
const HEADER_DESCRIPTION = INFO_BANNER_MESSAGE_MAP_V2['ERROR']['ACCOUNTBLOCKED'].description;
export default function UserLockedOutBanner() {
  return (
    <Banner alertType='critical' className='fe_u_padding--small'>
      <BannerItem headerText={HEADER_TEXT}>
        {HEADER_DESCRIPTION}
      </BannerItem>
    </Banner>
  );
}
