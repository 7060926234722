import athenaConfig from '../../config';
import { TOTP_PROVIDER_AMPLITUDE_EVENT } from '../../constants';
import AmplitudeUtils from '../../utils/amplitude-utils';
import { getFactorApplicationName } from '../../utils/i18n-utils';
import { toPascalCase } from '../../utils/string-utils';
import ActivatePushBaseCustomizer from './base';
const { classic } = athenaConfig;

export default class ActivatePushClassicCustomizer extends ActivatePushBaseCustomizer {
  protected applicationName = getFactorApplicationName();
  headerDescription = this.applicationName ? `Enter the code displayed in your ${this.applicationName}` : '';

  async shouldCustomize() {
    return (await super.shouldCustomize()) && classic;
  }

  async customize() {
    super.customize();
    AmplitudeUtils.logMfaEnrollEvent(`${TOTP_PROVIDER_AMPLITUDE_EVENT[this.provider]}TotpActivatePush::Landing`);
  }

  protected primaryButtonOnClick = (buttonText: string) => {
    buttonText = buttonText ? toPascalCase(buttonText) : 'Verify';
    AmplitudeUtils.logMfaEnrollEvent(`${TOTP_PROVIDER_AMPLITUDE_EVENT[this.provider]}TotpActivatePush::${buttonText}ButtonClick`);
  };
}
