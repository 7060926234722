import BaseForgeCustomizer from '../../base-forge';
import config from '../../../config';
import { CONTACT_ADMIN_TEXT } from '../../../constants';
import ProxyButton from '../../../components/common/ProxyButton';

const { oieEnabled } = config;

export default class OktaVerifyOieBaseCustomizer extends BaseForgeCustomizer {
  footerText = CONTACT_ADMIN_TEXT;
  primaryButtonClassName = 'fe_u_margin--bottom-small';
  async shouldCustomize() {
    return forgify && ([ 'select-authenticator-authenticate', 'mfa-verify', 'mfa-verify-passcode' ].includes(this.context.controller)) && oieEnabled;
  }

  async customize() {
    await super.customize();
    this.forgifyOktaPushButton();
    this.replaceComponents();
  }

  private forgifyOktaPushButton() {
    const oktaNextButton: JQuery<HTMLElement> = jQueryCourage('.link-button');
    const isDisabled = oktaNextButton.hasClass('link-button-disabled');

    if (oktaNextButton.length) {
      const forgeComponent = (
        <ProxyButton
          oktaButton={oktaNextButton}
          variant="primary"
          size='large'
          disabled={isDisabled}
          fullWidth={true}
          className='fe_u_margin--bottom-small'
        />
      );
      this.renderForgeAndHideOktaComponent({
        id: oktaNextButton.attr('class')!,
        oktaComponent: oktaNextButton,
        forgeComponent: forgeComponent,
      });
    }
  }
}
