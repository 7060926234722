import athenaConfig from '../../config';
import { passwordResetOie, shouldPromptForSymantecVIPOIE } from '../../utils/anet-client';
import { getOIEStateHandle, getOktaWidgetModel, showFormErrors, stopSpinner } from '../../utils/widget-facade';
import PasswordResetBaseCustomizer, { PasswordRequirementsSelector } from './password-expired-base';
import { getAnetUsername } from '../../utils/user-utils';
import AmplitudeUtils from '../../utils/amplitude-utils';
import { ERROR_KEYS, ERROR_MESSAGES } from '../../constants';
import { setSecurityQuestionsPromptCookies } from '../../utils/storage-utils';

const { oieEnabled } = athenaConfig;

interface SymantecPromptPayloadOie {
  username: string;
  stateHandle: string;
}

export default class PasswordResetOieCustomizer extends PasswordResetBaseCustomizer<SymantecPromptPayloadOie> {
  constructor(args: any) {
    super(args);
    this.symantecFieldIndex = 0;
  }

  protected onPasswordReset() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const {
      oktaSignIn: { authClient },
    } = this;
    const originalProceed = authClient.idx.proceed.bind(authClient);

    // Widget calls this method after performing frontend validation with the expected payload
    // See https://github.com/okta/okta-signin-widget/blob/7.8/src/v2/controllers/FormController.ts#L284
    authClient.idx.proceed = async (...args: any) => {
      const model = getOktaWidgetModel();
      //Handle errors by listing to error events once the button is clicked. This helps in receiving a more "normalized"
      //error payload - See https://github.com/okta/okta-signin-widget/blob/7.8/src/v2/controllers/FormController.ts#L385
      model.once('error', (_: any, error: any) => {
        if (this.isSymantecValidationError(error.responseJSON?.message)) {
          this.clearPasswordResetForm();
        }
        else {
          this.clearSymantecCode();
        }
        const errorMessage =
          error?.responseJSON?.errorCauses?.[0]?.errorSummary ??
          error?.responseJSON?.xhr?.responseJSON?.errorSummary ??
          error?.errorSummary ??
          ERROR_MESSAGES[ERROR_KEYS.GENERIC_INTERNAL_ERROR];
        AmplitudeUtils.logPasswordResetEvent('PasswordChangeFailed', {
          errorMessage,
          shouldPromptForSymantec: self.shouldPromptForSymantec,
        });
      });

      AmplitudeUtils.logPasswordResetEvent('PasswordChangeInitiated', {
        shouldPromptForSymantec: self.shouldPromptForSymantec,
      });
      const { step } = args[0];
      if (step === 'reenroll-authenticator') {
        const {
          credentials: { passcode },
          stateHandle,
        } = args[0];
        const symantecSecurityCode = this.getSymantecSecurityCode();
        const response = await self.triggerPasswordResetRequest({
          symantecSecurityCode,
          newPassword: passcode,
          stateHandle,
        });
        if (response.sqtoken) {
          setSecurityQuestionsPromptCookies ({
            sqtoken: response.sqtoken,
            primaryAuthWithCustomTxn: true,
          });
        }
        const requestSucceeded = !response.error;
        if (requestSucceeded) {
          AmplitudeUtils.logPasswordResetEvent('PasswordChangeSuccess', {
            shouldPromptForSymantec: self.shouldPromptForSymantec,
          });
        }
        const idxResponse = await authClient.idx.makeIdxResponse(response, {}, requestSucceeded);
        await authClient.transactionManager.saveIdxResponse({
          rawIdxResponse: idxResponse,
          requestDidSucceed: requestSucceeded,
          stateHandle,
        });
        return idxResponse;
      }
      else {
        return originalProceed.apply(authClient, args);
      }
    };
  }

  async shouldCustomize() {
    return (await super.shouldCustomize()) && oieEnabled;
  }
  async getSymantecPromptPayload(): Promise<SymantecPromptPayloadOie> {
    return {
      username: getAnetUsername(),
      stateHandle: getOIEStateHandle(),
    };
  }

  async handleSymantecPromptError(error: any): Promise<void> {
    const errorMessage = error.messages?.value?.[0]?.message ?? error.errorSummary ?? error.message;
    AmplitudeUtils.logPasswordResetEvent('SymantecVipPromptFailure', {
      errorMessage,
    });
    showFormErrors(error);
    stopSpinner();
  }

  async triggerPasswordResetRequest({ newPassword, symantecSecurityCode }: any): Promise<any> {
    const symantecPromptPayload = await this.getSymantecPromptPayload();
    return passwordResetOie({
      newPassword,
      symantecSecurityCode,
      ...symantecPromptPayload,
    });
  }

  protected async shouldPromptForSymantecVIP(payload: any): Promise<boolean> {
    return shouldPromptForSymantecVIPOIE(payload);
  }
  protected getPasswordRequirementsSelector(): PasswordRequirementsSelector {
    return {
      containerSelector: '[data-se="password-authenticator--rules"]',
      headerSelector: '.password-authenticator--heading',
      itemListSelector: '.password-authenticator--list',
    };
  }

  protected getSymantecCodeTarget(): JQuery {
    return jQueryCourage('#athena-password-authenticator--rules');
  }
}
