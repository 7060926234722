import { startSpinnerAndHideForm } from '../../utils/widget-facade';
import OktaVerifyEnrollOieBaseCustomizer from './base';

export default class SelectEnrollmentChannelCustomizer extends OktaVerifyEnrollOieBaseCustomizer {
  async shouldCustomize() {
    return (await super.shouldCustomize()) && this.context.formName === 'select-enrollment-channel';
  }

  async customize() {
    // This screen is not required since we have only one manual option present
    startSpinnerAndHideForm();
    this.removeEmailManualOption();
    this.triggerSmsOptionAndNextButtonClick();
    await super.customize();
  }

  private removeEmailManualOption() {
    jQueryCourage('input[value="email"]').parent()
      .remove();
  }

  private triggerSmsOptionAndNextButtonClick() {
    jQueryCourage('input[value="sms"]').trigger('click');
    jQueryCourage('input[value="Next"]').trigger('click');
  }
}
