import Logger from '../../logger';

export const withErrorConsumed = <I extends any[], O>(wrappedFunction: (...args: I) => O) => {
  return function (...args: I) {
    try {
      return wrappedFunction(...args);
    }
    catch (e) {
      Logger.error('Error occurred while calling wrapped function', e);
    }
  };
};

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const isStateTokenOverrideFlow = () => {
  return global.stateTokenOverrideFlow;
};

/**
 * Creates a Promise that never resolves or rejects, effectively creating an indefinite wait.
 * @returns {Promise<void>} The indefinite wait Promise.
 */
export const indefiniteWaitPromise = () => {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  return new Promise(() => {});
};
