import withEventHelper from '../common/EventHelper';
import { InputFieldContainerProps } from '../common/InputFieldContainer';
import CallPhoneInputSection from './CallPhoneInputSection';
import SmsPhoneInputSection from './SmsPhoneInputSection';

function PhoneInputSectionClassic(props: {
  componentType: 'Call' | 'SMS';
} & InputFieldContainerProps) {
  const { componentType, ...inputFieldProps } = props;

  // These components are wrapped within a common parent since both create listeners on the
  // same events which are emitted based on component name (emitEventToComponent)
  if (componentType === 'Call') return <CallPhoneInputSection {...inputFieldProps}/>;
  else return <SmsPhoneInputSection {...inputFieldProps}/>;
}

PhoneInputSectionClassic.displayName = 'PhoneInputSectionClassic';

export default withEventHelper(PhoneInputSectionClassic);
