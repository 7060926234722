import MutedText from '../common/MutedText';

export interface SecretKeyInstructionsProps {
  container: JQuery<HTMLElement>;
}

export default function SecretKeyInstructions({ container }: SecretKeyInstructionsProps) {
  const sharedSecret = container.find('.shared-secret').find('input').attr('placeholder');
  const text = container.find('.oie-enroll-google-authenticator-manual-setup .google-authenticator-setup-info').text();
  if (text && sharedSecret) {
    return (
      <div className='fe_u_text-align--center'>
        <MutedText text={text} />
        <p className='fe_u_font-size--large'>{sharedSecret}</p>
      </div>
    );
  }
  else {
    return <></>;
  }
}
