
import MutedText from '../common/MutedText';
import { useMemo, useState } from 'react';
import { useMutationObserver } from '../../hooks/useMutationObserver';
import Link from '../common/Link';
import { getSelectedProvider } from '../../utils/widget-facade';

export interface AppDownloadInstructionsProps {
  container: JQuery<HTMLElement>;
}

export default function AppDownloadInstructions({ container }: AppDownloadInstructionsProps) {
  const downloadInstructionsLink = container.find('a');
  const [ downloadInstructionsState, setDownloadInstructionsState ] = useState({
    hidden: container[0].style.display === 'none',
    sentence: container.text(),
    linkText: downloadInstructionsLink?.text(),
    linkUrl: downloadInstructionsLink[0]?.href,
  });

  const containerMutationObserverArgs = useMemo(() => {
    return {
      filterOptions: { childList: true },
      onMutation: (mutation: MutationRecord) => {
        const appDownloadInstructions = mutation.target as any;
        const downloadInstructionsLink = appDownloadInstructions.getElementsByClassName('inline-link');
        setDownloadInstructionsState({
          hidden: appDownloadInstructions.style.display === 'none',
          sentence: appDownloadInstructions.textContent,
          linkText: downloadInstructionsLink[0]?.textContent,
          linkUrl: downloadInstructionsLink[0]?.href,
        });
      },
    };
  }, []);


  useMutationObserver({
    element: container[0],
    ...containerMutationObserverArgs,
  });

  const { sentence, linkText, linkUrl, hidden } = downloadInstructionsState;
  const provider = getSelectedProvider();

  if (!hidden && sentence) {
    const nonLinkTextParts = sentence.split(linkText);
    return (
      <div className='app-download-instructions fe_u_margin--bottom-medium'>
        { provider === 'OKTA' && <span className="app-logo okta-verify-download-icon app-download-instructions-logo" /> }
        { provider === 'GOOGLE' && <span className="app-logo google-auth-38 app-download-instructions-logo"></span> }
        <div>
          <MutedText className='app-download-instructions-text-item' text={nonLinkTextParts[0]} />
          <Link className='app-download-instructions-text-item' target='_blank' href={linkUrl} text={linkText} />
          <MutedText className='app-download-instructions-text-item' text={nonLinkTextParts[1]} />
        </div>
      </div>
    );
  }
  else {
    return <></>;
  }
}
