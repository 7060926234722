import InputFieldContainer, { InputFieldContainerProps } from './InputFieldContainer';
import { ReadOnlyInput } from '@athena/forge';
import { useMemo } from 'react';
import { useWidgetContext } from '../context/WidgetContext';
import athenaConfig from '../../config';
const { oieEnabled } = athenaConfig;

export default function UsernameField(props: Readonly<InputFieldContainerProps>) {
  const username = useMemo(() => {
    const usernameSelector = oieEnabled ? 'identifier' : 'username';
    const usernameField = props.inputFieldContainer.find(`[name=${usernameSelector}]`);
    if (usernameField.length) {
      // When there is a live okta session, both prompt=login and loginhint are needed to start a fresh login.
      // 'ANETUSERNAME' is a placeholder which will be cleared in OHSW
      if (usernameField.val() === 'ANETUSERNAME') {
        usernameField.val('');
      }
      else {
        return usernameField.val()?.toString();
      }
    }
  }, [ props.inputFieldContainer ]);

  const { athenaOneWebSessionTimedOut, athenaOneWebSsoSessionTimedOut } = useWidgetContext();

  if ((athenaOneWebSessionTimedOut || athenaOneWebSsoSessionTimedOut) && username) {
    return (
      <InputFieldContainer {...props} inputAs={ReadOnlyInput} labelAlwaysAbove text={username} className='fe_u_font-weight--semibold fe_u_margin--bottom-medium' />
    );
  }
  else {
    return (
      <InputFieldContainer {...props} autoFocus/>
    );
  }
}
