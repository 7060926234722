import { getRawAppName } from './widget-facade';
export function getAppConfigForApp({ requestContext, appConfigs }: { requestContext: any; appConfigs: any }) {
  const appName = getAppNameForConfig(requestContext);
  if (appConfigs && appName) {
    if (appConfigs[appName]) {
      return appConfigs[appName];
    }
  }
  return {};
}

function getAppNameForConfig(requestContext: any) {
  const appName = getRawAppName(requestContext);
  if (appName && (appName.includes('-') || appName.includes(' '))) {
    return appName.replace(/-/g, ' ').replace(/ +/g, '-')
      .toLowerCase();
  }
  return appName;
}

export const exposedForTesting = { getAppNameForConfig };
