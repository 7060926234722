import { GridCol, GridRow } from '@athena/forge';
import InputFieldContainer, { InputFieldContainerProps } from '../common/InputFieldContainer';

export default function CallPhoneInput({
  callExtension,
  inputContainerProps,
}: {
  callExtension: JQuery;
  inputContainerProps: InputFieldContainerProps;
}) {
  return (
    <GridRow>
      <GridCol width={{ small: 7 }}>
        {/*Phone input field*/}
        <InputFieldContainer {...inputContainerProps}></InputFieldContainer>
      </GridCol>
      <GridCol width={{ small: 5 }}>
        {/*Extension input field*/}
        <InputFieldContainer
          {...inputContainerProps}
          error={undefined}
          className="athena-input-form-field fe_u_margin--left-small"
          inputFieldContainer={callExtension}
        ></InputFieldContainer>
      </GridCol>
    </GridRow>
  );
}
