import { useMemo, useState } from 'react';
import { useMutationObserver } from '../../hooks/useMutationObserver';
import InputFieldContainer from './InputFieldContainer';
import RadioButton from './RadioButton';
import DropDownList from './DropDownList';
import CountryCodeDropDownList from './CountryCodeDropDownList';
import CheckBox from './CheckBox';
import PhoneInputSectionClassic from '../enroll-phone/PhoneInputSectionClassic';
import PasscodeVerifySection from '../mfa-verify/PasscodeVerifySection';
import UsernameField from './UsernameField';
import athenaConfig from '../../config';
import PasswordField from './PasswordField';
import PhoneInputSectionOie from '../enroll-phone/oie/PhoneInputSectionOie';
import SecretKeyInstructionsOie from '../enroll-manual-push/SecretKeyInstructionsOie';
const { oieEnabled } = athenaConfig;

interface InputFieldContainerProps {
  inputFieldContainer: JQuery<HTMLElement>;
  className?: string;
}

export default function InputFieldFactory({ inputFieldContainer, className }: InputFieldContainerProps) {
  const { inputContainer, input, dropDownList, form } = useMemo(() => {
    const inputContainer = inputFieldContainer.find('[data-se="o-form-input-container"]');
    const input = inputContainer.find('input');
    const form = inputFieldContainer.closest('form');
    const dropDownList = inputContainer.find('select');
    return { inputContainer, input, dropDownList, form };
  }, [ inputFieldContainer ]);

  const getErrorMessage = () => {
    return inputContainer.find('.okta-form-input-error').text();
  };
  const [ errorMessage, setErrorMessage ] = useState(getErrorMessage());
  const [ disabled, setDisabled ] = useState(input.prop('disabled') || form.hasClass('o-form-saving'));
  const [ hidden, setHidden ] = useState(
    inputFieldContainer[0].style.display === 'none' || inputFieldContainer.hasClass('hide')
  );
  const inputMutationObserverArgs = useMemo(() => {
    return {
      filterOptions: { attributeFilter: [ 'disabled' ] },
      onMutation: (mutation: MutationRecord) => {
        const targetElement = mutation.target as HTMLInputElement;
        setDisabled(targetElement.disabled);
      },
    };
  }, []);

  useMutationObserver({
    element: input[0],
    ...inputMutationObserverArgs,
  });

  useMutationObserver({
    element: form[0],
    filterOptions: { attributeFilter: [ 'class' ] },
    onMutation: () => {
      setDisabled(form.hasClass('o-form-saving'));
    },
  });

  const inputContainerMutationObserverArgs = useMemo(() => {
    return {
      filterOptions: { childList: true },
      onMutation: () => {
        setErrorMessage(getErrorMessage());
      },
    };
  }, []);

  useMutationObserver({
    element: inputContainer[0],
    ...inputContainerMutationObserverArgs,
  });

  const inputFieldContainerMutationObserverArgs = useMemo(() => {
    return {
      filterOptions: { attributeFilter: [ 'style' ] },
      onMutation: (mutation: MutationRecord) => {
        const targetElement = mutation.target as HTMLInputElement;
        setHidden(targetElement.style.display === 'none');
      },
    };
  }, []);

  useMutationObserver({
    element: inputFieldContainer[0],
    ...inputFieldContainerMutationObserverArgs,
  });

  function renderInputFieldComponent() {
    const props = {
      className,
      error: errorMessage,
      inputFieldContainer,
      disabled,
    };
    const isDropDownList = dropDownList.length;
    if (isDropDownList) {
      if ([ 'countryCode', 'country' ].includes(dropDownList[0].name)) {
        return <CountryCodeDropDownList {...props} />;
      }
      return <DropDownList {...props} />;
    }
    else {
      const usernameSelector = oieEnabled ? 'identifier' : 'username';
      const passwordSelector = oieEnabled ? 'credentials.passcode' : 'password';
      if (inputFieldContainer.find(`[name=${usernameSelector}]`).length) {
        return <UsernameField {...props} />;
      }
      else if (inputFieldContainer.find(`[name='${passwordSelector}']`).length) {
        return <PasswordField {...props} />;
      }
      else if (input.attr('type') === 'radio') {
        return <RadioButton {...props} />;
      }
      else if (input.attr('type') === 'checkbox') {
        return <CheckBox {...props} />;
      }
      else if (inputFieldContainer.hasClass('enroll-sms-phone')) {
        return <PhoneInputSectionClassic componentType='SMS' {...props} />;
      }
      else if (inputFieldContainer.hasClass('enroll-call-phone')) {
        return <PhoneInputSectionClassic componentType='Call' {...props} />;
      }
      else if (inputFieldContainer.hasClass('phone-authenticator-enroll__phone')) {
        return <PhoneInputSectionOie {...props} />;
      }
      else if (inputFieldContainer.hasClass('enroll-call-extension')) {
        //This component is managed by CallPhoneInputSection
        return <></>;
      }
      else if (inputFieldContainer.hasClass('phone-authenticator-enroll__phone-ext')) {
        //This component is managed by CallPhoneInputSectionOie
        return <></>;
      }
      else if (inputFieldContainer.hasClass('auth-passcode')) {
        return <PasscodeVerifySection {...props} />;
      }
      else if (oieEnabled && inputFieldContainer.hasClass('shared-secret')) {
        const container: JQuery<HTMLElement> = jQueryCourage('.o-form-fieldset-container');
        return <SecretKeyInstructionsOie container={container} />;
      }
      return <InputFieldContainer {...props} />;
    }
  }

  if (!hidden) {
    return <>{renderInputFieldComponent()}</>;
  }
  else {
    return <></>;
  }
}
