import BaseCustomizer from './base';
import athenaConfig from '../config';
import renderReactComponentFactory, { createWrapper, FactoryReturnType } from '../utils/react-utils';
import Header from '../components/common/Header';
import ButtonBar from '../components/common/ButtonBar';
import ErrorContainer from '../components/common/ErrorContainer';
import InfoContainer from '../components/common/InfoContainer';
import { ReactNode } from 'react';
import EventBus from '../utils/event-bus';
import { isAthenaOneWebOrMobileClient } from '../utils/app-utils';
import { AthenaOneParams } from '../utils/banner-jwt-utils';
import Footer from '../components/common/Footer';
import InputFieldFactory from '../components/common/InputFieldFactory';
import { getSelectedProvider, getSelectedFactorType } from '../utils/widget-facade';
import MutedText from '../components/common/MutedText';

const { oieEnabled, classic } = athenaConfig;

interface ControllerContext {
  athenaOneParams?: AthenaOneParams;
  athenaOneWebSsoSessionTimedOut?: boolean;
  athenaOneWebSessionTimedOut?: boolean;
}

export default abstract class BaseForgeCustomizer extends BaseCustomizer {
  protected controllerContext: ControllerContext = {};
  protected footerText: string = '';
  protected headerDescription: string = '';
  protected headerText: string = '';
  protected headerFontSize?: string;
  protected displayHeader: boolean = true;
  protected displayFooterLink: boolean = true;
  protected primaryButtonFullWidth: boolean = true;
  protected primaryButtonOnClick?: any;
  protected suppressOktaClick: boolean = false;
  protected footerMarginTop: string = oieEnabled ? 'large' : 'small';
  protected footerTextAlign?: string;
  protected inputFieldsCustomClass?: string;
  protected eventEmitter = new EventBus();
  protected primaryButtonClassName: string = '';
  protected renderReactComponent: FactoryReturnType = () => undefined;
  protected provider = getSelectedProvider();
  protected factorType = getSelectedFactorType();
  protected secondaryButtonOnClick?: any;

  hideList: Array<any> = [];
  showList: Array<any> = [];

  protected addAthenaOneLogo(signInHeader: JQuery<HTMLElement>) {
    const headerLogo = signInHeader.find('img').addClass('athena-one-logo');
    const identityStaticAssetsUrl = process.env.REACT_APP_IDENTITY_STATIC_ASSETS_URL;
    if (headerLogo.length && identityStaticAssetsUrl) {
      headerLogo[0].src = `${identityStaticAssetsUrl}/athenaOneLogo.png`;
    }
  }

  protected customizeLogo() {
    const signInHeader: JQuery<HTMLElement> = jQueryCourage('.okta-sign-in-header');
    if (signInHeader.length) {
      const isClientAthenaOne = isAthenaOneWebOrMobileClient(this.requestContext?.authentication?.client?.id);
      if (isClientAthenaOne) {
        this.addAthenaOneLogo(signInHeader);
      }
    }
  }

  async customize() {
    this.renderReactComponent = renderReactComponentFactory({
      eventEmitter: this.eventEmitter,
      language: this.config?.language,
      clientId: this.requestContext?.authentication?.client?.id,
      controller: this.context?.controller,
      provider: this.provider,
      factorType: this.factorType,
      ...this.controllerContext,
    });
    if (forgify) {
      this.forgifyController();
      this.replaceComponents();
    }
  }

  protected forgifyController() {
    this.customizeLogo();
    this.forgifyWidgetContainer();
    this.forgifyHeader();
    this.forgifyInfoContainer();
    this.forgifyErrorContainer();
    this.forgifyInputFields();
    this.forgifyButtonBar();
    this.addFooter();
  }

  private forgifyInputFields() {
    const inputFieldContainers: JQuery = jQueryCourage('.o-form-fieldset');
    if (inputFieldContainers.length) {
      inputFieldContainers.each((index) => {
        const inputFieldContainer = inputFieldContainers.eq(index);
        const selectField = inputFieldContainer.find('select');
        const inputField  = selectField.length ? selectField : inputFieldContainer.find('input');
        const component = (
          <InputFieldFactory inputFieldContainer={inputFieldContainer} className={this.inputFieldsCustomClass} />
        );
        this.renderForgeAndHideOktaComponent({
          id: inputField.attr('name')! || inputField.attr('id')!,
          forgeComponent: component,
          oktaComponent: inputFieldContainer,
        });
      });
    }
  }

  private forgifyHeader() {
    const oktaHeader: JQuery<HTMLElement> = jQueryCourage('[data-se="o-form-head"]');
    const oktaSubtitle = jQueryCourage('[data-se="o-form-explain"]');
    if (oktaHeader.length || oktaSubtitle.length) {
      if (this.displayHeader) {
        const component = (
          <Header
            oktaHeader={oktaHeader}
            oktaSubtitle={oktaSubtitle}
            description={this.headerDescription}
            headerText={this.headerText}
            fontSize={this.headerFontSize}
          />
        );
        this.renderForgeAndHideOktaComponent({
          oktaComponent: oktaHeader,
          forgeComponent: component,
          id: oktaHeader.attr('data-se')!,
        });
        oktaSubtitle[0] && this.addToHideList(oktaSubtitle[0]);
      }
      else {
        this.addToHideList(oktaHeader[0]);
      }
    }
  }

  addFooter() {
    const footerLink = this.displayFooterLink ? jQueryCourage('div.auth-footer a').toArray() : undefined;
    if (this.footerText || footerLink?.length) {
      const oktaAuthContainer = jQueryCourage('.auth-footer').length ? jQueryCourage('.auth-footer') : jQueryCourage('.auth-content-inner form');
      const footer = createWrapper({ hidden: false });
      oktaAuthContainer.after(footer);
      const component = (
        <Footer
          text={this.footerText}
          marginTop={this.footerMarginTop}
          footerLinks={footerLink}
          textAlign={this.footerTextAlign}
        />
      );
      this.renderReactComponent({
        id: 'footer',
        container: footer,
        component,
      });
    }
  }

  private forgifyButtonBar() {
    const buttonBar: JQuery<HTMLElement> = jQueryCourage('.o-form-button-bar');
    if (buttonBar.length) {
      const marginHackForClassic = classic ? 'fe_u_margin--bottom-large' : '';
      const component = (
        <ButtonBar
          oktaButtonBar={buttonBar}
          containerClassName={marginHackForClassic}
          fullWidth={this.primaryButtonFullWidth}
          primaryButtonOnClick={this.primaryButtonOnClick}
          primaryButtonClassName={this.primaryButtonClassName}
          secondaryButtonOnClick={this.secondaryButtonOnClick}
          suppressOktaClick={this.suppressOktaClick}
        />
      );
      this.renderForgeAndHideOktaComponent({
        id: buttonBar.attr('class')!,
        oktaComponent: buttonBar,
        forgeComponent: component,
      });
    }
  }

  private forgifyErrorContainer() {
    const errorContainers: Readonly<JQuery> = jQueryCourage('div[data-se="o-form-error-container"]');
    if (errorContainers.length) {
      errorContainers.each((index) => {
        const errorContainer = errorContainers.eq(index);
        const forgeComponent = <ErrorContainer oktaErrorContainer={errorContainer} />;
        this.renderForgeAndHideOktaComponent({
          /*
            As Okta error containers are not having `id` attribute, we are relying `index` value to uniquely identify.
            By making `errorContainers` immutable we ensure that only `id` remains unique.
          */
          id: `${errorContainer.attr('class')}${index}`!,
          oktaComponent: errorContainer,
          forgeComponent: forgeComponent,
        });
      });
    }
  }

  protected forgifyWidgetContainer() {
    const oktaSignInContainer = jQueryCourage('#okta-sign-in');
    if (oktaSignInContainer.length) {
      oktaSignInContainer.addClass('rounded-border');
      oktaSignInContainer.find('.auth-header').css({ border: 'none' });
    }
  }

  protected renderForgeAndHideOktaComponent({
    id,
    oktaComponent,
    forgeComponent,
    className,
  }: {
    id: string;
    oktaComponent: JQuery;
    forgeComponent: ReactNode;
    className?: string;
  }) {
    if (oktaComponent.length) {
      const container = createWrapper();
      oktaComponent.after(container);
      this.renderReactComponent({
        id,
        container,
        component: forgeComponent,
        className,
      });
      this.addToHideList(oktaComponent[0]);
      this.addToShowList(container);
    }
  }

  addToHideList(element: HTMLElement) {
    if (element) {
      this.hideList.push(element);
    }
  }

  addToShowList(element: HTMLElement) {
    if (element) {
      this.showList.push(element);
    }
  }

  protected replaceComponents() {
    if (this.hideList.length) {
      this.hideList.forEach((el) => this.hideComponent(el));
      this.hideList.length = 0;
    }
    if (this.showList.length) {
      this.showList.forEach((el) => el.classList.remove('hide-element'));
      this.showList.length = 0;
    }
  }

  protected hideComponent(element: HTMLElement) {
    element?.classList.add('hide-element');
  }

  private forgifyInfoContainer() {
    if (classic) {
      jQueryCourage('.o-form-error-container').before('<div class="o-form-info-container"></div>');
    }

    const infoContainer: JQuery<HTMLElement> = jQueryCourage('.o-form-info-container');
    if (infoContainer.length) {
      const forgeComponent = <InfoContainer oktaInfoContainer={infoContainer} />;
      this.renderForgeAndHideOktaComponent({
        id: infoContainer.attr('class')!,
        oktaComponent: infoContainer,
        forgeComponent: forgeComponent,
      });
    }
  }

  protected forgifySubTitle(selector: string) {
    const oktaComponent: JQuery = jQueryCourage(selector).eq(0);
    const component = (
      <MutedText className='fe_u_font-size--default fe_u_text-align--center' text= { oktaComponent.text() }/>
    );
    this.renderForgeAndHideOktaComponent({
      id: oktaComponent.attr('class')!,
      forgeComponent: component,
      oktaComponent,
    });
  }

}
