import { IWidgetContext, useWidgetContext } from './WidgetContext';

export default function withWidgetContext<P extends IWidgetContext>(
  ComponentToWrap: React.ComponentType<P>
): React.FC<Omit<P, keyof IWidgetContext>> {
  const WrappedComponent = (props: Omit<P, keyof IWidgetContext>) => {
    const widgetContext = useWidgetContext();
    return <ComponentToWrap {...(widgetContext as IWidgetContext)} {...(props as P)} />;
  };
  WrappedComponent.displayName = 'withWidgetContext_' + (ComponentToWrap.displayName || ComponentToWrap.name);
  return WrappedComponent;
}
