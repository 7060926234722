import InputFieldContainer, { InputFieldContainerProps } from '../common/InputFieldContainer';
import { useMemo } from 'react';
import { GridCol, GridRow } from '@athena/forge';
import ProxyButton from '../common/ProxyButton';
import AmplitudeUtils from '../../utils/amplitude-utils';
import { toPascalCase } from '../../utils/string-utils';

export default function TotpVerifySection(props: InputFieldContainerProps) {
  const totpVerifyButton = useMemo(() => {
    return jQueryCourage('.button.inline-totp-verify');
  }, []);
  const showTotpVerifyButton = totpVerifyButton.length > 0;
  return (
    <div>
      <GridRow>
        <GridCol width={{ small: 12 }}>
          <div className="athena-input-form-field athena-input-form-field--required">
            <InputFieldContainer
              required={true}
              autoComplete={'off'}
              {...props}>
            </InputFieldContainer>
          </div>
        </GridCol>
        <>
          {showTotpVerifyButton && (
            <GridCol width={{ small: 12 }}>
              <div className="fe_u_flex-container">
                <ProxyButton
                  oktaButton={totpVerifyButton}
                  className="totp-verify-button"
                  fullWidth
                  variant="primary"
                  size="large"
                  onClick={() => {
                    const buttonText = totpVerifyButton.text() ? toPascalCase(totpVerifyButton.text()) : 'Verify';
                    AmplitudeUtils.logMfaChallengeEvent(`OktaVerify::${buttonText}ButtonClick`);
                  }}
                />
              </div>
            </GridCol>
          )}
        </>
      </GridRow>
    </div>
  );
}
