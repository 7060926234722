import { getOktaWidgetModel } from '../../../utils/widget-facade';
import BaseEnrollPhoneFactorCustomizer from '../base';

export default class AuthenticatorEnrollmentDataCustomizer extends BaseEnrollPhoneFactorCustomizer {
  async shouldCustomize() {
    return [ 'authenticator-enrollment-data' ].includes(this.context?.formName);
  }

  protected forgifyController() {
    super.forgifyController();
    this.onMethodTypeChange();
  }

  private onMethodTypeChange() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const widgetModel = getOktaWidgetModel();
    widgetModel.once('change:authenticator.methodType', async function () {
      await self.customize();
    });
  }
}
