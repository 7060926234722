import FactorEnrollCustomizerBase from './enroll-factors-base';
import athenaConfig from '../../config';
import FactorsList from '../../components/enroll-factors/FactorsList';
import AmplitudeUtils from '../../utils/amplitude-utils';
import { toPascalCase } from '../../utils/string-utils';

const { classic } = athenaConfig;

export default class ClassicFactorEnrollCustomizer extends FactorEnrollCustomizerBase {
  async shouldCustomize() {
    return (await super.shouldCustomize()) && classic;
  }

  forgifyFactors() {
    let oktaComponent = jQueryCourage('.enroll-factor-list');
    AmplitudeUtils.logCustomEvent('AthenanetMFAPrompt', { });
    //To keep things consistent OIE, we are hiding the enrolled factors
    if (oktaComponent.length === 2) {
      this.addToHideList(oktaComponent[0]);
      oktaComponent = oktaComponent.eq(1);
    }

    const forgeComponent = <FactorsList oktaFactorsList={oktaComponent} listItemsSelector='.enroll-factor-row' />;
    this.renderForgeAndHideOktaComponent({ id: oktaComponent.attr('class')!, forgeComponent, oktaComponent });
  }

  protected primaryButtonOnClick = (buttonText?: string) => {
    buttonText = buttonText ? toPascalCase(buttonText) : 'Finish';
    AmplitudeUtils.logMfaEnrollEvent(`${buttonText}ButtonClick`);
  };
}
