import BarcodePushBaseCustomizer from './base';
import ProxyButton from '../../components/common/ProxyButton';
import athenaConfig from '../../config';
const { oieEnabled } = athenaConfig;

export default class BarcodePushOieCustomizer extends BarcodePushBaseCustomizer {
  scanInstructionsTextSelector = '.qrcode-info-container .google-authenticator-setup-info';
  cantScanTextSelector = '.cannot-scan-link';
  async shouldCustomize() {
    return this.context.formName === 'enroll-authenticator' && this.context?.authenticatorKey === 'google_otp' && oieEnabled && forgify;
  }

  private forgifyNextButton() {
    const oktaNextButton: JQuery<HTMLElement> = jQueryCourage('.google-authenticator-next');
    if (oktaNextButton.length) {
      const forgeComponent = <ProxyButton
        oktaButton={oktaNextButton}
        variant="primary"
        fullWidth size='large'
      />;
      this.renderForgeAndHideOktaComponent({
        id: oktaNextButton.attr('class')!,
        oktaComponent: oktaNextButton,
        forgeComponent: forgeComponent,
      });
    }
  }

  protected forgifyController() {
    this.forgifySubTitle('.barcode-setup-title');
    this.forgifyNextButton();
    super.forgifyController();
  }

}

