import FactorEnrollCustomizerBase from './enroll-factors-base';
import athenaConfig from '../../config';
import FactorsList from '../../components/enroll-factors/FactorsList';
import AmplitudeUtils from '../../utils/amplitude-utils';
const { oieEnabled } = athenaConfig;
export default class OieFactorEnrollCustomizer extends FactorEnrollCustomizerBase {
  async shouldCustomize() {
    return ((await super.shouldCustomize()) || this.context.formName === 'select-authenticator-enroll') && oieEnabled;
  }

  forgifyFactors() {
    const oktaComponent = jQueryCourage('.authenticator-enroll-list');
    AmplitudeUtils.logCustomEvent('AthenanetMFAPrompt', { });
    const forgeComponent = (
      <FactorsList
        oktaFactorsList={oktaComponent}
        listItemsSelector=".authenticator-row"
        submitButtonSelector={'a.button-primary.button.skip-all.link-button'}
      />
    );
    this.renderForgeAndHideOktaComponent({ id: 'authenticator-enroll-list', forgeComponent, oktaComponent });
  }
}
