import BaseForgeCustomizer from '../base-forge';
import config from '../../config';
import { CONTACT_ADMIN_TEXT, STEPS_TO_FINISH_SETUP_TEXT } from '../../constants';
const { oieEnabled } = config;

export default class OktaVerifyEnrollOieBaseCustomizer extends BaseForgeCustomizer {
  headerText = STEPS_TO_FINISH_SETUP_TEXT;
  footerText = CONTACT_ADMIN_TEXT;
  async shouldCustomize() {
    const { authenticatorKey, methodType } = this.context;
    return authenticatorKey === 'okta_verify' && methodType === 'push' && oieEnabled && forgify;
  }

  // Text is not required since we have only one manual option
  protected hideSwitchChannelText() {
    this.hideComponent(jQueryCourage('.switch-channel-text')[0]);
  }
}
