import { useEffect } from 'react';
import { useWidgetContext } from '../components/context/WidgetContext';

type OnEventFunction = (payload: any) => void;

interface UseEventBusArgs {
  eventName: string;
  onEvent: OnEventFunction;
}

export function useEventBus({ eventName, onEvent }: UseEventBusArgs) {
  const { eventEmitter } = useWidgetContext();
  useEffect(() => {
    eventEmitter.on(eventName, onEvent);
    return () => {
      eventEmitter.removeListener(eventName, onEvent);
    };
  }, [ eventEmitter, eventName, onEvent ]);
}
