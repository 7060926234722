import { Fragment } from 'react';

export interface OrderedListProps {
  oktaComponent: JQuery<HTMLElement>;
  className?: string;
}

export default function OrderedList({ oktaComponent, className = 'numbered-list' }: Readonly<OrderedListProps>) {
  const listOptions = Object.values(oktaComponent.find('li'))
    .map((option) => option.textContent as string)
    .filter((value) => value);

  return (
    <ol className={`${className} fe_u_margin--left-none fe_u_margin--down-none`}>
      {listOptions.length > 0 && listOptions.map((value, _) =>
        <Fragment key={value}>
          <li className='fe_u_color--muted fe_u_margin--bottom-medium'>
            {value}
          </li>
        </Fragment>
      )}
    </ol>
  );
}
