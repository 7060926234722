import { Button } from '@athena/forge';
import { useWidgetContext } from '../context/WidgetContext';
import { getAthenaOneSwitchUserUrl } from '../../utils/app-utils';
import AmplitudeUtils from '../../utils/amplitude-utils';
import { closeOktaSession } from '../../utils/widget-facade';
import config from '../../config';
import { removeLastUsernameOktaCookie } from '../../utils/storage-utils';
const { oieEnabled } = config;

export default function SwitchUserButton() {
  const { clientId, athenaOneParams, athenaOneWebSsoSessionTimedOut } = useWidgetContext();
  let SWITCH_USER_BUTTON_WARNING;
  if (!athenaOneParams || !([ 'REFRESHTIMEOUT' ].includes(athenaOneParams.BANNERTYPE))) {
    SWITCH_USER_BUTTON_WARNING = 'If you switch user, unsaved changes will be discarded.';
  }

  const onSwitchUserClick = async () => {
    //Kill Okta session before starting authentication as Okta is not able to update session to newly authenticated
    //if there is already a valid session
    await closeOktaSession();
    if (window.top) {
      window.top.location.href = getAthenaOneSwitchUserUrl(clientId, athenaOneParams);
    }
    oieEnabled && removeLastUsernameOktaCookie();
    AmplitudeUtils.logCustomEvent('SwitchUserButtonClick', { isSso: !!athenaOneWebSsoSessionTimedOut });
  };

  return (
    <div>
      <Button variant="tertiary" text="Switch User" fullWidth={false} size="small" className="fe_u_padding--left-none" onClick={onSwitchUserClick}/>
      <p className='fe_u_font-size--small fe_u_font-weight--regular fe_u_color--muted'>{SWITCH_USER_BUTTON_WARNING}</p>
    </div>
  );
}
