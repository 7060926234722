import InputFieldContainer, { InputFieldContainerProps } from './InputFieldContainer';
import { useWidgetContext } from '../context/WidgetContext';

export default function PasswordField(props: InputFieldContainerProps) {

  const { athenaOneWebSsoSessionTimedOut } = useWidgetContext();

  if (athenaOneWebSsoSessionTimedOut) {
    return <></>;
  }
  return (
    <InputFieldContainer {...props}  />
  );
}
