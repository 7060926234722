import { CONTACT_ADMIN_TEXT, STEPS_TO_FINISH_SETUP_TEXT } from '../../constants';
import BaseForgeCustomizer from '../base-forge';

export default abstract class ActivatePushBaseCustomizer extends BaseForgeCustomizer {
  headerText = STEPS_TO_FINISH_SETUP_TEXT;
  footerText = CONTACT_ADMIN_TEXT;

  async shouldCustomize() {
    // common customizations between oktaVerify and googleAuthenticator controllers
    return [ 'activate-push', 'activate-totp' ].includes(this.context?.controller);
  }
}
