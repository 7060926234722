import { getCountryCallingCode } from '../../utils/widget-facade';
import DropDownList, { DropDownListProps } from './DropDownList';

type CountryCodeDropDownProps = Omit<DropDownListProps, 'options'>;

export default function CountryCodeDropDownList(props: CountryCodeDropDownProps) {
  const options = props.inputFieldContainer
    .find('option')
    .map((_, element) => {
      const value = element.value;
      const label = element.text + ' ' + getCountryCallingCode(element.value);
      return { value, label };
    })
    .toArray();

  return (
    <DropDownList
      {...props}
      options={options}
      defaultValue={{
        value: 'US',
        label: 'United States +1',
      }}
    />
  );
}
